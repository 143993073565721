import styled from 'styled-components';

export const OuterErrorContainer = styled.div`
  width: 80vw;
  height: 60vh;
  margin-left: auto;
  margin-right: auto;
`;

export const ErrorContainer = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  margin-top: 4rem;
`;

export const ErrorButtonContainer = styled.div`
  width: 15rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
`;
