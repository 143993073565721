import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { SiteProps } from 'src/components/App/App';
import Button from 'src/components/Button/Button';
import CartItem, { CartItemProps } from 'src/components/Cart/CartItem';
import { exampleCartItemCss } from 'src/components/Cart/exampleCSS';
import { ValidCartItem } from 'src/utils/utils';

export interface CartListProps extends SiteProps {
  hideEmptyCartButton?: boolean;
  emptyCartMessage: string;
  emptyCartNavigateMessage?: string;
  emptyCartNavigate?: () => void;
  itemRenderer?: (cartItem: CartItemProps) => React.ReactNode;
  validCartItems: ValidCartItem[];
  styles?: {
    cartListContainer?: React.CSSProperties;
    cartListItem?: React.CSSProperties;
    emptyCartListContainer?: React.CSSProperties;
    emptyCartListMessage?: React.CSSProperties;
    buttonContainer?: React.CSSProperties;
  };
}

const CartList = (props: CartListProps) => {
  const [showEmptyList, setShowEmptyList] = useState(false);
  useEffect(() => {
    if (isEmpty(props.validCartItems)) {
      setShowEmptyList(true);
    } else {
      setShowEmptyList(false);
    }
  }, [props.validCartItems]);

  return (
    <>
      {showEmptyList && (
        <div
          className="emptyCartListContainer"
          data-testid="emptyCartListContainer"
          style={props.styles?.emptyCartListContainer}
        >
          <div
            className="emptyCartListMessage"
            data-testid="emptyCartListMessage"
            style={props.styles?.emptyCartListMessage}
          >
            {props.emptyCartMessage}
          </div>
          {!props.hideEmptyCartButton &&
            props.emptyCartNavigate &&
            props.emptyCartNavigateMessage && (
              <Button
                text={props.emptyCartNavigateMessage}
                onClick={props.emptyCartNavigate}
                styles={props.styles}
                testId="emptyCartButton"
                classNamePrefix="emptyCart"
              />
            )}
        </div>
      )}
      {!showEmptyList && (
        <ol
          className="cartListContainer"
          data-testid="cartListContainer"
          style={props.styles?.cartListContainer}
        >
          {props.validCartItems.map((cartItem, itemIndex: number) => {
            return props.itemRenderer ? (
              // Use item renderer if provided
              <li
                key={`${cartItem?.identifier?.itemAsin}-${cartItem?.identifier?.customId}-${cartItem?.identifier?.customizationToken}-${itemIndex}`}
                className="cartListItem"
                data-testid="cartListItem"
                style={props.styles?.cartListItem}
              >
                {props.itemRenderer({
                  sitePrefix: props.sitePrefix,
                  siteType: props.siteType,
                  includeSessionIdInMetrics: props.includeSessionIdInMetrics,
                  identifier: cartItem?.identifier,
                  image: cartItem?.image,
                  title: cartItem?.title,
                  availability: cartItem?.availability,
                  price: cartItem?.price,
                  quantity: cartItem?.quantity,
                  variations: cartItem?.variations,
                  deliveryMessage: cartItem?.deliveryMessage,
                  primeBadgeData: cartItem?.primeBadgeData,
                })}
              </li>
            ) : (
              // Otherwise use default order item
              <li
                key={itemIndex}
                className="cartListItem"
                data-testid="cartListItem"
                style={props.styles?.cartListItem}
              >
                <CartItem
                  sitePrefix={props.sitePrefix}
                  siteType={props.siteType}
                  includeSessionIdInMetrics={props.includeSessionIdInMetrics}
                  identifier={cartItem?.identifier}
                  image={cartItem?.image}
                  title={cartItem?.title}
                  availability={cartItem?.availability}
                  price={cartItem?.price}
                  quantity={cartItem?.quantity}
                  variations={cartItem?.variations}
                  deliveryMessage={cartItem?.deliveryMessage}
                  primeBadgeData={cartItem?.primeBadgeData}
                  styles={exampleCartItemCss}
                />
              </li>
            );
          })}
        </ol>
      )}
    </>
  );
};

export default CartList;
