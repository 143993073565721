import {
  Availability,
  ItemIdentifier,
  ProductImages,
  ProductVariations,
} from '@amzn/mulberry-external-typescript-client';
import moment from 'moment';
import MediaCentralImage from 'src/components/Image/MediacentralImage';
import {
  ARRIVING_STRING,
  EACH_STRING,
  QUANTITY_STRING,
  SUBTOTAL_TEXT,
} from 'src/constants/strings';
import { SiteType } from 'src/frameworks/models/SiteType';
import { PageType } from 'src/metricsTypes/metricsTypes';
import { ImageSize } from 'src/utils/imageUtil';
import {
  formatAvailabilityString,
  getPriceNumberFromString,
  getVariationValues,
  priceToUSDStringFormatter,
} from 'src/utils/utils';

export interface OrderItemStyles {
  orderItemContainer?: React.CSSProperties;
  orderItemImage?: React.CSSProperties;
  arrivalDateString?: React.CSSProperties;
  availabilityString?: React.CSSProperties;
  variationContainer?: React.CSSProperties;
  variationKeyContainer?: React.CSSProperties;
  variationValueContainer?: React.CSSProperties;
  variationOuterContainer?: React.CSSProperties;
  orderInfoContainer?: React.CSSProperties;
  priceString?: React.CSSProperties;
  quantityString?: React.CSSProperties;
  priceLabelString?: React.CSSProperties;
  quantityLabelString?: React.CSSProperties;
  priceContainer?: React.CSSProperties;
  quantityContainer?: React.CSSProperties;
  titleString?: React.CSSProperties;
  subtotalContainer?: React.CSSProperties;
  subtotalLabelString?: React.CSSProperties;
  subtotalString?: React.CSSProperties;
  priceQuantityContainer?: React.CSSProperties;
  orderInfoOuterContainer?: React.CSSProperties;
}
export interface OrderItemProps {
  estimatedArrivalDate: string | undefined; // epoch seconds
  image: ProductImages | undefined;
  title: string | undefined;
  availability: Availability | undefined;
  price: string | undefined;
  quantity: number | undefined;
  styles?: OrderItemStyles;
  variations: ProductVariations | undefined;
  identifier: ItemIdentifier | undefined;
  siteType: SiteType;
}

const OrderItem = (props: OrderItemProps) => {
  if (
    !props.estimatedArrivalDate &&
    !props.image &&
    !props.title &&
    !props.availability &&
    !props.price &&
    !props.quantity
  ) {
    return null;
  }
  const date = new Date(0);
  let userLocalTimeDate = '';
  if (props.estimatedArrivalDate) {
    date.setUTCSeconds(parseInt(props.estimatedArrivalDate));
    userLocalTimeDate = moment(date).format('MMM Do, YYYY');
  }
  const variations: { [key: string]: string } =
    props.variations && props.identifier
      ? getVariationValues(
          props.variations,
          props.identifier.itemAsin ?? '',
          props.identifier.customId ?? '',
          props.identifier.customizationToken ?? '',
        )
      : {};

  const availabilityText = formatAvailabilityString(
    props.availability?.primaryMessage,
    props.availability?.secondaryMessage,
  );
  const priceNumber = getPriceNumberFromString(props.price ?? '');
  const quantity = props?.quantity || 0;
  const subtotal = priceNumber * quantity;
  const formattedSubtotal = priceToUSDStringFormatter.format(subtotal);

  return (
    <div data-testid="orderItemContainer" style={props.styles?.orderItemContainer}>
      <MediaCentralImage
        siteType={props.siteType}
        pageType={PageType.ORDER_PAGE}
        size={ImageSize.SMALL}
        productImages={props.image}
        testId={`orderItemImage`}
        style={props.styles?.orderItemImage}
      />
      <div
        className="orderInfoOuterContainer"
        data-testid="orderInfoOuterContainer"
        style={props.styles?.orderInfoOuterContainer}
      >
        <div
          className="orderInfoContainer"
          data-testid="orderInfoContainer"
          style={props.styles?.orderInfoContainer}
        >
          {props.title && (
            <div
              className="titleString"
              data-testid="titleString"
              style={props.styles?.titleString}
            >
              {props.title}
            </div>
          )}
          {variations && (
            <div
              data-testid="variationOuterContainer"
              style={props.styles?.variationOuterContainer}
            >
              {Object.entries(variations).map(([key, value]) => (
                <div
                  key={key}
                  data-testid="variationContainer"
                  style={props.styles?.variationContainer}
                >
                  <div
                    data-testid="variationKeyContainer"
                    style={props.styles?.variationKeyContainer}
                  >
                    {key}:
                  </div>
                  <div
                    data-testid="variationValueContainer"
                    style={props.styles?.variationValueContainer}
                  >
                    {value}
                  </div>
                </div>
              ))}
            </div>
          )}
          {availabilityText && (
            <div
              className="availabilityString"
              data-testid="availabilityString"
              style={props.styles?.availabilityString}
            >
              {availabilityText}
            </div>
          )}
          {userLocalTimeDate && (
            <div
              className="arrivalDateString"
              data-testid="arrivalDateString"
              style={props.styles?.arrivalDateString}
            >
              {ARRIVING_STRING}
              <b>{userLocalTimeDate}</b>
            </div>
          )}
        </div>
        <div data-testid="priceQuantityContainer" style={props.styles?.priceQuantityContainer}>
          {props.price && (
            <div
              className="priceContainer"
              data-testid="priceContainer"
              style={props.styles?.priceContainer}
            >
              <div
                className="priceLabelString"
                data-testid="priceLabelString"
                style={props.styles?.priceLabelString}
              >
                {EACH_STRING}
              </div>
              <div
                className="priceString"
                data-testid="priceString"
                style={props.styles?.priceString}
              >
                {props.price}
              </div>
            </div>
          )}
          {props.quantity && (
            <div
              className="quantityContainer"
              data-testid="quantityContainer"
              style={props.styles?.quantityContainer}
            >
              <div
                className="quantityLabelString"
                data-testid="quantityLabelString"
                style={props.styles?.quantityLabelString}
              >
                {QUANTITY_STRING}
              </div>
              <div
                className="quantityString"
                data-testid="quantityString"
                style={props.styles?.quantityString}
              >
                {props.quantity}
              </div>
            </div>
          )}
          <div
            className="subtotalContainer"
            data-testid="subtotalContainer"
            style={props.styles?.subtotalContainer}
          >
            <div
              className="subtotalLabelString"
              data-testid="subtotalLabelString"
              style={props.styles?.subtotalLabelString}
            >
              {SUBTOTAL_TEXT}
            </div>
            <div
              className="subtotalString"
              data-testid="subtotalString"
              style={props.styles?.subtotalString}
            >
              {formattedSubtotal}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderItem;
