export const AMAZON_ACCOUNTS_PAGE = (suffix: string) =>
  `https://www.amazon.com/gp/css/homepage.html?ref_=mm_1_ammerch_d2c_acc_page_${suffix}`;

export const AMAZON_ADDRESS_PAGE = (suffix: string) =>
  `https://www.amazon.com/gp/css/homepage.html?ref_=mm_1_ammerch_d2c_addr_page_${suffix}`;

export const AMAZON_PAYMENT_PAGE = (suffix: string) =>
  `https://www.amazon.com/cpe/yourpayments/wallet?ref=mm_1_ammerch_d2c_payment_page_${suffix}`;

export const KYC_PAGE = (suffix: string) =>
  `https://www.amazon.com/gp/help/customer/display.html?nodeId=GAV3NYNSGRGTGAJJ&ref=mm_1_ammerch_d2c_kyc_page_${suffix}`;

export const AMAZON_ORDERS_PAGE = (suffix: string) =>
  `https://www.amazon.com/gp/css/order-history?ref=mm_1_ammerch_d2c_order_page_${suffix}`;
