import 'src/sites/TormentedValleyExternalPilot/components/Cart/TormentedValleyCartItem.scss';

import CartItem, { CartItemProps, CartItemStyle } from 'src/components/Cart/CartItem';
import { quantitySelectorTormentedValleyStyle } from 'src/sites/TormentedValleyExternalPilot/styles/QuantitySelectorStyles';

const $style: CartItemStyle = {
  cartItemContainer: {
    background: 'var(--primary-color)',
    color: 'var(--secondary-color)',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    gap: '1rem',
    padding: '0.5rem',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    boxSizing: 'border-box',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  cartItemInfoContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  cartItemImage: {
    width: 175,
    height: 175,
    objectFit: 'contain',
  },
  cartTitleString: {
    paddingRight: '2rem',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    MozBoxOrient: 'vertical',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '1rem',
    width: '100%',
    boxSizing: 'border-box',
  },
  cartItemFirstColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxWidth: 670,
    minWidth: 240,
    marginBottom: 15,
  },
  cartItemInnerContainer: { display: 'flex', flexDirection: 'column' },
  cartItemSecondColumn: {
    flexDirection: 'row',
    minWidth: 240,
    display: 'flex',
  },
  cartItemThirdColumn: {
    height: '2.5rem',
    width: 'auto',
    alignContent: 'end',
    textAlign: 'end',
    right: '0',
  },
  cartItemFirstRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '0.25rem',
  },
  cartPrimeBadgeContainer: {
    width: 'auto',
    marginTop: '4px',
    marginBottom: '4px',
  },
  removeItemButton: {
    backgroundColor: 'var(--primary-color)',
    boxSizing: 'border-box',
    border: '0 solid',
    padding: '0px',
    width: '2.5rem',
    minWidth: '1rem',
    height: '2.5rem',
    marginLeft: '1rem !important',
    marginTop: '0px !important',
    cursor: 'pointer',
  },
  totalPriceContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    fontSize: '1.5rem',
    marginTop: 'auto',
    marginBottom: 'auto',
    height: '2.5rem',
    alignItems: 'center',
    alignContent: 'end',
    marginLeft: 'auto',
  },
  variationOuterContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  variationContainer: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '0.9rem',
    fontFamily: 'var(--font-family)',
    marginRight: '0.5rem',
  },
  variationKeyContainer: { marginRight: '0.15rem' },
  cartPriceContainer: {
    color: 'rgb(96, 96, 96)',
    marginBottom: '0.5rem',
    fontSize: '1.25rem',
    maxWidth: 300,
    width: '100%',
  },
  cartAvailabilityString: {
    fontSize: '1rem',
  },
  cartItemQuantitySelectorStyle: quantitySelectorTormentedValleyStyle,
  deliveryMessageContainer: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '0.8rem',
    fontFamily: 'var(--font-family)',
    marginRight: '0.5rem',
    fontWeight: '300',
    opacity: 0.8,
  },
};
const TormentedValleyCartItem = (props: CartItemProps) => {
  return <CartItem {...props} styles={$style} />;
};

export default TormentedValleyCartItem;
