import { ProductImages } from '@amzn/mulberry-external-typescript-client';
import React, { useEffect } from 'react';
import { FALLBACK_IMAGE_ERROR_MESSAGE } from 'src/constants/strings';
import { SiteType } from 'src/frameworks/models/SiteType';
import { recordContentViewed, recordImageError } from 'src/metrics';
import { ElementType } from 'src/metricsTypes/metricsTypes';
import { getImageId, GetMediaCentralImage, ImageSize } from 'src/utils/imageUtil';

interface MediaCentralImageProps extends React.HTMLProps<HTMLImageElement> {
  siteType: SiteType;
  pageType: string;
  size: ImageSize | undefined;
  productImages: ProductImages | undefined;
  testId?: string;
  sessionId?: string;
}

/* 
  This component wraps the HTML image component and can try to fetch the image from media central and handle fallback
*/
const MediaCentralImage = (props: MediaCentralImageProps) => {
  // If the required fields are not provided, return null (which would not render the image)
  if (!props.siteType || !props.productImages) {
    return null;
  }

  // For the main URL, we should always use highres first for both small and large ImageSize since images on mediacentral will be
  // using same ids as highres images.
  const image = props.productImages;
  const { url, fallback } = {
    url: image.highRes?.url ?? image.lowRes?.url,
    fallback:
      props.size === ImageSize.LARGE
        ? image.highRes?.url ?? image.lowRes?.url
        : image.lowRes?.url ?? image.highRes?.url,
  };
  // if we use the fallback image url, emit the image id of the fallback image in the error metric
  const imageId = getImageId(fallback ?? '');

  const emitImageErrorMetric = () => {
    recordImageError(
      props.siteType,
      props.pageType,
      props.sessionId ?? undefined,
      imageId,
      FALLBACK_IMAGE_ERROR_MESSAGE,
    );
  };

  useEffect(() => {
    recordContentViewed(
      props.siteType,
      props.pageType,
      ElementType.PRODUCT_IMAGE,
      props.sessionId ?? undefined,
      imageId,
    );
  }, [props.siteType, props.pageType, props.sessionId, imageId]);

  return (
    <img
      data-testid={props.testId}
      className={props.className}
      style={props.style}
      onClick={props.onClick}
      src={GetMediaCentralImage(props.siteType, emitImageErrorMetric, url, fallback, props.size)}
      onError={(e) => {
        // if fallback was already set, then dont set again
        if (e.currentTarget.src === fallback) return;
        e.currentTarget.src = fallback ?? '';
        emitImageErrorMetric();
      }}
    />
  );
};

export default MediaCentralImage;
