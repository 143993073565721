export const primaryInfoBoxTormentedValleyStyle: Record<string, React.CSSProperties> = {
  primaryInfoBoxContainer: {
    color: 'var( --secondary-color )',
    marginBottom: '.5rem',
    width: '100%',
    alignItems: 'left',
  },
  primaryInfoBoxHeader: {
    fontFamily: 'var( --font-family )',
    fontWeight: 'var( --font-weight )',
    fontSize: '2rem',
    marginTop: '0px',
    marginBottom: '1rem',
  },
  primaryInfoBoxText: {
    fontFamily: 'var( --font-family )',
    fontWeight: 'var( --font-weight )',
    fontSize: '1.25rem',
  },
  primaryInfoBoxPreOrderPriceGuarantee: {
    color: '#077d63',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '1rem',
    marginBottom: '0.5rem',
    gap: '0.25rem',
  },
  primaryInfoBoxAvailability: { marginBottom: '.5rem', fontSize: '1rem', fontWeight: 'normal' },
  primaryInfoBoxReturn: { marginBottom: '1rem', fontSize: '1rem', fontWeight: 'normal' },
  primaryInfoBoxFeesMessage: {
    color: '#555',
    fontFamily: 'var( --font-family )',
    fontWeight: 'var( --font-weight )',
    fontSize: '1rem',
  },
};
