var endpointMap = {
	en: {
		"pages.homepage": "translation-en.json"
	},
	"en-US": {
		"pages.homepage": "translation-en.json"
	},
	und: {
		"pages.homepage": "translation.json"
	}
};
var arbManifest = {
	endpointMap: endpointMap
};

export { arbManifest as default, endpointMap };
