import { SiteType } from 'src/frameworks/models/SiteType';
import { getEndpoints, SITE_MAP, STAGE, STAGE_MAP } from 'src/services/roadie';

// Returns the value from the stage map for the current stage as key (prod, beta, gamma)
export const getValueFromStageMap = (map: STAGE_MAP) => {
  return map['Prod' as STAGE] || map['Beta'];
};

export const getStage = (): STAGE => {
  const stageFromVariable: string = 'Prod';
  const stages: STAGE[] = Object.keys(getEndpoints(SiteType.TEST)) as STAGE[];
  const stage = stages.find((validStage) => validStage === stageFromVariable);
  return stage || 'Beta';
};

export const getValueFromSiteMap = (map: SITE_MAP, site: SiteType) => {
  return map[site];
};
