export const Paths = {
  home: '/',
  liveEvents: '/live-events',
  music: '/music',
  store: '/store',
  about: '/about',
  details: '/product/:asin',
  cart: '/cart',
  previewCheckout: '/checkout',
  successCheckout: '/checkout/success',
  refreshToken: '/refresh-token',
  acceptToken: '/pass-token',
  closeWindow: '/return-to-checkout',
  faq: '/faq',
  invalid: '*',
};
