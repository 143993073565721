export const orderItemCss: Record<string, React.CSSProperties> = {
  orderItemContainer: {
    color: 'var(--secondary-color)',
    width: '100%',
    display: 'flex',
    height: 'auto',
    gap: '1rem',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  orderInfoContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  orderItemImage: {
    width: 175,
    height: 175,
    objectFit: 'contain',
  },
  arrivalDateString: {
    marginBottom: '.75rem',
    fontSize: '1.25rem',
  },
  titleString: {
    fontSize: '1.5rem',
    color: 'var(--secondary-color)',
  },
  priceQuantityContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    fontSize: '1.25rem',
    maxWidth: 300,
    width: '100%',
    alignSelf: 'flex-end',
  },
  subtotalContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-between',
    width: '100%',
    boxSizing: 'border-box',
    minWidth: '4rem',
    gap: '1rem',
  },
  subtotalLabelString: {
    textAlign: 'left',
  },
  subtotalString: {
    width: '100%',
    textAlign: 'right',
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-between',
    width: '100%',
    boxSizing: 'border-box',
    minWidth: '4rem',
    gap: '1rem',
  },
  priceString: {
    width: '100%',
    textAlign: 'right',
  },
  priceLabelString: {
    textAlign: 'left',
  },
  quantityContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-between',
    width: '100%',
    boxSizing: 'border-box',
    minWidth: '4rem',
    gap: '1rem',
  },
  quantityLabelString: {
    textAlign: 'left',
  },
  quantityString: {
    width: '100%',
    textAlign: 'right',
  },
  orderInfoOuterContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: 1,
  },
  variationOuterContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  variationContainer: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '1.25rem',
    fontFamily: 'var(--font-family)',
    marginRight: '0.5rem',
  },
  variationKeyContainer: { marginRight: '0.15rem' },
  cartPriceContainer: {
    marginBottom: '0.5rem',
    fontSize: '1.5rem',
    maxWidth: 300,
    width: '100%',
  },
};
