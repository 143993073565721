import './LoadingSpinner.scss';

export interface LoadingSpinnerProps {
  testId?: string;
}

const LoadingSpinner = (props: LoadingSpinnerProps) => {
  return <div className="LoadingSpinner" data-testid={props.testId}></div>;
};

export default LoadingSpinner;
