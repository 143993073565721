import {
  ItemIdentifier,
  ItemMetadata,
  PurchaseItem,
} from '@amzn/mulberry-external-typescript-client';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SiteProps } from 'src/components/App/App';
import Button, { ButtonStyles } from 'src/components/Button/Button';
import CheckoutButton, {
  CheckoutButtonStyles,
  CheckoutButtonType,
} from 'src/components/Button/CheckoutButton';
import {
  ErrorButtonContainer,
  ErrorContainer,
  OuterErrorContainer,
} from 'src/components/Error/errorStyledComponents';
import LoadingSpinner from 'src/components/LoadingSpinner/LoadingSpinner';
import { LoadingSpinnerContainer } from 'src/components/LoadingSpinner/LoadingSpinnerContainer';
import MessageBox, { MessageBoxStyles } from 'src/components/MessageBox/MessageBox';
import OrderItemList from 'src/components/Order/OrderItemList';
import OrderSummary, {
  OrderSummaryOptions,
  OrderSummaryStyles,
} from 'src/components/OrderSummary/OrderSummary';
import { MARKETPLACE_STRING } from 'src/constants/amazonanywhere';
import { AMAZON_ACCOUNTS_PAGE } from 'src/constants/links';
import { getRedirectUrl } from 'src/constants/lwa';
import { BATCH_SIZE } from 'src/constants/roadie';
import {
  ACCOUNT_SETTING,
  CONDITIONS_OF_USE,
  CONFIRM_ORDER_STRING,
  CONTINUE_SHOPPING_STRING,
  DEFAULT_PAYMENT_AND_ADDRESS_NOTE,
  EMPTY_CART_ERROR_STRING,
  LOCALE_STRING,
  PRIVACY_NOTICE,
  PRIVACY_NOTICE_AND_CONDITION_OF_USE_NOTE,
  TAKE_ME_TO_CART_STRING,
  UH_OH,
  YOUR_ORDER_STRING,
} from 'src/constants/strings';
import { getSite } from 'src/frameworks/GetSite';
import { recordClick } from 'src/metrics';
import { ActionType } from 'src/metricsTypes/metricsTypes';
import { Paths } from 'src/paths/Paths';
import {
  selectAuthState,
  selectClientId,
  selectCsrfToken,
  selectExpiryTime,
  selectValidateTokenComplete,
  selectValidateTokenInProgress,
} from 'src/store/authSlice';
import {
  getConfirmOrder,
  getPreviewOrder,
  resetPreviewOrderState,
  selectCart,
  selectConfirmOrderComplete,
  selectOrder,
  selectOrderError,
  selectPreviewOrderComplete,
  selectPreviewOrderInProgress,
} from 'src/store/cartSlice';
import {
  hydrateCatalog,
  selectCatalog,
  selectCatalogError,
  selectHydrateCatalogComplete,
} from 'src/store/catalogSlice';
import { selectSessionId } from 'src/store/identifierSlice';
import { AppDispatch } from 'src/store/store';
import {
  findCatalogItemUsingPurchaseItem,
  getErrorMessage,
  getErrorMessageLink,
  getErrorMessageText,
  handleAuth,
  reloadWindowOnStorageChange,
} from 'src/utils/utils';
import styled from 'styled-components';

const OrderPageContainer = styled.div`
  font-family: var(--font-family), serif;
  box-sizing: border-box;
  width: 100%;
  max-width: 1350px;
  margin: auto;
  @media (max-width: 700px) {
    padding: 1rem;
  }
`;

const OrderContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const OrderHeader = styled.div`
  padding: 0;
  width: 100%;
  @media (max-width: 600px) {
    text-align: center;
  }
`;

const OrderHeaderText = styled.div`
  font-size: 3rem;
`;

const OrderNote = styled.div`
  font-size: 1.25rem;
  padding-top: 1em;
  padding-bottom: 1em;
  color: rgb(155, 152, 152);
  @media (max-width: 375px) {
    text-align: left;
  }
  a,
  a:visited,
  a:hover,
  a:active {
    cursor: pointer;
    color: var(--secondary-color);
    text-decoration: underline;
  }
`;

export interface OrderPageStyles {
  loadingSpinnerContainer?: React.CSSProperties;
  outerErrorContainer?: React.CSSProperties;
  errorButtonContainer?: React.CSSProperties;
  errorContainer?: React.CSSProperties;
  errorMessageBoxStyle?: MessageBoxStyles;
  errorButtonStyle?: ButtonStyles;
  orderPageContainer?: React.CSSProperties;
  orderContent?: React.CSSProperties;
  orderHeader?: React.CSSProperties;
  orderHeaderText?: React.CSSProperties;
  orderItemStyle?: Record<string, React.CSSProperties>;
  orderItemListStyle?: Record<string, React.CSSProperties>;
  orderSummaryStyle?: OrderSummaryStyles;
  paymentAndAddressNote?: React.CSSProperties;
  privacyNoticeAndConditionOfUseNote?: React.CSSProperties;
  checkoutButtonStyles?: CheckoutButtonStyles;
}
export interface OrderPageProps extends SiteProps {
  style?: OrderPageStyles;
  orderSummaryOptions?: OrderSummaryOptions;
}

export const OrderPage = (props: OrderPageProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const cart = useSelector(selectCart);
  const order = useSelector(selectOrder);
  const error = useSelector(selectOrderError);
  const previewOrderInProgress = useSelector(selectPreviewOrderInProgress);
  const catalog = useSelector(selectCatalog);
  const hydrateCatalogComplete = useSelector(selectHydrateCatalogComplete);
  const hydrateCatalogError = useSelector(selectCatalogError);
  const previewOrderComplete = useSelector(selectPreviewOrderComplete);
  const confirmOrderComplete = useSelector(selectConfirmOrderComplete);
  const [cartItems, setCartItems] = useState<PurchaseItem[]>([]);
  const [validHydratedCartItems, setValidHydratedCartItems] = useState<PurchaseItem[]>([]);
  const accessTokenExists = useSelector(selectValidateTokenComplete);
  const expiryTime = useSelector(selectExpiryTime);
  const validTokenInProgress = useSelector(selectValidateTokenInProgress);
  const clientId = useSelector(selectClientId);
  const authState = useSelector(selectAuthState);
  const csrfToken = useSelector(selectCsrfToken);
  const sessionId = useSelector(selectSessionId);

  const queryParams = new URLSearchParams();
  queryParams.set('confirmOrderComplete', confirmOrderComplete ?? '');
  const urlWithParams = `${Paths.successCheckout}?${queryParams.toString()}`;

  const goCart = (): void => {
    recordClick(
      props.siteType,
      ActionType.GO_CART,
      props.includeSessionIdInMetrics ? sessionId : undefined,
    );
    navigate(`/cart`);
  };

  const goStore = (): void => {
    recordClick(
      props.siteType,
      ActionType.GO_STORE,
      props.includeSessionIdInMetrics ? sessionId : undefined,
    );
    navigate(`/store`);
  };

  // Update title
  useEffect(() => {
    document.title = `${CONFIRM_ORDER_STRING} - ${getSite()?.name}`;
  });

  // Resets order state once to avoid showing stale order.
  useEffect(() => {
    dispatch(resetPreviewOrderState());
  }, []);

  useEffect(() => {
    reloadWindowOnStorageChange(dispatch, authState);
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(confirmOrderComplete)) {
      recordClick(
        props.siteType,
        ActionType.GO_SUCCESS,
        props.includeSessionIdInMetrics ? sessionId : undefined,
      );
      navigate(urlWithParams);
    }
  }, [confirmOrderComplete]);

  // Hydrate catalog so cart items have up to date info in the catalog
  useEffect(() => {
    if (!isEmpty(cart)) {
      setCartItems(cart?.items ?? []);
      const itemIdentifiers =
        cart?.items.map((cartItem) => {
          return {
            itemAsin: cartItem.asin,
            customId: cartItem.customId,
            customizationToken: cartItem.customizationToken,
          } as ItemIdentifier;
        }) ?? [];
      // Separate item identifiers into chunks
      for (let i = 0; i < itemIdentifiers.length; i += BATCH_SIZE) {
        const chunk = itemIdentifiers.slice(i, i + BATCH_SIZE);
        dispatch(
          hydrateCatalog({
            itemIdentifiers: chunk,
            site: props.siteType,
            locale: LOCALE_STRING,
            csrfToken: csrfToken,
          }),
        );
      }
    }
  }, [cart, accessTokenExists, csrfToken]);

  // Only use valid cart items i.e. ones that can be hydrated
  useEffect(() => {
    if (!isEmpty(catalog) && !isEmpty(cartItems)) {
      const hydratedCartItems: PurchaseItem[] =
        cartItems
          .map((item) => {
            const catalogItem: ItemMetadata | null = findCatalogItemUsingPurchaseItem(
              catalog,
              item,
            );
            if (catalogItem) {
              return {
                asin: catalogItem.identifier?.itemAsin,
                customId: catalogItem.identifier?.customId,
                customizationToken: catalogItem.identifier?.customizationToken,
                merchantId: catalogItem.merchantId,
                offerId: catalogItem.offerId,
                quantity: item.quantity,
              } as PurchaseItem;
            } else {
              return null;
            }
          })
          .filter((item): item is PurchaseItem => item !== null) ?? [];
      setValidHydratedCartItems(hydratedCartItems);
    }
  }, [catalog, cartItems]);

  useEffect(() => {
    if (hydrateCatalogComplete && !isEmpty(validHydratedCartItems) && !validTokenInProgress) {
      const currentTimestamp = Date.now();
      const isExpired = expiryTime ? currentTimestamp > expiryTime : true;
      if (isExpired || !accessTokenExists) {
        handleAuth(clientId ?? '', getRedirectUrl(props.siteType));
      } else if (accessTokenExists && validHydratedCartItems)
        dispatch(
          getPreviewOrder({
            site: props.siteType,
            marketplaceId: MARKETPLACE_STRING,
            locale: LOCALE_STRING,
            purchaseItems: validHydratedCartItems,
            csrfToken: csrfToken,
          }),
        );
    }
  }, [validHydratedCartItems, accessTokenExists, csrfToken]);

  useEffect(() => {
    if (hydrateCatalogError) {
      handleAuth(clientId ?? '', getRedirectUrl(props.siteType));
    }
  }, [hydrateCatalogError]);

  const onClickConfirmOrder = () => {
    recordClick(
      props.siteType,
      ActionType.CONFIRM_ORDER,
      props.includeSessionIdInMetrics ? sessionId : undefined,
    );
    const currentTimestamp = Date.now();
    const isExpired = expiryTime ? currentTimestamp > expiryTime : true;
    if (isExpired || !accessTokenExists) {
      handleAuth(clientId ?? '', getRedirectUrl(props.siteType));
    } else if (previewOrderComplete && accessTokenExists) {
      dispatch(
        getConfirmOrder({
          site: props.siteType,
          purchaseId: previewOrderComplete,
          csrfToken: csrfToken,
        }),
      );
    }
  };

  const getErrorMessageBox = (errorCodes: string[]) => {
    return (
      <MessageBox
        heading={UH_OH}
        text={getErrorMessage(errorCodes)}
        link={getErrorMessageLink(errorCodes, props.sitePrefix)}
        linkText={getErrorMessageText(errorCodes)}
        styles={props.style?.errorMessageBoxStyle}
      />
    );
  };

  const openAccountSettings = () => {
    const accountSettings = window.open(AMAZON_ACCOUNTS_PAGE(props.sitePrefix));
    const timer = setInterval(function () {
      if (accountSettings?.closed) {
        clearInterval(timer);
        window.location.reload();
      }
    }, 1000);
  };

  return (
    <>
      {previewOrderInProgress && (
        <LoadingSpinnerContainer
          data-testid="loadingSpinnerContainer"
          style={props.style?.loadingSpinnerContainer}
        >
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      )}
      {!previewOrderInProgress && !isEmpty(error) && (
        <OuterErrorContainer
          data-testid="outerErrorContainer"
          style={props.style?.outerErrorContainer}
        >
          <ErrorContainer data-testid="errorContainer" style={props.style?.errorContainer}>
            {getErrorMessageBox((error as string[]) ?? [])}
          </ErrorContainer>
          <ErrorButtonContainer
            data-testid="errorButtonContainer"
            style={props.style?.errorButtonContainer}
          >
            <Button
              text={TAKE_ME_TO_CART_STRING}
              onClick={goCart}
              styles={props.style?.errorButtonStyle}
              classNamePrefix={props.sitePrefix}
            />
          </ErrorButtonContainer>
        </OuterErrorContainer>
      )}
      {!previewOrderInProgress && isEmpty(error) && isEmpty(cart?.items) && (
        <OuterErrorContainer
          data-testid="outerErrorContainer"
          style={props.style?.outerErrorContainer}
        >
          <ErrorContainer data-testid="errorContainer" style={props.style?.errorContainer}>
            <MessageBox
              heading={UH_OH}
              text={EMPTY_CART_ERROR_STRING}
              styles={props.style?.errorMessageBoxStyle}
            />
          </ErrorContainer>
          <ErrorButtonContainer
            data-testid="errorButtonContainer"
            style={props.style?.errorButtonContainer}
          >
            <Button
              text={CONTINUE_SHOPPING_STRING}
              onClick={goStore}
              styles={props.style?.errorButtonStyle}
              classNamePrefix={props.sitePrefix}
            />
          </ErrorButtonContainer>
        </OuterErrorContainer>
      )}
      {!previewOrderInProgress && isEmpty(error) && order && (
        <OrderPageContainer
          data-testid="orderPageContainer"
          className={`${props.sitePrefix}-orderPageContainer`}
          style={props.style?.orderPageContainer}
        >
          <OrderContent data-testid="orderContent" style={props.style?.orderContent}>
            <OrderHeader data-testid="orderHeader" style={props.style?.orderHeader}>
              <OrderHeaderText data-testid="orderHeaderText" style={props.style?.orderHeaderText}>
                {YOUR_ORDER_STRING}
              </OrderHeaderText>
            </OrderHeader>
            <OrderItemList
              siteType={props.siteType}
              itemStyles={props.style?.orderItemStyle}
              styles={props.style?.orderItemListStyle}
            />
            <OrderSummary
              charges={order.charges}
              shippingAddress={order.shippingAddress}
              paymentMethod={order.paymentMethods?.[0]}
              options={props.orderSummaryOptions}
              styles={props.style?.orderSummaryStyle}
            />
            <OrderNote style={props.style?.paymentAndAddressNote}>
              {DEFAULT_PAYMENT_AND_ADDRESS_NOTE}{' '}
              <a
                data-testid="accountSettingLink"
                onClick={openAccountSettings}
                rel="noreferrer"
                target="_blank"
              >
                {ACCOUNT_SETTING}.
              </a>
            </OrderNote>

            {/* TODO Confirm order button to accept styling OR take in a component? */}
            <CheckoutButton
              type={CheckoutButtonType.CONFIRM_ORDER}
              onClick={onClickConfirmOrder}
              testId="confirmOrderButton"
              style={props.style?.checkoutButtonStyles}
              classNamePrefix={props.sitePrefix}
            />
            <OrderNote
              style={props.style?.privacyNoticeAndConditionOfUseNote}
              className={`${props.sitePrefix}-orderNote`}
            >
              {PRIVACY_NOTICE_AND_CONDITION_OF_USE_NOTE}{' '}
              <a
                data-testid="privacyNoticeLink"
                href="https://www.amazon.com/gp/help/customer/display.html?nodeId=468496&ref_=footer_privacy"
                rel="noreferrer"
                target="_blank"
              >
                {PRIVACY_NOTICE}
              </a>
              {' and '}
              <a
                data-testid="conditionOfUseLink"
                href="https://www.amazon.com/gp/help/customer/display.html?nodeId=508088&ref_=footer_cou"
                rel="noreferrer"
                target="_blank"
              >
                {CONDITIONS_OF_USE}
              </a>
            </OrderNote>
          </OrderContent>
        </OrderPageContainer>
      )}
    </>
  );
};
