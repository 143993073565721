import 'src/sites/Plumeria/components/Cart/PlumeriaCartItem.scss';

import CartItem, { CartItemProps, CartItemStyle } from 'src/components/Cart/CartItem';
import { quantitySelectorPlumeriaStyle } from 'src/sites/Plumeria/styles/QuantitySelectorStyles';

const $style: CartItemStyle = {
  cartItemContainer: {
    color: 'var(--secondary-color)',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    gap: '1rem',
    padding: '0.5rem',
    boxSizing: 'border-box',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cartItemInfoContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  cartItemImage: {
    width: 175,
    height: 175,
    objectFit: 'contain',
  },
  cartTitleString: {
    paddingRight: '2rem',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    MozBoxOrient: 'vertical',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '1.4rem',
    width: '100%',
    boxSizing: 'border-box',
  },
  cartItemFirstColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxWidth: 670,
    minWidth: 230,
    marginBottom: 15,
  },
  cartItemInnerContainer: { display: 'flex', flexDirection: 'column' },
  cartItemSecondColumn: {
    flexDirection: 'row',
    width: 200,
    display: 'flex',
  },
  cartItemThirdColumn: {
    height: '2.5rem',
    width: 100,
    alignContent: 'end',
    textAlign: 'end',
    right: '0',
  },
  cartItemFirstRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '0.25rem',
  },
  cartPrimeBadgeContainer: {
    width: 'auto',
    marginTop: '4px',
    marginBottom: '4px',
  },
  removeItemButtonIcon: {
    color: 'var(--secondary-color)',
    cursor: 'pointer',
  },
  totalPriceContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    fontSize: '1.5rem',
    marginTop: 'auto',
    marginBottom: 'auto',
    height: '2.5rem',
    alignItems: 'center',
    alignContent: 'end',
    marginLeft: 'auto',
    color: 'var(--tertiary-color)',
  },
  variationOuterContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  variationContainer: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '1.2rem',
    fontFamily: 'var(--font-family)',
    marginRight: '0.5rem',
  },
  variationKeyContainer: { marginRight: '0.15rem' },
  cartPriceContainer: {
    marginBottom: '0.5rem',
    fontSize: '1.5rem',
    maxWidth: 300,
    width: '100%',
    color: 'var(--tertiary-color)',
  },
  cartAvailabilityString: {
    fontSize: '1rem',
  },
  cartItemQuantitySelectorStyle: quantitySelectorPlumeriaStyle,
  deliveryMessageContainer: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '0.8rem',
    fontFamily: 'var(--font-family)',
    marginRight: '0.5rem',
    fontWeight: '300',
    opacity: 0.8,
  },
};
const PlumeriaCartItem = (props: CartItemProps) => {
  return <CartItem {...props} styles={$style} />;
};

export default PlumeriaCartItem;
