import { ItemIdentifier, ItemMetadata, MerchItem } from '@amzn/mulberry-external-typescript-client';
import { Grid } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from 'src/components/LoadingSpinner/LoadingSpinner';
import { LoadingSpinnerContainer } from 'src/components/LoadingSpinner/LoadingSpinnerContainer';
import { exampleMerchGridItemCss } from 'src/components/MerchGrid/exampleCSS';
import MerchGridItem from 'src/components/MerchGrid/MerchGridItem';
import MessageBox from 'src/components/MessageBox/MessageBox';
import { BATCH_SIZE } from 'src/constants/roadie';
import { GENERIC_ERROR_STRING, LOCALE_STRING, UH_OH } from 'src/constants/strings';
import { SiteType } from 'src/frameworks/models/SiteType';
import { selectCsrfToken } from 'src/store/authSlice';
import {
  hydrateCatalog,
  selectCatalog,
  selectCatalogError,
  selectHydrateCatalogComplete,
} from 'src/store/catalogSlice';
import { fetchCollection, selectCollections, selectMerchError } from 'src/store/merchSlice';
import { AppDispatch } from 'src/store/store';

interface MerchGridProps {
  includeSessionIdInMetrics: boolean;
  itemRenderer?: (merchItem: MerchItem, metadata: ItemMetadata) => React.ReactNode;
  engagementId: string;
  site: SiteType;
  numColumnsSmallScreen?: number;
  numColumnsMediumScreen?: number;
  numColumnsLargeScreen: number;
  gridSpacing?: number;
  errorRenderer?: () => React.ReactNode;
  styles?: {
    merchGridContainer?: React.CSSProperties;
  };
}

const MerchGrid = (props: MerchGridProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const hydrateCatalogComplete = useSelector(selectHydrateCatalogComplete);
  const catalogError = useSelector(selectCatalogError);
  const merchError = useSelector(selectMerchError);
  const csrfToken = useSelector(selectCsrfToken);

  // Fetch collection
  useEffect(() => {
    dispatch(
      fetchCollection({ engagementId: props.engagementId, site: props.site, csrfToken: csrfToken }),
    );
  }, []);

  const collectionEqual = (
    oldValue: { [key: string]: MerchItem[] },
    newValue: { [key: string]: MerchItem[] },
  ) => isEqual(oldValue[props.engagementId], newValue[props.engagementId]);
  const merchItems = useSelector(selectCollections, collectionEqual)[props.engagementId] ?? [];

  // Hydrate catalog
  useEffect(() => {
    const itemIdentifiers = merchItems.map((merchItem) => {
      return {
        itemAsin: merchItem.itemAsin,
      } as ItemIdentifier;
    });
    // Separate item identifiers into chunks
    for (let i = 0; i < itemIdentifiers.length; i += BATCH_SIZE) {
      const chunk = itemIdentifiers.slice(i, i + BATCH_SIZE);
      dispatch(
        hydrateCatalog({
          itemIdentifiers: chunk,
          site: props.site,
          locale: LOCALE_STRING,
          csrfToken: csrfToken,
        }),
      );
    }
  }, [merchItems, csrfToken]);
  const catalog: ItemMetadata[] = (useSelector(selectCatalog) ?? [])
    .filter((itemMetadata) => {
      // Filter merch items for this engagement id
      const index = merchItems.findIndex((merchItem) => {
        return merchItem.itemAsin === itemMetadata.identifier?.itemAsin;
      });
      return index >= 0;
    })
    .reduce(function (acc: ItemMetadata[], currentItem) {
      // Filter out duplicates
      const index = acc
        .map((item) => item.identifier?.itemAsin)
        .indexOf(currentItem.identifier?.itemAsin);
      if (index < 0) {
        acc.push(currentItem);
      }
      return acc;
    }, [])
    .sort((a, b) => {
      const orderedCollection = merchItems.map((merchItem) => {
        return merchItem.itemAsin;
      });
      return (
        orderedCollection.indexOf(a.identifier?.itemAsin) -
        orderedCollection.indexOf(b.identifier?.itemAsin)
      );
    });
  // Column widths are integer values between 1 and 12
  const small = 12 / (props.numColumnsSmallScreen ?? 12);
  const medium = 12 / (props.numColumnsMediumScreen ?? 12);
  const large = 12 / props.numColumnsLargeScreen;

  if (!hydrateCatalogComplete) {
    return (
      <LoadingSpinnerContainer data-testid="loadingSpinnerContainer">
        <LoadingSpinner />
      </LoadingSpinnerContainer>
    );
  }

  if (merchError || (catalogError && hydrateCatalogComplete && isEmpty(catalog))) {
    return (
      <div className="merchGridErrorContainer" data-testid="merchGridErrorContainer">
        {props.errorRenderer ? (
          // Use error renderer if provided
          props.errorRenderer()
        ) : (
          <MessageBox heading={UH_OH} text={GENERIC_ERROR_STRING} />
        )}
      </div>
    );
  }

  return (
    <div
      className="merchGridContainer"
      data-testid="merchGridContainer"
      style={props.styles?.merchGridContainer}
    >
      <Grid container spacing={props.gridSpacing ?? 1}>
        {catalog.map((itemMetadata) => (
          <Grid
            item
            xs={12}
            sm={small}
            md={medium}
            lg={large}
            key={itemMetadata.identifier?.itemAsin}
          >
            {props.itemRenderer ? (
              // Use item renderer if provided
              props.itemRenderer({ itemAsin: itemMetadata.preferredAsin }, itemMetadata)
            ) : (
              // Otherwise use default merch grid item
              <MerchGridItem
                includeSessionIdInMetrics={props.includeSessionIdInMetrics}
                merchItem={{ itemAsin: itemMetadata.identifier?.itemAsin }}
                metadata={itemMetadata}
                styles={exampleMerchGridItemCss}
              />
            )}
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default MerchGrid;
