import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SiteProps } from 'src/components/App/App';
import Button, { ButtonStyles } from 'src/components/Button/Button';
import {
  ErrorButtonContainer,
  ErrorContainer,
  OuterErrorContainer,
} from 'src/components/Error/errorStyledComponents';
import MessageBox, { MessageBoxStyles } from 'src/components/MessageBox/MessageBox';
import { NOT_FOUND_STRING, TAKE_ME_HOME_STRING, UH_OH } from 'src/constants/strings';
import { recordClick } from 'src/metrics';
import { ActionType } from 'src/metricsTypes/metricsTypes';
import { selectSessionId } from 'src/store/identifierSlice';

export interface NotFoundPageStyles {
  errorButtonContainer?: React.CSSProperties;
  errorContainer?: React.CSSProperties;
  pageContainerStyle?: React.CSSProperties;
  errorMessageBoxStyle?: MessageBoxStyles;
  errorButtonStyle?: ButtonStyles;
}
export interface NotFoundPageProps extends SiteProps {
  style?: NotFoundPageStyles;
}

export const NotFoundPage = (props: NotFoundPageProps) => {
  const navigate = useNavigate();
  const sessionId = useSelector(selectSessionId);

  const goHome = (): void => {
    recordClick(
      props.siteType,
      ActionType.GO_HOME,
      props.includeSessionIdInMetrics ? sessionId : undefined,
    );
    navigate(`/`);
  };
  return (
    <OuterErrorContainer data-testid="outerErrorContainer" style={props.style?.pageContainerStyle}>
      <ErrorContainer data-testid="errorContainer" style={props.style?.errorContainer}>
        <MessageBox
          heading={UH_OH}
          text={NOT_FOUND_STRING}
          styles={props.style?.errorMessageBoxStyle}
          testId="messageBox"
        />
      </ErrorContainer>
      <ErrorButtonContainer
        data-testid="errorButtonContainer"
        style={props.style?.errorButtonContainer}
      >
        <Button
          text={TAKE_ME_HOME_STRING}
          onClick={goHome}
          styles={props.style?.errorButtonStyle}
          classNamePrefix={props.sitePrefix}
          testId="errorButton"
        />
      </ErrorButtonContainer>
    </OuterErrorContainer>
  );
};
