import { ItemMetadata, MerchItem } from '@amzn/mulberry-external-typescript-client';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { recordClick } from 'src/metrics';
import { ActionType } from 'src/metricsTypes/metricsTypes';
import { selectSessionId } from 'src/store/identifierSlice';

interface MerchGridItemProps {
  merchItem: MerchItem;
  metadata: ItemMetadata;
  includeSessionIdInMetrics: boolean;
  metrics?: {
    siteName: string;
    pageName?: string;
  };
  styles?: {
    merchGridItemContainer?: React.CSSProperties;
    merchGridItemImage?: React.CSSProperties;
  };
}

const MerchGridItem = (props: MerchGridItemProps) => {
  const navigate = useNavigate();
  const image = props.metadata.images?.[0];
  const sessionId = useSelector(selectSessionId);

  const goProductDetail = (): void => {
    if (props.metrics) {
      recordClick(
        props.metrics.siteName,
        ActionType.GO_PRODUCT_DETAIL,
        props.includeSessionIdInMetrics ? sessionId : undefined,
        props.merchItem.itemAsin,
        props.metrics.pageName,
      );
    }
    navigate(`/product/${props.merchItem.itemAsin}`);
  };

  return (
    <div
      className="merchGridItemContainer"
      data-testid="merchGridItemContainer"
      style={props.styles?.merchGridItemContainer}
    >
      <img
        data-testid="merchGridItemImage"
        onClick={goProductDetail}
        src={image?.lowRes?.url ?? ''}
        alt={image?.altText ?? ''}
        style={props.styles?.merchGridItemImage}
      />
    </div>
  );
};

export default MerchGridItem;
