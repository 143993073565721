import { combineReducers } from '@reduxjs/toolkit';
import authReducer from 'src/store/authSlice';
import cartReducer from 'src/store/cartSlice';
import catalogReducer from 'src/store/catalogSlice';
import identifierReducer from 'src/store/identifierSlice';
import merchReducer from 'src/store/merchSlice';
import variationReducer from 'src/store/variationSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  merch: merchReducer,
  catalog: catalogReducer,
  cart: cartReducer,
  variation: variationReducer,
  identifier: identifierReducer,
});

export default rootReducer;
