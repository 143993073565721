export const exampleCartItemCss: Record<string, React.CSSProperties> = {
  cartItemContainer: {
    border: '1px solid #ddd',
    padding: '16px',
    borderRadius: '8px',
    background: '#f8f8f8',
    color: '#542848',
    width: '800px',
    margin: '16px 0 16px 0',
    display: 'flex',
  },
  cartItemImage: { width: '100px', height: '100px' },
  cartItemInfoContainer: { marginRight: '20px' },
  cartTitleString: { marginBottom: '12px', fontSize: '24px' },
  cartAvailabilityString: { marginBottom: '12px' },

  variationContainer: { display: 'flex' },
  variationOuterContainer: { display: 'flex' },
  variationKeyContainer: { marginRight: '4px' },
  variationValueContainer: { marginRight: '10px', color: 'blue' },
};

export const buttonContainerStyle = {
  buttonContainer: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
};

export const exampleCartListCss: Record<string, React.CSSProperties> = {
  cartListContainer: {
    listStyleType: 'none',
  },
  cartListItem: {
    listStyleType: 'none',
  },
  emptyCartListMessage: {
    marginBottom: '40px',
    color: 'white',
  },
  emptyCartListContainer: {
    marginBottom: '40px',
    padding: '12px',
    border: '1px solid #ddd',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'inline-flex',
    flexDirection: 'column',
    width: '400px',
    height: '200px',
  },
  buttonContainer: {
    fontWeight: 'bold',
    padding: '10px 20px',
    background: 'linear-gradient(to bottom, #333, #555)',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background 0.3s ease',
  },
};
