import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'src/components/Button/Button';
import MessageBox, { MessageBoxStyles } from 'src/components/MessageBox/MessageBox';
import {
  CONTINUE_SHOPPING_STRING,
  GENERIC_ORDER_ERROR_STRING,
  ORDER_STATUS_INSTRUCTION_STRING,
  TAKE_ME_HOME_STRING,
  TAKE_ME_TO_ORDERS_STRING,
  UH_OH,
  YOUR_ORDER_CONFIRMED_STRING,
} from 'src/constants/strings';
import { SiteType } from 'src/frameworks/models/SiteType';
import { recordClick } from 'src/metrics';
import { ActionType } from 'src/metricsTypes/metricsTypes';
import { NavigationProps } from 'src/shared/withRouter';
import { CartState, replaceCartState, selectOrderError } from 'src/store/cartSlice';
import { selectSessionId } from 'src/store/identifierSlice';
import { AppDispatch } from 'src/store/store';

export interface SuccessStyle {
  successMessageBox?: MessageBoxStyles;
  errorMessageBox?: MessageBoxStyles;
  successButtonContainer?: React.CSSProperties;
  successButton?: React.CSSProperties;
  errorContainer?: React.CSSProperties;
  errorMessageBoxButton?: React.CSSProperties;
  successContainer?: React.CSSProperties;
}
export interface SuccessProps {
  includeSessionIdInMetrics: boolean;
  navigation: NavigationProps;
  sitePrefix: string;
  siteType: SiteType;
  styles?: SuccessStyle;
}

export const Success = (props: SuccessProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector(selectOrderError);
  const confirmOrderComplete = props.navigation?.params?.get('confirmOrderComplete') || '';
  const SITE_PREFIX = props.sitePrefix;
  const sessionId = useSelector(selectSessionId);

  useEffect(() => {
    if (confirmOrderComplete && isEmpty(error)) {
      dispatch(
        replaceCartState({
          cart: null,
          previewOrderInProgress: false,
          confirmOrderInProgress: false,
        } as CartState),
      );
    }
  }, []);

  const goHome = (): void => {
    recordClick(
      props.siteType,
      ActionType.GO_HOME,
      props.includeSessionIdInMetrics ? sessionId : undefined,
    );
    props.navigation.navigate(`/`);
  };

  return (
    <>
      {confirmOrderComplete && isEmpty(error) ? (
        <div
          className={`${SITE_PREFIX}-successContainer`}
          data-testid="success"
          style={props.styles?.successContainer}
        >
          <MessageBox
            heading={YOUR_ORDER_CONFIRMED_STRING}
            text={ORDER_STATUS_INSTRUCTION_STRING}
            link={confirmOrderComplete}
            linkText={TAKE_ME_TO_ORDERS_STRING}
            styles={props.styles?.successMessageBox}
            testId="successMessageBox"
          />
          <div
            className={`${SITE_PREFIX}-successButtonContainer`}
            data-testid="successButtonContainer"
            style={props.styles?.successButtonContainer}
          >
            <Button
              text={CONTINUE_SHOPPING_STRING}
              onClick={goHome}
              classNamePrefix={SITE_PREFIX}
              styles={{ buttonContainer: props.styles?.successButton }}
            />
          </div>
        </div>
      ) : (
        <div
          className={`${SITE_PREFIX}-errorContainer`}
          data-testid="errorContainer"
          style={props.styles?.errorContainer}
        >
          <MessageBox
            heading={UH_OH}
            text={GENERIC_ORDER_ERROR_STRING}
            styles={props.styles?.errorMessageBox}
          />
          <div>
            <Button
              text={TAKE_ME_HOME_STRING}
              onClick={goHome}
              styles={{ buttonContainer: props.styles?.errorMessageBoxButton }}
              classNamePrefix={SITE_PREFIX}
            />
          </div>
        </div>
      )}
    </>
  );
};
