export const orderSummaryCSS: Record<string, React.CSSProperties> = {
  orderSummaryContainer: {
    color: 'var(--secondary-color)',
    marginBottom: '1rem',
    marginTop: '1rem',
    maxWidth: 300,
    alignSelf: 'flex-end',
    fontFamily: 'var(--font-family)',
    width: '100%',
  },
  orderShipmentContainer: {
    marginTop: '0',
  },
  chargeItemContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  chargeTypeString: {
    fontSize: '1.25rem',
  },
  chargeAmountString: {
    fontSize: '1.25rem',
  },
};
