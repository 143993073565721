import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import React from 'react';
import { BUY_WITH_AMAZON, CONFIRM_ORDER_STRING } from 'src/constants/strings';
import logo from 'src/images/amazon_logo.png';

export enum CheckoutButtonType {
  CHECKOUT,
  CONFIRM_ORDER,
}

export interface CheckoutButtonStyles {
  confirmButtonTextStyle?: React.CSSProperties;
  checkoutButtonTextStyle?: React.CSSProperties;
  confirmButtonStyle?: React.CSSProperties;
  checkoutButtonStyle?: React.CSSProperties;
  lockIconStyle?: React.CSSProperties;
}

export interface CheckoutButtonProps {
  disabled?: boolean;
  onClick: () => void;
  onHover?: () => void;
  testId?: string;
  classNamePrefix: string;
  type: CheckoutButtonType;
  style?: CheckoutButtonStyles;
}

const $confirmButtonStyle: React.CSSProperties = {
  height: '53px',
  borderRadius: '67px',
  padding: '4px 8px',
  border: '0px none',
  backgroundColor: 'rgb(89,151,242)',
  width: '300px',
  alignSelf: 'flex-end',
  alignContent: 'center',
  justifyContent: 'center',
  verticalAlign: 'center',
};

const $checkoutButtonStyle = (isDisabled: boolean): React.CSSProperties => ({
  height: '53px',
  borderRadius: '67px',
  padding: '4px 8px',
  border: '0px none',
  backgroundColor: '#FFD814',
  width: '300px',
  alignSelf: 'flex-end',
  alignContent: 'center',
  justifyContent: 'center',
  verticalAlign: 'center',
  filter: isDisabled ? 'grayscale(100%)' : 'none',
  opacity: isDisabled ? '50%' : '100',
});

const $logoStyle: React.CSSProperties = {
  marginLeft: '4px',
  marginTop: '8px',
  height: '20px',
  width: '64px',
  objectFit: 'contain',
};

const $lockIconStyle: React.CSSProperties = { color: 'white' };

const $checkoutButtonTextStyle: React.CSSProperties = {
  fontSize: '16px',
  color: 'black',
  fontWeight: 'bold',
  fontFamily: 'Amazon Ember V2',
  textTransform: 'none',
};

const $confirmButtonTextStyle: React.CSSProperties = {
  fontSize: '16px',
  color: 'white',
  fontWeight: 'bold',
  fontFamily: 'Amazon Ember V2',
  textTransform: 'none',
};

const CheckoutButton = (props: CheckoutButtonProps) => {
  const handleOnClick = () => {
    props.onClick();
  };

  return (
    <Button
      style={
        props.type === CheckoutButtonType.CHECKOUT
          ? {
              ...$checkoutButtonStyle(props.disabled ?? false),
              ...props.style?.checkoutButtonStyle,
            }
          : { ...$confirmButtonStyle, ...props.style?.confirmButtonStyle }
      }
      data-testid={props.testId}
      onClick={handleOnClick}
      disabled={props.disabled}
      startIcon={
        props.type === CheckoutButtonType.CONFIRM_ORDER ? (
          <LockIcon style={{ ...$lockIconStyle, ...props.style?.lockIconStyle }} />
        ) : null
      }
    >
      <span
        style={
          props.type === CheckoutButtonType.CONFIRM_ORDER
            ? { ...$confirmButtonTextStyle, ...props.style?.confirmButtonTextStyle }
            : { ...$checkoutButtonTextStyle, ...props.style?.checkoutButtonTextStyle }
        }
      >
        {props.type === CheckoutButtonType.CONFIRM_ORDER ? CONFIRM_ORDER_STRING : BUY_WITH_AMAZON}
      </span>
      {props.type === CheckoutButtonType.CONFIRM_ORDER ? null : (
        <img src={logo} style={$logoStyle} />
      )}
    </Button>
  );
};

export default CheckoutButton;
