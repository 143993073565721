export const sanitizeAsinUtil = (recievedAsin: string): string => {
  // Regular expression to match only capital letters and numbers i.e. ASIN format
  const asinRegex = /[A-Z0-9]{10}/;

  // Check if the recieved ASIN string matches the regex
  const sanitizedAsin = recievedAsin.match(asinRegex);

  if (!sanitizedAsin) {
    // If not, return an empty string
    return '';
  }

  // .match returns an array of matched strings, return the first
  return sanitizedAsin[0];
};
