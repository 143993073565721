import { ListMerchRequest, MerchItem } from '@amzn/mulberry-external-typescript-client';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { SiteType } from 'src/frameworks/models/SiteType';
import { logger } from 'src/logger';
import { recordAPIError, recordAPIRequest } from 'src/metrics';
import { RoadieService } from 'src/services/roadie';

export interface MerchState {
  getCollectionInProgress: boolean;
  collections: { [key: string]: MerchItem[] };
  error?: boolean;
}

export const initialMerchState: MerchState = {
  getCollectionInProgress: false,
  collections: {},
};

const fetchCollection = createAsyncThunk(
  'merch/fetchCollection',
  async ({
    engagementId,
    site,
    csrfToken,
  }: {
    engagementId: string;
    site: SiteType;
    csrfToken?: string;
  }) => {
    recordAPIRequest(site, 'fetchCollection');
    try {
      const service = new RoadieService(site, csrfToken);
      const listMerchRequest: ListMerchRequest = {
        engagementId,
      };
      const listMerchResponse = await service.listMerch(listMerchRequest);
      let items = listMerchResponse.items ?? [];
      let paginationToken = listMerchResponse.paginationToken;
      while (paginationToken) {
        const requestWithPaginationToken: ListMerchRequest = {
          engagementId,
          paginationToken,
        };
        const responseFromPagination = await service.listMerch(requestWithPaginationToken);
        items = items.concat(responseFromPagination.items ?? []);
        paginationToken = responseFromPagination.paginationToken;
      }
      return {
        items: items ?? [],
        engagementId,
      };
    } catch (error: any) {
      let status = undefined;
      if (isAxiosError(error)) {
        status = error.response?.status;
      }
      recordAPIError(site, 'fetchCollection', status);
      logger.error(`Error fetching merch collection: ${error}`, {
        site: site,
      });
      throw error;
    }
  },
);

const merchSlice = createSlice({
  name: 'merch',
  initialState: initialMerchState,
  reducers: {
    replaceMerchState: (state, action: PayloadAction<MerchState>) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCollection.pending, (state) => {
        return {
          ...state,
          getCollectionInProgress: true,
        };
      })
      .addCase(fetchCollection.fulfilled, (state, action) => {
        const { items, engagementId } = action.payload;
        return {
          ...state,
          collections: {
            ...state.collections,
            [engagementId]: items,
          },
          getCollectionInProgress: false,
          error: undefined,
        };
      })
      .addCase(fetchCollection.rejected, (state) => {
        return {
          ...state,
          getCollectionInProgress: false,
          error: true,
        };
      });
  },
  selectors: {
    selectGetCollectionInProgress: (state) => state.getCollectionInProgress,
    selectCollections: (state) => state.collections,
    selectMerchError: (state) => state.error,
  },
});

export const { replaceMerchState } = merchSlice.actions;

export const selectGetCollectionInProgress = merchSlice.selectors.selectGetCollectionInProgress;
export const selectCollections = merchSlice.selectors.selectCollections;
export const selectMerchError = merchSlice.selectors.selectMerchError;

export { fetchCollection };

export default merchSlice.reducer;
