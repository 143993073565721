import 'src/sites/TormentedValleyExternalPilot/components/ProductDetailPage/ProductDetailPage.css';

import { TORMENTED_VALLEY_PREFIX } from 'src/constants/strings';
import { SiteType } from 'src/frameworks/models/SiteType';
import ProductDetailPage, { ProductDetailPageStyles } from 'src/pages/ProductDetailPage';
import { descriptionBulletsTormentedValleyStyle } from 'src/sites/TormentedValleyExternalPilot//styles/DescriptionBulletsStyles';
import {
  tvAlertContainer,
  tvLoadingSpinnerContainer,
  tvproductDPLoadingContainer,
  tvVariationLabel,
} from 'src/sites/TormentedValleyExternalPilot/components/ProductDetailPage/ProductDetailPageCss';
import { buttonGroupTormentedValleyStyle } from 'src/sites/TormentedValleyExternalPilot/styles/ButtonGroupStyles';
import { buttonTormentedValleyStyles } from 'src/sites/TormentedValleyExternalPilot/styles/ButtonStyles';
import { errorContainerStyles } from 'src/sites/TormentedValleyExternalPilot/styles/ErrorContainerStyles';
import { errorTormentedValleyStyle } from 'src/sites/TormentedValleyExternalPilot/styles/ErrorStyles';
import { imageBoxTormentedValleyStyle } from 'src/sites/TormentedValleyExternalPilot/styles/ImageBoxStyles';
import { primaryInfoBoxTormentedValleyStyle } from 'src/sites/TormentedValleyExternalPilot/styles/PrimaryInfoBoxStyles';
import { quantitySelectorTormentedValleyStyle } from 'src/sites/TormentedValleyExternalPilot/styles/QuantitySelectorStyles';
import { TV_INCLUDE_SESSION_ID_IN_METRICS } from 'src/sites/TormentedValleyExternalPilot/TormentedValley';

const TormentedValleyProductDetailPage = () => {
  const $style: ProductDetailPageStyles = {
    loadingSpinnerContainer: tvLoadingSpinnerContainer,
    productDPErrorContainer: errorContainerStyles,
    messageBoxErrorStyles: errorTormentedValleyStyle,
    imageBoxStyles: imageBoxTormentedValleyStyle,
    primaryInfoBoxStyles: primaryInfoBoxTormentedValleyStyle,
    variationLabel: tvVariationLabel,
    buttonGroupStyles: buttonGroupTormentedValleyStyle,
    quantitySelectorStyles: quantitySelectorTormentedValleyStyle,
    buttonStyles: buttonTormentedValleyStyles,
    alertContainer: tvAlertContainer,
    descriptionBulletsStyle: descriptionBulletsTormentedValleyStyle,
    productDPLoadingContainer: tvproductDPLoadingContainer,
  };
  return (
    <ProductDetailPage
      siteType={SiteType.TORMENTEDVALLEY}
      sitePrefix={TORMENTED_VALLEY_PREFIX}
      style={$style}
      includeSessionIdInMetrics={TV_INCLUDE_SESSION_ID_IN_METRICS}
    />
  );
};

export default TormentedValleyProductDetailPage;
