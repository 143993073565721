import {
  GetItemMetadataRequest,
  ItemIdentifier,
  ItemMetadata,
} from '@amzn/mulberry-external-typescript-client';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError, isAxiosError } from 'axios';
import isEqual from 'lodash/isEqual';
import { SiteType } from 'src/frameworks/models/SiteType';
import { logger } from 'src/logger';
import { recordAPIError, recordAPIRequest } from 'src/metrics';
import { RoadieService } from 'src/services/roadie';

export interface CatalogState {
  hydrateCatalogComplete: boolean;
  catalog: ItemMetadata[];
  failed: ItemIdentifier[];
  error?: boolean;
}

export const initialCatalogState: CatalogState = {
  hydrateCatalogComplete: false,
  catalog: [],
  failed: [],
};

export const hydrateCatalog = createAsyncThunk(
  'merch/hydrateCatalog',
  async ({
    itemIdentifiers,
    site,
    locale,
    csrfToken,
  }: {
    itemIdentifiers: ItemIdentifier[];
    site: SiteType;
    locale: string;
    csrfToken?: string;
  }) => {
    recordAPIRequest(site, 'hydrateCatalog');
    try {
      const service = new RoadieService(site, csrfToken);
      const getItemMetadataRequest: GetItemMetadataRequest = {
        identifiers: itemIdentifiers,
        locale: locale,
      };
      return await service.getItemMetadata(getItemMetadataRequest);
    } catch (error: any) {
      if (error?.code === AxiosError.ECONNABORTED) {
        return { results: [], failed: [] };
      } else {
        let status = undefined;
        if (isAxiosError(error)) {
          status = error.response?.status;
        }
        recordAPIError(site, 'hydrateCatalog', status);
        logger.error(`Error hydrating catalog: ${error}`, {
          site: site,
        });
        throw error;
      }
    }
  },
);

const catalogSlice = createSlice({
  name: 'catalog',
  initialState: initialCatalogState,
  reducers: {
    replaceCatalogState: (state, action: PayloadAction<CatalogState>) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(hydrateCatalog.pending, (state) => {
        return {
          ...state,
          hydrateCatalogComplete: false,
        };
      })
      .addCase(hydrateCatalog.fulfilled, (state, action) => {
        const { results, failed } = action.payload;
        // Merge catalog
        const updatedCatalog = [...state.catalog];
        results?.forEach((result) => {
          const index = updatedCatalog.findIndex((itemMetadata) => {
            return isEqual(itemMetadata.identifier, result.identifier);
          });
          if (index == -1) {
            updatedCatalog.push(result);
          } else {
            updatedCatalog[index] = result;
          }
        });
        // Merge failed
        const updatedFailed = [...state.failed];
        failed?.forEach((fail) => {
          const index = updatedFailed.findIndex((itemIdentifier) => {
            return isEqual(itemIdentifier, fail);
          });
          if (index == -1) {
            updatedFailed.push(fail);
          } else {
            updatedFailed[index] = fail;
          }
        });
        return {
          ...state,
          hydrateCatalogComplete: true,
          catalog: updatedCatalog,
          failed: updatedFailed,
          error: undefined,
        };
      })
      .addCase(hydrateCatalog.rejected, (state) => {
        return {
          ...state,
          hydrateCatalogComplete: true,
          error: true,
        };
      });
  },
  selectors: {
    selectHydrateCatalogComplete: (state) => state.hydrateCatalogComplete,
    selectCatalog: (state) => state.catalog,
    selectFailed: (state) => state.failed,
    selectCatalogError: (state) => state.error,
  },
});

export const selectHydrateCatalogComplete = catalogSlice.selectors.selectHydrateCatalogComplete;
export const selectCatalog = catalogSlice.selectors.selectCatalog;
export const selectFailed = catalogSlice.selectors.selectFailed;
export const selectCatalogError = catalogSlice.selectors.selectCatalogError;

export const { replaceCatalogState } = catalogSlice.actions;

export default catalogSlice.reducer;
