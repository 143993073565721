import {
  Availability,
  ItemIdentifier,
  Order,
  OrderItem,
  ProductImages,
  ProductVariations,
} from '@amzn/mulberry-external-typescript-client';
import { useSelector } from 'react-redux';
import OrderItemComponent, { OrderItemStyles } from 'src/components/Order/OrderItem';
import { SHIPMENT_STRING } from 'src/constants/strings';
import { SiteType } from 'src/frameworks/models/SiteType';
import { selectOrder } from 'src/store/cartSlice';
import { selectCatalog } from 'src/store/catalogSlice';
import { findCatalogItemUsingPurchaseItem } from 'src/utils/utils';

type OrderItemListProps = {
  siteType: SiteType;
  itemRenderer?: (
    estimatedArrivalDate: string | undefined,
    image: ProductImages | undefined,
    title: string | undefined,
    availability: Availability | undefined,
    price: string | undefined,
    quantity: number | undefined,
    variation: ProductVariations | undefined,
    itemIdentifiers: ItemIdentifier | undefined,
  ) => React.ReactNode;
  itemStyles?: OrderItemStyles;
  styles?: {
    orderItemListContainer?: React.CSSProperties;
    headerString?: React.CSSProperties;
    orderedListContainer?: React.CSSProperties;
    orderShipmentContainer?: React.CSSProperties;
    orderedListItem?: React.CSSProperties;
  };
};

const OrderItemList = (props: OrderItemListProps) => {
  const catalog = useSelector(selectCatalog);
  const order = useSelector(selectOrder);

  // TODO: check with UX/PM if we should display these in order groups like below
  const orderItemGroups: Order[] = order?.orders || [];

  return (
    <div
      className="orderItemListContainer"
      data-testid="orderItemListContainer"
      style={props.styles?.orderItemListContainer}
    >
      {orderItemGroups.map((orderItemGroup, groupIndex) => {
        return (
          <div
            key={groupIndex}
            className="orderShipmentContainer"
            data-testid="orderShipmentContainer"
            style={props.styles?.orderShipmentContainer}
          >
            <h2
              className="headerString"
              data-testid="headerString"
              style={props.styles?.headerString}
            >
              {SHIPMENT_STRING}
              {groupIndex + 1}
            </h2>
            <ol
              className="orderedListContainer"
              data-testid="orderedListContainer"
              style={props.styles?.orderedListContainer}
            >
              {orderItemGroup?.items?.map((order: OrderItem, itemIndex: number) => {
                const catalogItem = findCatalogItemUsingPurchaseItem(catalog, order);

                return props.itemRenderer ? (
                  // Use item renderer if provided
                  <li
                    key={`${groupIndex}-${itemIndex}`}
                    className="orderedListItem"
                    data-testid="orderedListItem"
                    style={props.styles?.orderedListItem}
                  >
                    {props.itemRenderer(
                      order.estimatedArrivalDate ?? '',
                      catalogItem?.images?.[0],
                      order.title,
                      undefined,
                      catalogItem?.price ?? '',
                      order.quantity,
                      catalogItem?.variations,
                      catalogItem?.identifier,
                    )}
                  </li>
                ) : (
                  // Otherwise use default order item
                  <li
                    key={itemIndex}
                    className="orderedListItem"
                    data-testid="orderedListItem"
                    style={props.styles?.orderedListItem}
                  >
                    <OrderItemComponent
                      siteType={props.siteType}
                      estimatedArrivalDate={order.estimatedArrivalDate}
                      image={catalogItem?.images?.[0]}
                      title={order.title}
                      availability={undefined}
                      price={catalogItem?.price ?? ''}
                      quantity={order.quantity}
                      styles={props.itemStyles}
                      variations={catalogItem?.variations}
                      identifier={catalogItem?.identifier}
                    />
                  </li>
                );
              })}
            </ol>
          </div>
        );
      })}
    </div>
  );
};

export default OrderItemList;
