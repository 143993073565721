export const buttonPlumeriaStyles: Record<string, React.CSSProperties> = {
  buttonContainer: {
    color: 'var( --secondary-color)',
    cursor: 'pointer',
    minWidth: '15rem',
    textAlign: 'center',
    alignItems: '1rem',
    justifyContent: '1rem',
    minHeight: '40px',
    fontFamily: 'var( --font-family )',
    fontWeight: 'var( --font-weight )',
    backgroundColor: '#141414',
    transition: 'color 0.3s, background-color 0.3s',
    borderRadius: '5px',
    fontSize: '1.5rem',
    boxSizing: 'border-box',
    marginBottom: '5rem',
    border: 'none',
  },
};
