import { Success, SuccessStyle } from 'src/components/Success/Success';
import { PLUMERIA_PREFIX } from 'src/constants/strings';
import { SiteType } from 'src/frameworks/models/SiteType';
import { PageType } from 'src/metricsTypes/metricsTypes';
import withPageMetrics from 'src/shared/withPageMetrics';
import { NavigationProps, withRouter } from 'src/shared/withRouter';
import { PP_INCLUDE_SESSION_ID_IN_METRICS } from 'src/sites/Plumeria/Plumeria';
import { buttonPlumeriaStyles } from 'src/sites/Plumeria/styles/ButtonStyles';

const $style: SuccessStyle = {
  errorContainer: {
    background: 'var(--primary-color)',
    color: 'var(--secondary-color)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'var(--font-family)',
    padding: '3rem 0rem',
    boxSizing: 'border-box',
    maxWidth: '1350px',
    margin: 'auto',
    lineHeight: '3rem',
    textAlign: 'center',
  },
  successButton: { ...buttonPlumeriaStyles.buttonContainer, marginTop: '50px' },
  errorMessageBox: {
    messageBoxHeading: {
      fontSize: '2rem',
    },
    messageBoxContainer: {
      fontSize: '1.5rem',
    },
    messageBoxLink: {
      fontSize: '1.5rem',
      color: 'var(--tertiary-color)',
    },
  },
  errorMessageBoxButton: { ...buttonPlumeriaStyles.buttonContainer, marginTop: '50px' },
  successButtonContainer: {},
  successMessageBox: {
    messageBoxHeading: {
      fontSize: '2rem',
    },
    messageBoxContainer: {
      fontSize: '1.5rem',
    },
    messageBoxLink: {
      fontSize: '1.5rem',
      color: 'var(--tertiary-color)',
    },
  },
  successContainer: {
    color: 'var(--secondary-color)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'var(--font-family)',
    padding: '3rem 0rem',
    boxSizing: 'border-box',
    maxWidth: '1350px',
    margin: 'auto',
    lineHeight: '3rem',
    textAlign: 'center',
  },
};

const SuccessPage = ({ params, navigate }: NavigationProps) => {
  return (
    <Success
      styles={$style}
      sitePrefix={PLUMERIA_PREFIX}
      siteType={SiteType.PLUMERIA}
      navigation={{ params, navigate }}
      includeSessionIdInMetrics={PP_INCLUDE_SESSION_ID_IN_METRICS}
    />
  );
};

export default withPageMetrics(
  SiteType.PLUMERIA,
  PageType.SUCCESS_PAGE,
  withRouter(SuccessPage),
  PP_INCLUDE_SESSION_ID_IN_METRICS,
);
