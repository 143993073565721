import { SiteType } from 'src/frameworks/models/SiteType';
import { Paths } from 'src/paths/Paths';
import { PROD_ENDPOINTS } from 'src/services/roadie';
import { getStage, getValueFromSiteMap } from 'src/utils/roadieHelper';

// We fetch the client id from the service, but this is for testing
export const TEST_CLIENT_ID = 'amzn1.application-oa2-client.9cc171abbb8b4aed871a00dce2291cde';

export const currentBaseUrl = window.location.origin;

// Note: each site's redirect url will need to be allowlisted in developer.amazon.com in the corresponding security profile
export const getRedirectUrl = (siteType: SiteType): string => {
  return getStage() === 'Prod'
    ? `${getValueFromSiteMap(PROD_ENDPOINTS, siteType)}auth/fetch?redirect_uri=${currentBaseUrl}${
        Paths.closeWindow
      }`
    : `https://mulberry.${getStage().toLowerCase()}.roadie.music.amazon.dev/auth/fetch?redirect_uri=${currentBaseUrl}${
        Paths.closeWindow
      }`;
};

export const POP_UP_WIDTH_AND_HEIGHT = 700;
