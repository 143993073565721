export const tvLoadingSpinnerContainer: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
};

export const tvProductDPErrorContainer: React.CSSProperties = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  margin: '0',
  position: 'absolute',
  top: '-4rem',
  textAlign: 'center',
};

export const tvVariationLabel: React.CSSProperties = {
  fontSize: '1rem',
  fontFamily: 'var(--font-family)',
};

export const tvAlertContainer: React.CSSProperties = {
  position: 'fixed',
  bottom: '2rem',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: '5',
};

export const tvproductDPLoadingContainer: React.CSSProperties = {
  height: '100%',
  width: '100%',
};
