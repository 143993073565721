import {
  ConfirmOrderRequest,
  ConfirmOrderResponse,
  FetchClientResponse,
  GetItemMetadataRequest,
  GetItemMetadataResponse,
  ListMerchRequest,
  ListMerchResponse,
  LogoutResponse,
  PreviewOrderRequest,
  PreviewOrderResponse,
  ValidateTokenRequest,
  ValidateTokenResponse,
} from '@amzn/mulberry-external-typescript-client';
import axios, { AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';
import {
  DEFAULT_PROD_ENDPOINT,
  MAX_RETRIES,
  PLUMERIA_PROD_ENDPOINT,
  TORMENTED_VALLEY_PROD_ENDPOINT,
} from 'src/constants/roadie';
import { SiteType } from 'src/frameworks/models/SiteType';
import { getValueFromStageMap } from 'src/utils/roadieHelper';

export type STAGE = 'Beta' | 'Gamma' | 'Prod';
export type STAGE_MAP = Record<STAGE, string>;
export type SITE_MAP = Record<SiteType, string>;

export const PROD_ENDPOINTS: SITE_MAP = {
  TEST: DEFAULT_PROD_ENDPOINT,
  TORMENTEDVALLEY: TORMENTED_VALLEY_PROD_ENDPOINT,
  PLUMERIA: PLUMERIA_PROD_ENDPOINT,
};

export const getEndpoints = (siteType: SiteType): STAGE_MAP => {
  return {
    Beta: 'https://mulberry.beta.roadie.music.amazon.dev',
    Gamma: 'https://mulberry.gamma.roadie.music.amazon.dev',
    Prod: PROD_ENDPOINTS[siteType],
  };
};

export class RoadieService {
  private axiosClient: AxiosInstance;

  constructor(siteType: SiteType, csrfToken?: string) {
    const endpoint = getValueFromStageMap(getEndpoints(siteType));
    this.axiosClient = axios.create({
      baseURL: endpoint,
      timeout: 30000,
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
      },
    });
    axiosRetry(this.axiosClient, {
      retries: MAX_RETRIES,
      retryDelay: axiosRetry.exponentialDelay,
      shouldResetTimeout: true,
    });
  }

  public async listMerch(request: ListMerchRequest): Promise<ListMerchResponse> {
    const path = `/merch`;
    const response = await this.axiosClient.request({
      url: path,
      method: 'get',
      params: request,
      withCredentials: true,
    });
    return response.data as ListMerchResponse;
  }

  public async getItemMetadata(request: GetItemMetadataRequest): Promise<GetItemMetadataResponse> {
    const path = `/item/metadata`;
    const response = await this.axiosClient.request({
      url: path,
      method: 'post',
      data: request,
      withCredentials: true,
    });
    return response.data as GetItemMetadataResponse;
  }

  public async previewOrder(request: PreviewOrderRequest): Promise<PreviewOrderResponse> {
    const path = `/order/preview`;
    const response = await this.axiosClient.request({
      url: path,
      method: 'post',
      data: request,
      withCredentials: true,
    });
    return response.data as PreviewOrderResponse;
  }

  public async confirmOrder(request: ConfirmOrderRequest): Promise<ConfirmOrderResponse> {
    const path = `/order/confirm`;
    const response = await this.axiosClient.request({
      url: path,
      method: 'post',
      data: request,
      withCredentials: true,
    });
    return response.data as ConfirmOrderResponse;
  }

  public async validateToken(request: ValidateTokenRequest): Promise<ValidateTokenResponse> {
    const path = `/auth/validate`;
    const response = await this.axiosClient.request({
      url: path,
      method: 'get',
      data: request,
      withCredentials: true,
    });
    return response.data as ValidateTokenResponse;
  }

  public async fetchClientId(): Promise<FetchClientResponse> {
    const path = `/auth/client`;
    const response = await this.axiosClient.request({
      url: path,
      method: 'get',
      withCredentials: true,
    });
    return response.data as FetchClientResponse;
  }

  public async logOut(): Promise<LogoutResponse> {
    const path = `/auth/logout`;
    const response = await this.axiosClient.request({
      url: path,
      method: 'post',
      withCredentials: true,
    });
    return response.data as LogoutResponse;
  }
}
