export const orderListCss: Record<string, React.CSSProperties> = {
  orderedListContainer: {
    gap: '1rem',
    display: 'flex',
    flexDirection: 'column',
    color: 'var(--secondary-color)',
    listStyleType: 'none',
    paddingLeft: '0',
  },
  headerString: {
    color: 'var(--secondary-color)',
    fontFamily: 'var(--font-family)',
    fontWeight: 'normal',
    fontSize: '1.5rem',
    height: '1.75rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    textTransform: 'uppercase',
  },
  orderedListItem: {
    listStyleType: 'none',
  },
  orderItemListContainer: {
    width: '100%',
  },
};
