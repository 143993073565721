import 'src/sites/TormentedValleyExternalPilot/components/Footer/Footer.scss';

import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer">
      <nav className="footerNav">
        © <span>{currentYear}</span>
        <a href="https://thetormentedvalley.com/" rel="noreferrer" target="_blank">
          {' '}
          The Tormented Valley Copyright{' '}
        </a>
        All Rights Reserved. |
        <a href="https://thetormentedvalley.com/site-terms" rel="noreferrer" target="_blank">
          {' '}
          Terms and Conditions{' '}
        </a>
        |
        <a
          href="https://www.amazon.com/gp/help/customer/display.html?nodeId=468496&ref_=footer_privacy"
          rel="noreferrer"
          target="_blank"
        >
          {' '}
          Privacy Policy{' '}
        </a>
        |
        <a
          href="https://www.amazon.com/privacyprefs?ref_=footer_iba"
          rel="noreferrer"
          target="_blank"
        >
          {' '}
          How we use your data{' '}
        </a>
        |
        <a
          href="https://www.amazon.com/privacyprefs?ref_=footer_iba"
          rel="noreferrer"
          target="_blank"
        >
          {' '}
          Your California Privacy Rights{' '}
        </a>
        |
        <a
          href="https://www.amazon.com/privacyprefs?ref_=footer_iba"
          rel="noreferrer"
          target="_blank"
        >
          {' '}
          Do not sell my Personal Information{' '}
        </a>
        |
        <a
          href="https://www.amazon.com/hz/contact-us/foresight/hubgateway"
          rel="noreferrer"
          target="_blank"
        >
          {' '}
          Send Us Feedback{' '}
        </a>
      </nav>
    </footer>
  );
};

export default Footer;
