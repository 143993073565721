import { configureStore } from '@reduxjs/toolkit';
import _isEqual from 'lodash/isEqual';
import rootReducer from 'src/store/rootReducer';

export const authMiddleware = (store: any) => (next: any) => (action: any) => {
  const prevAuthState = store.getState().auth;
  const result = next(action);
  const nextAuthState = store.getState().auth;

  if (!_isEqual(prevAuthState, nextAuthState)) {
    const authStateJSON = JSON.stringify(nextAuthState);
    localStorage.setItem('authState', authStateJSON);
  }

  return result;
};

export const variationMiddleware = (store: any) => (next: any) => (action: any) => {
  const prevVariationState = store.getState().variation;
  const result = next(action);
  const nextVariationState = store.getState().variation;

  if (!_isEqual(prevVariationState, nextVariationState)) {
    const variationStateJSON = JSON.stringify(nextVariationState);
    localStorage.setItem('variationState', variationStateJSON);
  }

  return result;
};

export const cartMiddleware = (store: any) => (next: any) => (action: any) => {
  const prevCartState = store.getState().cart.cart;
  const result = next(action);
  const nextCartState = store.getState().cart.cart;

  if (!_isEqual(prevCartState, nextCartState)) {
    const cartStateJSON = JSON.stringify(nextCartState);
    localStorage.setItem('cartState', cartStateJSON);
  }

  return result;
};

export const identifierMiddleware = (store: any) => (next: any) => (action: any) => {
  const prevIdentifierState = store.getState().identifier.sessionId;
  const result = next(action);
  const nextIdentifierState = store.getState().identifier.sessionId;

  if (!_isEqual(prevIdentifierState, nextIdentifierState)) {
    const identifierStateJSON = JSON.stringify(nextIdentifierState);
    localStorage.setItem('identifierState', identifierStateJSON);
  }

  return result;
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authMiddleware,
      cartMiddleware,
      variationMiddleware,
      identifierMiddleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
