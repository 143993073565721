import React from 'react';

export enum SymbolStyle {
  ARROW = 'arrow',
  ARITHMETIC = 'arithmetic',
}

export interface QuantitySelectorStyles {
  quantitySelectorContainer?: React.CSSProperties;
  quantitySelectorButton?: React.CSSProperties;
  quantitySelectorInput?: React.CSSProperties;
}

interface QuantitySelectorProps {
  disabled?: boolean;
  symbolStyle?: SymbolStyle;
  quantity: number;
  maxQuantity: number;
  setQuantity: (quantity: number) => void;
  classNamePrefix: string;
  styles?: QuantitySelectorStyles;
}

const QuantitySelector = (props: QuantitySelectorProps) => {
  if (props.quantity === 0) {
    return null;
  }

  const getSymbol = (symbolStyle: SymbolStyle | undefined, isRight: boolean): string => {
    switch (symbolStyle) {
      case SymbolStyle.ARROW:
        return isRight ? '>' : '<';
      case SymbolStyle.ARITHMETIC:
        return isRight ? '+' : '-'; // Use + and - for arithmetic style
      default:
        return isRight ? '\u2192' : '\u2190'; // Default to arrows if no symbolStyle is specified
    }
  };

  return (
    <div
      className={`${props.classNamePrefix}-quantitySelectorContainer`}
      data-testid="quantitySelectorContainer"
      style={props.styles?.quantitySelectorContainer}
    >
      <div
        className={`${props.classNamePrefix}-quantitySelectorLeftButton`}
        data-testid="quantitySelectorLeftButton"
        style={props.styles?.quantitySelectorButton}
        onClick={() => {
          if (!props.disabled && props.quantity > 1) {
            props.setQuantity(props.quantity - 1);
          }
        }}
      >
        <div>{getSymbol(props.symbolStyle, false)}</div>
      </div>
      <input
        className={`${props.classNamePrefix}-quantitySelectorInput`}
        data-testid="quantitySelectorInput"
        style={props.styles?.quantitySelectorInput}
        value={props.quantity}
        disabled
        onChange={(value) => {
          const newQuantity = parseInt(value.target.value);
          if (newQuantity >= 1 && newQuantity <= props.maxQuantity) {
            props.setQuantity(newQuantity);
          }
        }}
      />
      <div
        className={`${props.classNamePrefix}-quantitySelectorRightButton`}
        data-testid="quantitySelectorRightButton"
        style={props.styles?.quantitySelectorButton}
        onClick={() => {
          if (!props.disabled && props.quantity < props.maxQuantity) {
            props.setQuantity(props.quantity + 1);
          }
        }}
      >
        <div>{getSymbol(props.symbolStyle, true)}</div>
      </div>
    </div>
  );
};

export default QuantitySelector;
