import { DeliveryMessage, DeliveryMessageContent } from '@amzn/mulberry-external-typescript-client';
import _ from 'lodash';

export const parseDeliveryMessage = (message: DeliveryMessage): string | null => {
  if (_.isNull(message) || _.isEmpty(message)) {
    return null;
  }

  const result = '';

  const parseComposition = (content?: DeliveryMessageContent): string | null => {
    if (_.isNull(content) || _.isEmpty(content)) {
      return null;
    }

    if (content.composition && Array.isArray(content.composition)) {
      return content.composition?.map((c) => parseDeliveryMessage(c)).join('');
    } else if (content.message) {
      return content.message;
    }

    return parseComposition(content);
  };

  return result + parseComposition(message.content);
};
