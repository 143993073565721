import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'src/store/store';

export interface IdentifierState {
  sessionId: string;
}

const initialState: IdentifierState = {
  sessionId: '',
};

interface SetIdentifierPayload {
  sessionId: string;
}

const identifierSlice = createSlice({
  name: 'identifier',
  initialState,
  reducers: {
    setIdentifierState: (state, action: PayloadAction<SetIdentifierPayload>) => {
      state.sessionId = action.payload.sessionId;
    },
  },
});

export const { setIdentifierState } = identifierSlice.actions;

export const selectSessionId = (state: RootState) => state.identifier.sessionId;

export default identifierSlice.reducer;
