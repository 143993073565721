import { ProductImages } from '@amzn/mulberry-external-typescript-client';
import React, { useState } from 'react';
import MediaCentralImage from 'src/components/Image/MediacentralImage';
import { SiteType } from 'src/frameworks/models/SiteType';
import { PageType } from 'src/metricsTypes/metricsTypes';
import { ImageSize } from 'src/utils/imageUtil';

export interface ImageBoxStyles {
  imageBoxContainer?: React.CSSProperties;
  imageBoxRow?: React.CSSProperties;
  imageBoxColumn?: React.CSSProperties;
  imageBoxSmallImage?: React.CSSProperties;
  imageBoxLargeImage?: React.CSSProperties;
  imageBoxLargeImageContainer?: React.CSSProperties;
  imageBoxSmallImageContainer?: React.CSSProperties;
}

interface ImageBoxProps {
  images: ProductImages[];
  showLargeImage?: boolean;
  styles?: ImageBoxStyles;
  siteType: SiteType;
}

const ImageBox = (props: ImageBoxProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  // Maximum number of images to show is 5
  const slicedImages = props.images.slice(0, 5);
  const selectedImage = slicedImages[selectedIndex];
  return (
    <div
      className="imageBoxContainer"
      style={props.styles?.imageBoxContainer}
      data-testid={'imageBoxContainer'}
    >
      <div style={props.styles?.imageBoxRow}>
        <div style={props.styles?.imageBoxColumn}>
          {slicedImages.map((image: ProductImages, index: number) => (
            <div
              key={image?.lowRes?.url + '-container'}
              style={props.styles?.imageBoxSmallImageContainer}
            >
              <MediaCentralImage
                siteType={props.siteType}
                pageType={PageType.PRODUCT_DETAIL_PAGE}
                size={ImageSize.SMALL}
                productImages={image}
                testId={`imageBoxSmallImage`}
                onClick={() => setSelectedIndex(index)}
                style={props.styles?.imageBoxSmallImage}
              />
            </div>
          ))}
        </div>
        {props.showLargeImage && (
          <div style={props.styles?.imageBoxLargeImageContainer}>
            <MediaCentralImage
              siteType={props.siteType}
              pageType={PageType.PRODUCT_DETAIL_PAGE}
              size={ImageSize.LARGE}
              productImages={selectedImage}
              testId={`imageBoxLargeImage-${selectedIndex}`}
              style={props.styles?.imageBoxLargeImage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageBox;
