import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCsrfToken, selectExpiryTime, updateCsrfToken } from 'src/store/authSlice';
import { AppDispatch } from 'src/store/store';
import { useExpiryTimeMs } from 'src/utils/utils';

/* This component is used in the log in flow for LWA.
We are doing the login in a pop up window, and after a successful login,
we want to close the popup and continue the flow in the original window.
After success, the page should redirect to this page, which
stores the expiry time and then closes the popup. If the user 
cancels and we get an unsuccessful response, we should also
close the pop up.
*/
const ClosePageComponent = () => {
  const dispatch = useDispatch<AppDispatch>();
  const expiryTime = useSelector(selectExpiryTime);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const successParam = urlSearchParams.get('success');
  const csrfTokenParam = urlSearchParams.get('csrf_token');
  const csrfToken = useSelector(selectCsrfToken);

  useEffect(() => {
    useExpiryTimeMs(dispatch);
    if (csrfTokenParam) {
      dispatch(updateCsrfToken(csrfTokenParam));
    }
  }, []);

  useEffect(() => {
    if ((expiryTime && expiryTime > Date.now() && csrfToken) || successParam === 'false') {
      window.close();
    }
  }, [expiryTime, successParam, csrfToken]);

  return null;
};

export default ClosePageComponent;
