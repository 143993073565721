import * as KatalMetrics from '@amzn/katal-metrics';
import React from 'react';
import { connect } from 'react-redux';
import { initialMetricsPublisher } from 'src/metrics';
import { selectSessionId } from 'src/store/identifierSlice';
import { RootState } from 'src/store/store';

enum InitializationStatus {
  Failure = 0,
  Success = 1,
}

interface PropsType {
  children: JSX.Element;
}

type StateProps = {
  sessionId: string;
};

/**
 * Simple class to manage the application initialization metric.
 *
 * Note this is meant as a demonstration of how to use the initialization metric, and
 * is almost certainly inadequate for a more complex application.
 *
 * It waits for itself to be mounted (if it is the outermost component that means all of
 * its children are rendered and mounted by React, though are not necessarily fully rendered
 * in the browser), then waits for the translations to be ready.
 *
 * In your actual application, you are likely not fully initialized until you have completed
 * your initial REST calls.  You will need to either integrate that logic here, or else move
 * the initialization metric elsewhere.
 *
 * For applications using a state manager (e.g. Redux) it may make more sense to determine if
 * your application is initialized from that layer instead.
 */

type Props = StateProps & PropsType;

export class AppInitWrapper extends React.Component<Props> {
  private initializationMetric = new KatalMetrics.Metric.Initialization().withMonitor();
  private metricsPublisher = initialMetricsPublisher(this.props.sessionId);

  private initializationMetricsPublisher =
    this.metricsPublisher.newChildActionPublisherForInitialization();
  private errorBoundaryMetricsPublisher =
    this.metricsPublisher.newChildActionPublisherForMethod('ErrorBoundary');
  private isInitialized = false;

  componentDidMount() {
    this.logInitializationMetric(InitializationStatus.Success);
  }

  componentDidCatch(error: Error) {
    this.errorBoundaryMetricsPublisher.publishCounterMonitor('ErrorBoundary.Encounter', 1);
    // In your application you may want to update the UI; here we will re-throw and fail the whole app, after logging
    throw error;
  }

  render() {
    return this.props.children;
  }

  // Helper method to publish the initialization metric
  private logInitializationMetric(status: InitializationStatus) {
    if (!this.isInitialized && this.initializationMetric && this.initializationMetricsPublisher) {
      this.isInitialized = true;
      this.initializationMetric.setFailure(status !== InitializationStatus.Success);
      this.initializationMetricsPublisher.publish(this.initializationMetric);
    }
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  sessionId: selectSessionId(state),
});

export default connect(mapStateToProps)(AppInitWrapper);
