// we get the relevant scopes from https://console.harmony.a2z.com/lwa-tools-portal/scopes based on the permissions we need
export const BuildSignInUrl = (clientId: string, redirectUrl: string): string => {
  return (
    `https://www.amazon.com/ap/oa?client_id=${clientId}` +
    `&scope=${encodeURIComponent(
      'amazonanywhere::orders profile profile:user_id postal_code',
    )}&scope_data=${encodeURIComponent(
      '{"amazonanywhere::orders":{"essential":true},"profile":{"essential":true},"profile:user_id":{"essential":true},"postal_code":{"essential":true}}',
    )}` +
    `&response_type=code&redirect_uri=` +
    encodeURIComponent(redirectUrl)
  );
};
