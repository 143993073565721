import 'src/sites/TormentedValleyExternalPilot/components/NavBar/NavBar.scss';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Badge, Box, Drawer, IconButton, List, ListItem, ListItemButton } from '@mui/material';
import Alert from '@mui/material/Alert';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SiteProps } from 'src/components/App/App';
import {
  ABOUT_STRING,
  CLOSE_BUTTON_STRING,
  HOME_STRING,
  LIVE_EVENT_STRING,
  MENU_BUTTON_STRING,
  MUSIC_STRING,
  SIGN_OUT_BUTTON_STRING,
  SIGN_OUT_ERROR,
  SIGN_OUT_SUCCESS,
  STORE_STRING,
  TORMENTED_VALLEY_PREFIX,
  TORMENTED_VALLEY_STRING,
} from 'src/constants/strings';
import { SiteType } from 'src/frameworks/models/SiteType';
import { recordClick } from 'src/metrics';
import { ActionType } from 'src/metricsTypes/metricsTypes';
import {
  logOut,
  selectCsrfToken,
  selectExpiryTime,
  selectLogOutError,
  selectLogOutSuccess,
  selectValidateTokenComplete,
  updateExpiryTime,
} from 'src/store/authSlice';
import { selectCart } from 'src/store/cartSlice';
import { selectCatalog } from 'src/store/catalogSlice';
import { selectSessionId } from 'src/store/identifierSlice';
import { AppDispatch } from 'src/store/store';
import { getValidCart, hydrateCatalogFromCart, MuiAlertType } from 'src/utils/utils';

interface NavBarProps extends SiteProps {
  isNavMenuOpen: boolean;
  setIsNavMenuOpen: (open: boolean) => void;
}

const NavBar = (props: NavBarProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const cart = useSelector(selectCart);
  const catalog = useSelector(selectCatalog);
  const expiryTime = useSelector(selectExpiryTime);
  const accessTokenExists = useSelector(selectValidateTokenComplete);
  const [muiAlertType, setMuiAlertType] = useState<MuiAlertType>(MuiAlertType.SUCCESS);
  const [muiAlert, setMuiAlert] = useState<string>('');
  const [showAlert, setShowAlert] = useState(false);
  const [validQuantity, setValidQuantity] = useState(0);
  const csrfToken = useSelector(selectCsrfToken);
  const logOutError = useSelector(selectLogOutError);
  const logOutSuccess = useSelector(selectLogOutSuccess);
  const sessionId = useSelector(selectSessionId);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    }
  }, [showAlert]);

  // Use quantity of ONLY valid cart items i.e. cart items that can be hydrated by the catalog
  useEffect(() => {
    const cartItems = cart?.items ?? [];
    const validCart = getValidCart(cartItems, catalog);
    setValidQuantity(validCart.quantity);
  }, [cart, catalog]);

  // hydrate the catalog once from the cart, as when the user refreshes, the catalog will only be hydrated from whichever page they are on
  // meaning some items in cart may not be hydrated therefore would not be shown in the cart quantity on the icon
  useEffect(() => {
    if (cart) hydrateCatalogFromCart(cart, dispatch, SiteType.TORMENTEDVALLEY, csrfToken);
  }, []);

  const goHome = (): void => {
    recordClick(
      SiteType.TORMENTEDVALLEY,
      ActionType.GO_HOME,
      props.includeSessionIdInMetrics ? sessionId : undefined,
    );
    props.setIsNavMenuOpen(false);
  };

  const goLiveEvents = (): void => {
    recordClick(
      SiteType.TORMENTEDVALLEY,
      ActionType.GO_LIVE_EVENTS,
      props.includeSessionIdInMetrics ? sessionId : undefined,
    );
    props.setIsNavMenuOpen(false);
  };

  const goMusic = (): void => {
    recordClick(
      SiteType.TORMENTEDVALLEY,
      ActionType.GO_MUSIC,
      props.includeSessionIdInMetrics ? sessionId : undefined,
    );
    props.setIsNavMenuOpen(false);
  };

  const goStore = (): void => {
    recordClick(
      SiteType.TORMENTEDVALLEY,
      ActionType.GO_STORE,
      props.includeSessionIdInMetrics ? sessionId : undefined,
    );
    navigate(`/store`);
    props.setIsNavMenuOpen(false);
  };

  const goAbout = (): void => {
    recordClick(
      SiteType.TORMENTEDVALLEY,
      ActionType.GO_ABOUT,
      props.includeSessionIdInMetrics ? sessionId : undefined,
    );
    props.setIsNavMenuOpen(false);
  };

  const goCart = (): void => {
    recordClick(
      SiteType.TORMENTEDVALLEY,
      ActionType.GO_CART,
      props.includeSessionIdInMetrics ? sessionId : undefined,
    );
    navigate(`/cart`);
    props.setIsNavMenuOpen(false);
  };

  const goSignOut = (): void => {
    recordClick(
      SiteType.TORMENTEDVALLEY,
      ActionType.GO_SIGN_OUT,
      props.includeSessionIdInMetrics ? sessionId : undefined,
    );
    dispatch(updateExpiryTime(null));
    props.setIsNavMenuOpen(false);
    dispatch(
      logOut({
        site: SiteType.TORMENTEDVALLEY,
      }),
    );
  };

  useEffect(() => {
    if (logOutSuccess) {
      setMuiAlertType(MuiAlertType.SUCCESS);
      setMuiAlert(SIGN_OUT_SUCCESS);
      setShowAlert(true);
      navigate(`/store`);
      props.setIsNavMenuOpen(false);
    } else if (logOutError) {
      setMuiAlertType(MuiAlertType.ERROR);
      setMuiAlert(SIGN_OUT_ERROR);
      setShowAlert(true);
    }
  }, [logOutSuccess, logOutError]);

  const isSignedIn = (): boolean => {
    const currentTimestamp = Date.now();
    const isExpired = expiryTime ? currentTimestamp > expiryTime : true;
    return !isExpired && accessTokenExists !== undefined && accessTokenExists;
  };

  const onClickMenu = () => {
    recordClick(
      SiteType.TORMENTEDVALLEY,
      ActionType.SHOW_NAV_MENU,
      props.includeSessionIdInMetrics ? sessionId : undefined,
    );
    props.setIsNavMenuOpen(true);
  };

  const onClickCloseButton = () => {
    recordClick(
      SiteType.TORMENTEDVALLEY,
      ActionType.CLOSE_NAV_MENU,
      props.includeSessionIdInMetrics ? sessionId : undefined,
    );
    props.setIsNavMenuOpen(false);
  };

  const navBarLogo = (
    <a
      className={`${TORMENTED_VALLEY_PREFIX}-navBarLogo`}
      data-testid="navBarLogo"
      onClick={goHome}
    >
      {TORMENTED_VALLEY_STRING}
    </a>
  );

  const navMenuListItems = (
    <>
      <ListItem
        className={`${TORMENTED_VALLEY_PREFIX}-navMenuListItem`}
        data-testid="navMenuListItem"
      >
        <ListItemButton
          onClick={goHome}
          href={'https://thetormentedvalley.com/home'}
          target="_self"
        >
          {HOME_STRING}
        </ListItemButton>
      </ListItem>
      <ListItem
        className={`${TORMENTED_VALLEY_PREFIX}-navMenuListItem`}
        data-testid="navMenuListItem"
      >
        <ListItemButton
          onClick={goLiveEvents}
          href={'https://thetormentedvalley.com/home#live-events'}
          target="_self"
        >
          {LIVE_EVENT_STRING}
        </ListItemButton>
      </ListItem>
      <ListItem
        className={`${TORMENTED_VALLEY_PREFIX}-navMenuListItem`}
        data-testid="navMenuListItem"
      >
        <ListItemButton
          onClick={goMusic}
          href={'https://thetormentedvalley.com/home#music'}
          target="_self"
        >
          {MUSIC_STRING}
        </ListItemButton>
      </ListItem>
      <ListItem
        className={`${TORMENTED_VALLEY_PREFIX}-navMenuListItem`}
        data-testid="navMenuListItem"
      >
        <ListItemButton onClick={goStore}>{STORE_STRING}</ListItemButton>
      </ListItem>
      <ListItem
        className={`${TORMENTED_VALLEY_PREFIX}-navMenuListItem`}
        data-testid="navMenuListItem"
      >
        <ListItemButton
          onClick={goAbout}
          href={'https://thetormentedvalley.com/home#about'}
          target="_self"
        >
          {ABOUT_STRING}
        </ListItemButton>
      </ListItem>
    </>
  );

  const navMenuDrawer = (
    <Drawer
      className={`${TORMENTED_VALLEY_PREFIX}-navMenuDrawer`}
      anchor="left"
      open={props.isNavMenuOpen}
      onClose={props.isNavMenuOpen ? onClickCloseButton : onClickMenu}
      data-testid="navMenuPopOverContainer"
    >
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={onClickCloseButton}
        className={`${TORMENTED_VALLEY_PREFIX}-navMenuCloseButtonContainer`}
        data-testid="navMenuCloseButtonContainer"
      >
        <button
          className={`${TORMENTED_VALLEY_PREFIX}-navMenuCloseButton`}
          data-testid="navMenuCloseButton"
          onClick={onClickCloseButton}
        >
          <title>{CLOSE_BUTTON_STRING}</title>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 10.18 10.19"
            className="navMenuClose"
            data-testid="navMenuClose"
          >
            <path
              className="navMenuClosePath"
              data-testid="navMenuClosePath"
              fill="white"
              d="m6.29,5.09l3.64-3.64c.33-.33.33-.87,0-1.2-.33-.33-.87-.33-1.2,0l-3.64,3.64L1.45.25C1.12-.08.58-.08.25.25-.08.58-.08,1.12.25,1.45l3.64,3.64L.25,8.73c-.33.33-.33.87,0,1.2.17.17.38.25.6.25s.44-.08.6-.25l3.64-3.64,3.64,3.64c.17.17.38.25.6.25s.43-.08.6-.25c.33-.33.33-.87,0-1.2l-3.64-3.64Z"
            ></path>
          </svg>
        </button>
      </Box>
      <List className={`${TORMENTED_VALLEY_PREFIX}-navMenuList`} data-testid="navMenuList">
        {navMenuListItems}
      </List>
    </Drawer>
  );

  const navMenu = (
    <ol className={`${TORMENTED_VALLEY_PREFIX}-navMenuListHorizontal`} data-testid="navMenuList">
      {navMenuListItems}
    </ol>
  );

  const iconMenuButton = (
    <button
      className={`${TORMENTED_VALLEY_PREFIX}-iconMenuButton`}
      data-testid="iconMenuButton"
      onClick={onClickMenu}
    >
      <svg
        className={`${TORMENTED_VALLEY_PREFIX}-iconMenu`}
        xmlns="http://www.w3.org/2000/svg"
        data-testid="iconMenu"
        viewBox="-2.5 -7 24 24"
      >
        <title>{MENU_BUTTON_STRING}</title>
        <path
          data-testid="iconMenuPath"
          fill="white"
          d="M19,12H1c-.55,0-1-.45-1-1s.45-1,1-1h18c.55,0,1,.45,1,1s-.45,1-1,1Zm0-5H1c-.55,0-1-.45-1-1s.45-1,1-1h18c.55,0,1,.45,1,1s-.45,1-1,1Zm0-5H1c-.55,0-1-.45-1-1s.45-1,1-1h18c.55,0,1,.45,1,1s-.45,1-1,1Z"
        ></path>
      </svg>
    </button>
  );

  const iconCartButton = (
    <IconButton aria-label="cart" onClick={goCart} data-testid="iconCartButton">
      <Badge
        badgeContent={validQuantity}
        invisible={validQuantity == 0}
        color="primary"
        data-testid="iconCartQuantityBadge"
      >
        <ShoppingCartIcon fontSize="large" color="secondary" />
      </Badge>
    </IconButton>
  );

  const iconSignOutButton = (
    <button
      className={`${TORMENTED_VALLEY_PREFIX}-iconActionButton`}
      data-testid="iconSignOutButton"
      onClick={goSignOut}
    >
      <svg
        className={`${TORMENTED_VALLEY_PREFIX}-iconAction`}
        xmlns="http://www.w3.org/2000/svg"
        data-testid="iconCart"
        viewBox="0 0 24 24"
      >
        <title>{SIGN_OUT_BUTTON_STRING}</title>
        <path
          className={`${TORMENTED_VALLEY_PREFIX}-iconActionPath`}
          data-testid="iconSignOutPath"
          fill="white"
          d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z"
        ></path>
      </svg>
    </button>
  );

  return (
    <>
      <header className={`${TORMENTED_VALLEY_PREFIX}-navBar`} data-testid="tormentedValleyNavBar">
        {navMenuDrawer}
        <div className={`${TORMENTED_VALLEY_PREFIX}-navBarRow`}>
          {iconMenuButton}
          {navBarLogo}
          {iconCartButton}
          {isSignedIn() && iconSignOutButton}
        </div>
        {navMenu}
      </header>
      {showAlert && (
        <div className={`${TORMENTED_VALLEY_PREFIX}-alertContainer`} data-testid="alertContainer">
          <Alert variant="filled" severity={muiAlertType}>
            {muiAlert}
          </Alert>
        </div>
      )}
    </>
  );
};

export default NavBar;
