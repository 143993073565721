import { OrderSummaryOptions } from 'src/components/OrderSummary/OrderSummary';
import { PLUMERIA_PREFIX } from 'src/constants/strings';
import { SiteType } from 'src/frameworks/models/SiteType';
import { PageType } from 'src/metricsTypes/metricsTypes';
import { OrderPage, OrderPageStyles } from 'src/pages/OrderPage';
import withPageMetrics from 'src/shared/withPageMetrics';
import { withRouter } from 'src/shared/withRouter';
import { orderItemCss } from 'src/sites/Plumeria/components/Order/OrderItemCss';
import { orderListCss } from 'src/sites/Plumeria/components/Order/OrderListCss';
import { orderSummaryCSS } from 'src/sites/Plumeria/components/Order/OrderSummaryCss';
import { PP_INCLUDE_SESSION_ID_IN_METRICS } from 'src/sites/Plumeria/Plumeria';
import { buttonPlumeriaStyles } from 'src/sites/Plumeria/styles/ButtonStyles';
import { errorPlumeriaStyle } from 'src/sites/Plumeria/styles/ErrorStyles';

const orderSummaryOptions: OrderSummaryOptions = {
  showSummaryHeader: false,
  showDivider: true,
  paymentMethodVariant: 'align-right',
  shippingAddressVariant: 'align-right',
};

const $style: OrderPageStyles = {
  orderItemStyle: orderItemCss,
  orderItemListStyle: orderListCss,
  orderSummaryStyle: orderSummaryCSS,
  errorMessageBoxStyle: errorPlumeriaStyle,
  errorButtonStyle: buttonPlumeriaStyles,
  paymentAndAddressNote: { alignSelf: 'flex-end', maxWidth: 300 },
  privacyNoticeAndConditionOfUseNote: { alignSelf: 'flex-end' },
  checkoutButtonStyles: {
    confirmButtonStyle: {
      alignSelf: 'flex-end',
      backgroundColor: '#141414',
      borderRadius: 0,
      cursor: 'pointer',
    },
    confirmButtonTextStyle: {
      color: 'var(--secondary-color)',
    },
    lockIconStyle: {
      color: 'var(--secondary-color)',
    },
  },
  orderHeaderText: {
    color: 'var(--secondary-color)',
  },
};

const PlumeriaOrderPage = () => {
  return (
    <OrderPage
      siteType={SiteType.PLUMERIA}
      sitePrefix={PLUMERIA_PREFIX}
      orderSummaryOptions={orderSummaryOptions}
      style={$style}
      includeSessionIdInMetrics={PP_INCLUDE_SESSION_ID_IN_METRICS}
    />
  );
};
export default withPageMetrics(
  SiteType.PLUMERIA,
  PageType.ORDER_PAGE,
  withRouter(PlumeriaOrderPage),
  PP_INCLUDE_SESSION_ID_IN_METRICS,
);
