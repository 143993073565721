import {
  AvailabilityType,
  ItemIdentifier,
  ItemMetadata,
  ProductVariations,
  PurchaseItem,
  Variation,
} from '@amzn/mulberry-external-typescript-client';
import Alert from '@mui/material/Alert';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SiteProps } from 'src/components/App/App';
import Button, { ButtonStyles } from 'src/components/Button/Button';
import ButtonGroup, { ButtonGroupStyles } from 'src/components/ButtonGroup/ButtonGroup';
import ImageBox, { ImageBoxStyles } from 'src/components/ImageBox/ImageBox';
import MessageBox, { MessageBoxStyles } from 'src/components/MessageBox/MessageBox';
import PrimaryInfoBox, { PrimaryInfoBoxStyles } from 'src/components/PrimaryInfoBox/PrimaryInfoBox';
import QuantitySelector, {
  QuantitySelectorStyles,
  SymbolStyle,
} from 'src/components/QuantitySelector/QuantitySelector';
import { MAX_ASINS_IN_CART } from 'src/constants/roadie';
import {
  ADD_TO_CART_MAX,
  ADD_TO_CART_STRING,
  ADD_TO_CART_SUCCESS,
  GENERIC_ERROR_STRING,
  LOADING_TEXT,
  LOCALE_STRING,
  MAX_ITEMS_REACHED_TEXT,
  PREORDER_STRING,
  UH_OH,
  UNAVAILABLE_STRING,
  VIEW_CART_BUTTON_STRING,
} from 'src/constants/strings';
import { getSite } from 'src/frameworks/GetSite';
import { recordClick, recordPageViewed } from 'src/metrics';
import { ActionType, ElementType, PageType } from 'src/metricsTypes/metricsTypes';
import NotFoundPage from 'src/sites/TormentedValleyExternalPilot/pages/NotFoundPage';
import { selectCsrfToken, selectValidateTokenComplete } from 'src/store/authSlice';
import { addToCart, selectCart } from 'src/store/cartSlice';
import {
  hydrateCatalog,
  selectCatalog,
  selectCatalogError,
  selectFailed,
  selectHydrateCatalogComplete,
} from 'src/store/catalogSlice';
import { selectSessionId } from 'src/store/identifierSlice';
import { AppDispatch } from 'src/store/store';
import { parseDeliveryMessage } from 'src/utils/deliveryMessageParser';
import { sanitizeAsinUtil } from 'src/utils/sanitizeAsinUtil';
import {
  convertPriceStringToNumber,
  getValidCart,
  hydrateCatalogFromCart,
  identifierExistsInCart,
  isCatalogItemUnavailable,
  isSamePurchaseItem,
  MuiAlertType,
  ValidCartItem,
} from 'src/utils/utils';
import { findDimensionValueUnavailabilities, findVariation } from 'src/utils/variation';

export interface ProductDetailPageStyles {
  productDPLoadingContainer?: React.CSSProperties;
  loadingSpinnerContainer?: React.CSSProperties;
  productDPErrorContainer?: React.CSSProperties;
  messageBoxErrorStyles?: MessageBoxStyles;
  productDetailPageContainer?: React.CSSProperties;
  imageBoxStyles?: ImageBoxStyles;
  productDetailPageInfo?: React.CSSProperties;
  primaryInfoBoxStyles?: PrimaryInfoBoxStyles;
  catalogVariations?: React.CSSProperties;
  variationDimensionSection?: React.CSSProperties;
  variationLabel?: React.CSSProperties;
  buttonGroupStyles?: ButtonGroupStyles;
  quantitySelectorStyles?: QuantitySelectorStyles;
  buttonStyles?: ButtonStyles;
  alertContainer?: React.CSSProperties;
  descriptionBulletsStyle?: Record<string, React.CSSProperties>;
}

export interface ProductDetailPageOptions {
  /**
   * Show the large image in image box
   * defaults to true
   */
  showLargeImage?: boolean;
}

export interface ProductDetailPageProps extends SiteProps {
  style?: ProductDetailPageStyles;
  options?: ProductDetailPageOptions;
}

const ProductDetailPage = (props: ProductDetailPageProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { asin } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const customId = queryParams.get('customId');
  const customizationToken = queryParams.get('customizationToken');

  // sanitize param for security purposes
  const sanitizedAsin = sanitizeAsinUtil(asin ?? '');
  const accessTokenExists = useSelector(selectValidateTokenComplete);
  const catalog = useSelector(selectCatalog);
  const failedCatalog = useSelector(selectFailed);
  const cart = useSelector(selectCart);
  const hydrateCatalogComplete = useSelector(selectHydrateCatalogComplete);
  const error = useSelector(selectCatalogError);
  const [buttonText, setButtonText] = useState<string>('');
  const [currentDimensionValueIndexes, setCurrentDimensionValueIndexes] = useState<number[]>([]);
  const [dimensionValueUnavailabilities, setDimensionValueUnavailabilities] = useState<boolean[][]>(
    [],
  );
  const [quantity, setQuantity] = useState<number>(1);
  const [subtotal, setSubtotal] = useState<number>(0);
  const [muiAlertType, setMuiAlertType] = useState<MuiAlertType>(MuiAlertType.SUCCESS);
  const [muiAlert, setMuiAlert] = useState<string>('');
  const [alertAction, setAlertAction] = useState<React.ReactElement>();
  const [showAlert, setShowAlert] = useState(false);
  const [identifier, setIdentifier] = useState<ItemIdentifier>({
    itemAsin: sanitizedAsin,
    customId: customId ?? undefined,
    customizationToken: customizationToken ?? undefined,
  } as ItemIdentifier);
  const [invalidCatalogItem, setInvalidCatalogItem] = useState<boolean>(false);
  const [catalogItem, setCatalogItem] = useState<ItemMetadata | undefined>();
  const [purchaseItem, setPurchaseItem] = useState<PurchaseItem>({
    quantity: 0,
    asin: '',
    customId: '',
    customizationToken: '',
    offerId: '',
    merchantId: '',
  } as PurchaseItem);
  const [validHydratedCartItems, setValidHydratedCartItems] = useState<ValidCartItem[]>([]);
  const [canAddToCart, setCanAddToCart] = useState(true);
  const csrfToken = useSelector(selectCsrfToken);
  const minOrderQuantity = catalogItem?.availability?.minOrderQuantity ?? 1;
  const maxOrderQuantity = catalogItem?.availability?.maxOrderQuantity ?? Number.MAX_SAFE_INTEGER;
  const sessionId = useSelector(selectSessionId);
  if (minOrderQuantity > quantity) {
    setQuantity(minOrderQuantity);
  }

  // Record Page View Metric
  useEffect(() => {
    if (sanitizedAsin) {
      recordPageViewed(
        props.siteType,
        PageType.PRODUCT_DETAIL_PAGE,
        props.includeSessionIdInMetrics ? sessionId : undefined,
        sanitizedAsin,
      );
    }
  }, [sanitizedAsin]);

  // Update Title
  useEffect(() => {
    if (catalogItem) {
      document.title = `${catalogItem?.name} - ${getSite()?.name}`;
    }
  }, [catalogItem]);

  // don't use cart as a dependency as we don't want the page to rerender every time a user adds to cart
  // we just want to check on page load how many valid hydrated cart items there are so we can check it against the max limit
  useEffect(() => {
    if (cart) {
      hydrateCatalogFromCart(cart, dispatch, props.siteType, csrfToken);
    }
  }, []);

  // customer cannot add more than 10 unique items to cart - they can continue to add to the quantity of existing cart items however
  useEffect(() => {
    if (identifier && validHydratedCartItems) {
      const maxItemsReached: boolean = validHydratedCartItems.length >= MAX_ASINS_IN_CART;
      const itemAlreadyInCart = identifierExistsInCart(validHydratedCartItems, identifier);
      setCanAddToCart(!maxItemsReached || (maxItemsReached && itemAlreadyInCart));
    }
  }, [validHydratedCartItems, identifier]);

  useEffect(() => {
    if (failedCatalog && asin) {
      if (failedCatalog.some((item) => item.itemAsin === asin)) {
        setInvalidCatalogItem(true);
      }
    }
  }, [failedCatalog]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (!isEmpty(identifier)) {
      dispatch(
        hydrateCatalog({
          itemIdentifiers: [identifier],
          site: props.siteType,
          locale: LOCALE_STRING,
          csrfToken: csrfToken,
        }),
      );
    }
  }, [dispatch, identifier, accessTokenExists, csrfToken]);

  useEffect(() => {
    if (!isEmpty(catalog)) {
      const merchItem = catalog.find(
        (item) =>
          item.identifier?.itemAsin === identifier?.itemAsin &&
          (identifier?.customId === undefined ||
            item.identifier?.customId === identifier.customId) &&
          (identifier?.customizationToken === undefined ||
            item.identifier?.customizationToken === identifier.customizationToken),
      );

      // undefined check prevents page flickering as merchItem can be undefined while waiting for catalog updates
      if (merchItem !== undefined) setCatalogItem(merchItem);

      const variations = merchItem?.variations;
      const selectedVariation = variations?.variations?.find(
        (variation) =>
          variation.asin == identifier?.itemAsin &&
          (identifier?.customId === undefined || variation.customId === identifier.customId) &&
          (identifier?.customizationToken === undefined ||
            variation.customizationToken === identifier.customizationToken),
      );
      // if there is a selected variation, select that one, otherwise select the first one
      // It is not necessary to update item identifier if selectedVariation is not undefined
      if (selectedVariation) {
        updateVariation(variations, selectedVariation, false);
      } else {
        updateVariation(variations, variations?.variations?.[0], true);
      }
    }
  }, [catalog]);

  useEffect(() => {
    if (!canAddToCart) {
      setButtonText(MAX_ITEMS_REACHED_TEXT);
    } else if (catalogItem?.availability?.type === AvailabilityType.PREORDER) {
      setButtonText(PREORDER_STRING);
    } else if (isCatalogItemUnavailable(catalogItem?.availability)) {
      setButtonText(UNAVAILABLE_STRING);
    } else {
      setButtonText(ADD_TO_CART_STRING);
    }
  }, [catalogItem, canAddToCart]);

  useEffect(() => {
    if (!isEmpty(catalogItem)) {
      setPurchaseItem({
        quantity: quantity,
        asin: catalogItem?.identifier?.itemAsin,
        customId: catalogItem?.identifier?.customId,
        customizationToken: catalogItem?.identifier?.customizationToken,
        offerId: catalogItem?.offerId,
        merchantId: catalogItem?.merchantId,
      } as PurchaseItem);
      setSubtotal(quantity * convertPriceStringToNumber(catalogItem?.price ?? ''));
    }
  }, [catalogItem, quantity]);

  // get valid hydrated cart items to check against max unique items
  useEffect(() => {
    if (catalog && cart) {
      const cartItems = cart?.items ?? [];
      const validCart = getValidCart(cartItems, catalog);
      const validItems = validCart.cartItems;
      setValidHydratedCartItems(validItems);
    }
  }, [cart, catalog]);

  const onAddToCart = () => {
    recordClick(
      props.siteType,
      ActionType.ADD_TO_CART,
      props.includeSessionIdInMetrics ? sessionId : undefined,
      PageType.PRODUCT_DETAIL_PAGE,
      ElementType.ADD_TO_CART_BUTTON,
      identifier.itemAsin,
    );
    const cartItem = cart?.items.find((item) => {
      return isSamePurchaseItem(item, purchaseItem);
    });
    const existingQuantity = cartItem?.quantity ?? 0;

    if (existingQuantity + quantity > maxOrderQuantity) {
      setMuiAlertType(MuiAlertType.ERROR);
      setMuiAlert(ADD_TO_CART_MAX);
      setShowAlert(true);
      setAlertAction(undefined);
    } else {
      dispatch(
        addToCart({
          purchaseItem: purchaseItem,
          quantity: quantity,
          subtotal: subtotal,
        }),
      );
      setMuiAlertType(MuiAlertType.SUCCESS);
      setMuiAlert(ADD_TO_CART_SUCCESS);
      const action = <a href="/cart">{VIEW_CART_BUTTON_STRING}</a>;
      setAlertAction(action);
      setShowAlert(true);
    }
  };

  const updateVariation = (
    productVariations: ProductVariations | undefined,
    variation: Variation | undefined,
    newIdentifier: boolean,
  ) => {
    if (!variation || !variation.dimensionLocation) {
      return;
    }
    setCurrentDimensionValueIndexes(variation.dimensionLocation);
    setDimensionValueUnavailabilities(
      findDimensionValueUnavailabilities(
        productVariations?.variations ?? [],
        productVariations?.dimensions ?? [],
        variation.dimensionLocation ?? [],
      ),
    );
    // Update browser url with variations
    const currentUrl = window.location.href;
    const regex = /\/product\/([A-Z0-9]+)/i;
    const newUrl = currentUrl.replace(regex, `/product/${variation.asin}`);
    const url = new URL(newUrl);
    if (!isNil(variation.customId)) {
      url.searchParams.set('customId', variation.customId);
    }
    if (!isNil(variation.customizationToken)) {
      url.searchParams.set('customizationToken', variation.customizationToken);
    }
    history.replaceState(null, '', url.toString());
    // Update identifier if needed
    if (newIdentifier) {
      setIdentifier({
        itemAsin: variation.asin,
        customId: variation.customId,
        customizationToken: variation.customizationToken,
      });
    }
  };

  return (
    <>
      {invalidCatalogItem && <NotFoundPage />}
      {error && (
        <div
          className={`${props.sitePrefix}-productDPErrorContainer`}
          data-testid="productDPErrorContainer"
          style={props.style?.productDPErrorContainer}
        >
          <MessageBox
            heading={UH_OH}
            text={GENERIC_ERROR_STRING}
            styles={props.style?.messageBoxErrorStyles}
            testId="productDPMessageBox"
          />
        </div>
      )}
      {!error && !invalidCatalogItem && (
        <div
          className={`${props.sitePrefix}-productDetailPageContainer`}
          data-testid="productDetailPageContainer"
          style={props.style?.productDetailPageContainer}
        >
          {catalogItem?.images && (
            <ImageBox
              showLargeImage={props.options?.showLargeImage ?? true}
              images={catalogItem.images}
              styles={props.style?.imageBoxStyles}
              siteType={props.siteType}
            />
          )}
          {catalogItem?.name && (
            <div
              className={`${props.sitePrefix}-productDetailPageInfo`}
              data-testid="productDetailPageInfo"
              style={props.style?.productDetailPageInfo}
            >
              <PrimaryInfoBox
                title={catalogItem.name}
                price={catalogItem.price}
                availability={catalogItem.availability}
                primeBadgeData={catalogItem.primeBadgeData}
                returns={
                  !isCatalogItemUnavailable(catalogItem.availability)
                    ? catalogItem.returnsPolicy
                    : undefined
                }
                styles={props.style?.primaryInfoBoxStyles}
                details={
                  catalogItem.descriptionBullets && {
                    heading: '',
                    details: catalogItem.descriptionBullets,
                    styles: props.style?.descriptionBulletsStyle,
                  }
                }
                deliveryMessage={
                  catalogItem.deliveryMessage
                    ? parseDeliveryMessage(catalogItem.deliveryMessage)
                    : null
                }
                importDetails={catalogItem.importDetails}
              />
              {!isEmpty(catalogItem.variations?.variations) &&
                !isEmpty(currentDimensionValueIndexes) &&
                !isEmpty(dimensionValueUnavailabilities) && (
                  <div
                    className={`${props.sitePrefix}-catalogVariations`}
                    data-testid="catalogVariations"
                    style={props.style?.catalogVariations}
                  >
                    {catalogItem.variations?.dimensions?.map((variationDimension, index) => {
                      return (
                        <div
                          className={`${props.sitePrefix}-variationDimensionSection`}
                          key={`variationDimensionSection-${index}`}
                          data-testid={`variationDimensionSection-${index}`}
                          style={props.style?.variationDimensionSection}
                        >
                          <div
                            className={`${props.sitePrefix}-variationLabel`}
                            data-testid="variationLabel"
                            style={props.style?.variationLabel}
                          >
                            {variationDimension.name}:
                          </div>
                          <div>
                            <ButtonGroup
                              key={`${catalogItem.variations?.variations?.[index]}-${currentDimensionValueIndexes[index]}`}
                              buttons={
                                variationDimension?.values?.map((value, index2) => ({
                                  id: index2,
                                  label: value,
                                  grayedOut: dimensionValueUnavailabilities?.[index]?.[index2],
                                })) || []
                              }
                              selectedIndex={currentDimensionValueIndexes[index]}
                              onButtonPress={(index2) => {
                                updateVariation(
                                  catalogItem.variations,
                                  findVariation(
                                    catalogItem?.variations?.variations ?? [],
                                    index,
                                    index2,
                                    currentDimensionValueIndexes,
                                  ),
                                  true,
                                );
                              }}
                              styles={props.style?.buttonGroupStyles}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

              {!isCatalogItemUnavailable(catalogItem.availability) && (
                <QuantitySelector
                  symbolStyle={SymbolStyle.ARITHMETIC}
                  quantity={quantity}
                  maxQuantity={maxOrderQuantity}
                  setQuantity={setQuantity}
                  styles={props.style?.quantitySelectorStyles}
                  classNamePrefix={props.sitePrefix}
                />
              )}
              <Button
                text={hydrateCatalogComplete ? buttonText : LOADING_TEXT}
                onClick={onAddToCart}
                styles={props.style?.buttonStyles}
                disabled={
                  isCatalogItemUnavailable(catalogItem.availability) ||
                  !canAddToCart ||
                  !hydrateCatalogComplete
                }
                testId="addToCartButton"
                classNamePrefix={props.sitePrefix}
              />
            </div>
          )}
          {showAlert && (
            <div
              className={`${props.sitePrefix}-alertContainer`}
              data-testid="alertContainer"
              style={props.style?.alertContainer}
            >
              <Alert icon={false} variant="filled" severity={muiAlertType} action={alertAction}>
                {muiAlert}
              </Alert>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ProductDetailPage;
