export const PageType = {
  HOME: 'Home',
  PRODUCT_DETAIL_PAGE: 'ProductDetailPage',
  CART_PAGE: 'CartPage',
  ORDER_PAGE: 'OrderPage',
  SUCCESS_PAGE: 'SuccessPage',
  NOT_FOUND: 'NotFoundPage',
  FAQ: 'FAQ',
};

export const ElementType = {
  MERCH_GRID_ITEM: 'MerchGridItem',
  VARIATION_SELECTOR: 'VariationSelector',
  CART_ITEM: 'MerchCartItem',
  ADD_TO_CART_BUTTON: 'AddToCartButton',
  PRODUCT_IMAGE: 'ProductImage',
};

export const ActionType = {
  ADD_TO_CART: 'AddToCart',
  GO_HOME: 'GoHome',
  GO_SHOP_ALL: 'GoShopAll',
  GO_STORE: 'GoStore',
  GO_LIVE_EVENTS: 'GoLiveEvents',
  GO_MUSIC: 'GoMusic',
  GO_ABOUT: 'GoAboutUs',
  GO_CART: 'GoCart',
  GO_SIGN_OUT: 'GoSignOut',
  SHOW_NAV_MENU: 'ShowNavMenu',
  CLOSE_NAV_MENU: 'CloseNavMenu',
  GO_PRODUCT_DETAIL: 'GoProductDetail',
  GO_CHECKOUT: 'GoCheckout',
  GO_SUCCESS: 'GoSuccess',
  CONFIRM_ORDER: 'ConfirmOrder',
};
