import 'src/sites/Plumeria/components/NavBar/NavBar.scss';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Badge, IconButton } from '@mui/material';
import Alert from '@mui/material/Alert';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SiteProps } from 'src/components/App/App';
import {
  PLUMERIA_PREFIX,
  SIGN_OUT_BUTTON_STRING,
  SIGN_OUT_ERROR,
  SIGN_OUT_SUCCESS,
} from 'src/constants/strings';
import { SiteType } from 'src/frameworks/models/SiteType';
import { recordClick } from 'src/metrics';
import { ActionType } from 'src/metricsTypes/metricsTypes';
import logo from 'src/sites/Plumeria/assets/logo.png';
import {
  logOut,
  selectCsrfToken,
  selectExpiryTime,
  selectLogOutError,
  selectLogOutSuccess,
  selectValidateTokenComplete,
  updateExpiryTime,
} from 'src/store/authSlice';
import { selectCart } from 'src/store/cartSlice';
import { selectCatalog } from 'src/store/catalogSlice';
import { selectSessionId } from 'src/store/identifierSlice';
import { AppDispatch } from 'src/store/store';
import { getValidCart, hydrateCatalogFromCart, MuiAlertType } from 'src/utils/utils';

interface NavBarProps extends SiteProps {
  isNavMenuOpen: boolean;
  setIsNavMenuOpen: (open: boolean) => void;
}

// TODO: update with new plumeria navbar
const SITE_TYPE = SiteType.PLUMERIA;

const NavBar = (props: NavBarProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const cart = useSelector(selectCart);
  const catalog = useSelector(selectCatalog);
  const expiryTime = useSelector(selectExpiryTime);
  const accessTokenExists = useSelector(selectValidateTokenComplete);
  const [muiAlertType, setMuiAlertType] = useState<MuiAlertType>(MuiAlertType.SUCCESS);
  const [muiAlert, setMuiAlert] = useState<string>('');
  const [showAlert, setShowAlert] = useState(false);
  const [validQuantity, setValidQuantity] = useState(0);
  const csrfToken = useSelector(selectCsrfToken);
  const logOutError = useSelector(selectLogOutError);
  const logOutSuccess = useSelector(selectLogOutSuccess);
  const sessionId = useSelector(selectSessionId);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    }
  }, [showAlert]);

  // Use quantity of ONLY valid cart items i.e. cart items that can be hydrated by the catalog
  useEffect(() => {
    const cartItems = cart?.items ?? [];
    const validCart = getValidCart(cartItems, catalog);
    setValidQuantity(validCart.quantity);
  }, [cart, catalog]);

  // hydrate the catalog once from the cart, as when the user refreshes, the catalog will only be hydrated from whichever page they are on
  // meaning some items in cart may not be hydrated therefore would not be shown in the cart quantity on the icon
  useEffect(() => {
    if (cart) hydrateCatalogFromCart(cart, dispatch, SITE_TYPE, csrfToken);
  }, []);

  const goCart = (): void => {
    recordClick(
      SITE_TYPE,
      ActionType.GO_CART,
      props.includeSessionIdInMetrics ? sessionId : undefined,
    );
    navigate(`/cart`);
    props.setIsNavMenuOpen(false);
  };

  const goSignOut = (): void => {
    recordClick(
      SITE_TYPE,
      ActionType.GO_SIGN_OUT,
      props.includeSessionIdInMetrics ? sessionId : undefined,
    );
    dispatch(updateExpiryTime(null));
    props.setIsNavMenuOpen(false);
    dispatch(
      logOut({
        site: SITE_TYPE,
      }),
    );
  };

  useEffect(() => {
    if (logOutSuccess) {
      setMuiAlertType(MuiAlertType.SUCCESS);
      setMuiAlert(SIGN_OUT_SUCCESS);
      setShowAlert(true);
      navigate(`/store`);
      props.setIsNavMenuOpen(false);
    } else if (logOutError) {
      setMuiAlertType(MuiAlertType.ERROR);
      setMuiAlert(SIGN_OUT_ERROR);
      setShowAlert(true);
    }
  }, [logOutSuccess, logOutError]);

  const isSignedIn = (): boolean => {
    const currentTimestamp = Date.now();
    const isExpired = expiryTime ? currentTimestamp > expiryTime : true;
    return !isExpired && accessTokenExists !== undefined && accessTokenExists;
  };

  const navBarLogo = (
    <div className={`${PLUMERIA_PREFIX}-navBarLogo`}>
      <a data-testid="navBarLogo" href="/">
        <img src={logo} />
      </a>
    </div>
  );

  const iconCartButton = (
    <IconButton aria-label="cart" onClick={goCart} data-testid="iconCartButton">
      <Badge
        badgeContent={validQuantity}
        invisible={validQuantity == 0}
        color="primary"
        data-testid="iconCartQuantityBadge"
      >
        <ShoppingCartIcon fontSize="large" color="secondary" />
      </Badge>
    </IconButton>
  );

  const iconSignOutButton = (
    <button
      className={`${PLUMERIA_PREFIX}-iconActionButton`}
      data-testid="iconSignOutButton"
      onClick={goSignOut}
    >
      <svg
        className={`${PLUMERIA_PREFIX}-iconAction`}
        xmlns="http://www.w3.org/2000/svg"
        data-testid="iconCart"
        viewBox="0 0 24 24"
      >
        <title>{SIGN_OUT_BUTTON_STRING}</title>
        <path
          className={`${PLUMERIA_PREFIX}-iconActionPath`}
          data-testid="iconSignOutPath"
          fill="white"
          d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z"
        ></path>
      </svg>
    </button>
  );

  return (
    <>
      <header className={`${PLUMERIA_PREFIX}-navBar`} data-testid="PLUMERIANavBar">
        <div className={`${PLUMERIA_PREFIX}-navBarRow`}>
          {navBarLogo}
          {iconCartButton}
          {isSignedIn() && iconSignOutButton}
        </div>
      </header>
      {showAlert && (
        <div className={`${PLUMERIA_PREFIX}-alertContainer`} data-testid="alertContainer">
          <Alert variant="filled" severity={muiAlertType}>
            {muiAlert}
          </Alert>
        </div>
      )}
    </>
  );
};

export default NavBar;
