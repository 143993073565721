import 'src/sites/TormentedValleyExternalPilot/TormentedValley.css';

import { useState } from 'react';
import Favicon from 'react-favicon';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { TORMENTED_VALLEY_PREFIX } from 'src/constants/strings';
import { SiteType } from 'src/frameworks/models/SiteType';
import { FavIcons } from 'src/images/icons';
import ClosePageComponent from 'src/pages/CloseWindowPage';
import { Paths } from 'src/paths/Paths';
import Footer from 'src/sites/TormentedValleyExternalPilot/components/Footer/Footer';
import NavBar from 'src/sites/TormentedValleyExternalPilot/components/NavBar/NavBar';
import CartPage from 'src/sites/TormentedValleyExternalPilot/pages/CartPage';
import Home from 'src/sites/TormentedValleyExternalPilot/pages/Home';
import NotFoundPage from 'src/sites/TormentedValleyExternalPilot/pages/NotFoundPage';
import TormentedValleyOrderPage from 'src/sites/TormentedValleyExternalPilot/pages/OrderPage';
import TormentedValleyProductDetailPage from 'src/sites/TormentedValleyExternalPilot/pages/ProductDetailPage';
import SuccessPage from 'src/sites/TormentedValleyExternalPilot/pages/SuccessPage';
import { selectCart } from 'src/store/cartSlice';

export const TV_INCLUDE_SESSION_ID_IN_METRICS = false;

const TormentedValley = () => {
  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);
  const cart = useSelector(selectCart);

  return (
    <BrowserRouter>
      <div className="page-wrapper">
        <NavBar
          isNavMenuOpen={isNavMenuOpen}
          setIsNavMenuOpen={setIsNavMenuOpen}
          includeSessionIdInMetrics={TV_INCLUDE_SESSION_ID_IN_METRICS}
          siteType={SiteType.TORMENTEDVALLEY}
          sitePrefix={TORMENTED_VALLEY_PREFIX}
        />

        {/* ToDo: Replace this with actual Favicon URLs when we have it. This is a temporary emoji. We can show/hide cart count */}
        <Favicon
          alertCount={cart?.quantity}
          alertFillColor="#000000"
          alertTextColor="#ffffff"
          animated={true}
          iconSize={128}
          url={FavIcons.TORMENTEDVALLEY}
        />
        <TransitionGroup className="content-wrapper">
          <CSSTransition key="group" classNames="fade" timeout={300}>
            <Routes>
              <Route path={Paths.home} Component={Home} />
              <Route path={Paths.store} Component={Home} />
              <Route path={Paths.details} Component={TormentedValleyProductDetailPage} />
              <Route path={Paths.cart} Component={CartPage} />
              <Route path={Paths.previewCheckout} Component={TormentedValleyOrderPage} />
              <Route path={Paths.successCheckout} Component={SuccessPage} />
              <Route path={Paths.closeWindow} Component={ClosePageComponent} />
              <Route path={Paths.invalid} Component={NotFoundPage} />
            </Routes>
          </CSSTransition>
        </TransitionGroup>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default TormentedValley;
