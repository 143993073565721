import React from 'react';

type MessageBoxType = 'error' | 'success';

export interface MessageBoxStyles {
  messageBoxContainer?: React.CSSProperties;
  messageBoxHeading?: React.CSSProperties;
  messageBoxText?: React.CSSProperties;
  messageBoxLink?: React.CSSProperties;
}
export interface MessageBoxProps {
  heading?: string;
  text: string;
  link?: string;
  linkText?: string;
  type?: MessageBoxType;
  styles?: MessageBoxStyles;
  testId?: string;
}

const MessageBox = (props: MessageBoxProps) => {
  return (
    <div
      className="messageBoxContainer"
      style={props.styles?.messageBoxContainer}
      data-testid={`${props.testId}-container`}
    >
      {props.heading && (
        <div
          className="messageBoxHeading"
          style={props.styles?.messageBoxHeading}
          data-testid={`${props.testId}-heading`}
        >
          {props.heading}
        </div>
      )}
      <div
        className="messageBoxText"
        style={props.styles?.messageBoxText}
        data-testid={`${props.testId}-text`}
      >
        {props.text}
      </div>
      {props.link && (
        <a
          className="messageBoxLink"
          href={props.link}
          rel="noreferrer"
          target="_blank"
          style={props.styles?.messageBoxLink}
          data-testid={`${props.testId}-link`}
        >
          {props.linkText}
        </a>
      )}
    </div>
  );
};

export default MessageBox;
