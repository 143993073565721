import React from 'react';

export interface ProductDetailBoxProps {
  heading: string;
  details: string[];
  styles?: {
    productDetailBoxContainer?: React.CSSProperties;
    productDetailBoxHeading?: React.CSSProperties;
    productDetailBoxContent?: React.CSSProperties;
    productDetailBoxContentList?: React.CSSProperties;
    productDetailBoxContentListItem?: React.CSSProperties;
  };
  testId?: string;
}

const ProductDetailBox = (props: ProductDetailBoxProps) => {
  return (
    <div
      className="productDetailBoxContainer"
      style={props.styles?.productDetailBoxContainer}
      data-testid={`${props.testId}-container`}
    >
      <div
        className="productDetailBoxHeading"
        style={props.styles?.productDetailBoxHeading}
        data-testid={`${props.testId}-heading`}
      >
        {props.heading}
      </div>
      <div
        className="productDetailBoxContent"
        style={props.styles?.productDetailBoxContent}
        data-testid={`${props.testId}-content`}
      >
        <ul
          className="productDetailBoxContentList"
          style={props.styles?.productDetailBoxContentList}
          data-testid={`${props.testId}-content-list`}
        >
          {props.details.map((detailItem, index) => {
            return (
              <li
                key={`productDetailBoxContentListItem-${index}`}
                className="productDetailBoxContentListItem"
                style={props.styles?.productDetailBoxContentListItem}
                data-testid={`${props.testId}-content-list-item`}
              >
                {detailItem}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ProductDetailBox;
