export const merchGridCss: Record<string, React.CSSProperties> = {
  merchGridContainer: {
    display: 'grid',
    width: '100%',
    maxWidth: 'var(--site-max-width)',
    margin: 'auto',
    marginTop: '0px',
    boxSizing: 'border-box',
  },
};
