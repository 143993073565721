import 'src/index.scss';
import 'src/logger';

import { LocalizationContextBuilder } from '@amzn/arb-tools';
import { KatSpinner } from '@amzn/katal-react';
import { MbmProvider } from '@amzn/react-arb-tools';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from 'src/components/App/App';
import AppInitWrapper from 'src/components/App/AppInitWrapper';
import { DEFAULT_LOCALE, TRANSLATION_ROUTE } from 'src/constants/localization';
import arbManifest from 'src/i18n/translations/arbManifest';
import { store } from 'src/store/store';

// Configuration for ARB message bundle manager
const mbmOptions = {
  arbManifest,
  defaultLocalizationContext: new LocalizationContextBuilder()
    .withLocale(DEFAULT_LOCALE)
    .withDefaultLocale(DEFAULT_LOCALE)
    .build(),
  urlPrefix: TRANSLATION_ROUTE,
};

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppInitWrapper>
        <React.Suspense fallback={<KatSpinner size="large" />}>
          <MbmProvider {...mbmOptions}>
            <App />
          </MbmProvider>
        </React.Suspense>
      </AppInitWrapper>
    </Provider>
  </React.StrictMode>,
);
