export const buttonGroupTormentedValleyStyle: Record<string, React.CSSProperties> = {
  pressedButton: {
    backgroundColor: 'var(--primary-color)',
    padding: '.5rem',
    color: 'var(--secondary-color)',
    border: '1px solid rgb(0, 0, 0)',
    marginBottom: '.5rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    borderRadius: '4px',
  },
  defaultButton: {
    backgroundColor: 'var(--primary-color)',
    color: 'var(--secondary-color)',
    marginBottom: '.5rem',
    padding: '.5rem',
    border: '1px solid rgb(219, 219, 219)',
    cursor: 'pointer',
    borderRadius: '4px',
  },
  grayedOutButton: {
    backgroundColor: 'var(--primary-color)',
    color: 'var(--tertiary-color)',
    marginBottom: '.5rem',
    padding: '.5rem',
    border: '1px dashed rgb(219, 219, 219)',
    cursor: 'pointer',
    borderRadius: '4px',
  },
  buttonGroupContainer: {
    columnGap: '.75rem',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '.5rem',
    WebkitUserSelect: 'none',
    userSelect: 'none',
    MozUserSelect: 'none',
  },
};
