export const quantitySelectorTormentedValleyStyle: Record<string, React.CSSProperties> = {
  quantitySelectorContainer: {
    display: 'flex',
    flexDirection: 'row',
    color: 'var(--secondary-color)',
    marginBottom: '0.6rem',
    fontSize: '1rem',
    border: '1px solid black',
    width: '8rem',
    borderRadius: '5px',
    height: '2.5rem',
  },
  quantitySelectorButton: {
    height: '2.5rem',
    width: '2.5rem',
    padding: '0 1rem',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.25rem',
    verticalAlign: 'top',
    textAlign: 'center',
    lineHeight: '2.5rem',
    WebkitUserSelect: 'none',
    userSelect: 'none',
    MozUserSelect: 'none',
  },
  quantitySelectorInput: {
    width: '3rem',
    height: '2.5rem',
    padding: '6px 12px',
    textAlign: 'center',
    fontFamily: 'var(--font-family)',
    border: 'none',
    boxSizing: 'border-box',
  },
};
