/**
 * Translations
 */
export const TRANSLATIONS_DIR_NAMESPACE = 'translations';

// This route is determined by translationOutputDir in configuration/configUtils.js
export const TRANSLATION_ROUTE = `/${TRANSLATIONS_DIR_NAMESPACE}`;

/**
 * Locales
 */
export const DEFAULT_LOCALE = 'en-US';
export const SUPPORTED_LOCALES = {
  DEFAULT_LOCALE,
};

/**
 * Message Bundles
 */
export const HOMEPAGE_MESSAGE_BUNDLE_NAME = 'pages.homepage';
