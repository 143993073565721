// since we are not localizing strings atm, put string constants here
export const AVAILABILITY_STRING = 'Availability';
export const RETURNS_STRING = 'Returns';
export const ORDER_SUMMARY_STRING = 'Order Summary';
export const ORDER_TOTAL_STRING = 'Order Total';
export const SHIPPING_TO_STRING = 'Shipping to ';
export const PAYMENT_STRING = 'Payment';
export const ENDING_IN_STRING = ' ending in ';
export const ARRIVING_STRING = 'Arriving ';
export const EACH_STRING = 'Each';
export const QUANTITY_STRING = 'Quantity';
export const SHIPMENT_STRING = 'Shipment ';
export const TOTAL_STRING = 'Total ';
export const EMPTY_CART_STRING = 'Your Cart is Empty';
export const TAKE_ME_HOME_STRING = 'Take Me Home';
export const TAKE_ME_TO_CART_STRING = 'Go To Cart';
export const HOME_STRING = 'Home';
export const SHOP_ALL_STRING = 'Shop All';
export const LIVE_EVENT_STRING = 'Live Events';
export const MUSIC_STRING = 'Music';
export const STORE_STRING = 'Store';
export const ABOUT_STRING = 'About';
export const MULBERRY_STRING = 'MULBERRY';
export const MENU_BUTTON_STRING = 'Menu';
export const CART_BUTTON_STRING = 'Cart';
export const VIEW_CART_BUTTON_STRING = 'View Cart';
export const SIGN_OUT_BUTTON_STRING = 'Sign Out';
export const CLOSE_BUTTON_STRING = 'Close Button';
export const LOCALE_STRING = 'en-US';
export const ADD_TO_CART_STRING = 'Add To Cart';
export const SELECT_ANOTHER_OPTION_STRING =
  'This option is unavailable. Please select another option.';
export const PREORDER_STRING = 'Preorder Now';
export const UNAVAILABLE_STRING = 'This item is unavailable.';
export const CHECKOUT_STRING = 'Checkout With Amazon';
export const YOUR_CART_STRING = 'Your Cart';
export const YOUR_ORDER_STRING = 'Your Order';
export const CART_FOOTER_TEXT = 'Taxes and shipping calculated at checkout';
export const CART_WITH_UNAVAILABLE_ITEMS_FOOTER_TEXT =
  'Please remove unavailable items to continue.';
export const CART_WITH_MAX_EXCEEDED_FOOTER_TEXT =
  'You have exceeded the maximum of 10 different items per order. Please remove item(s) to continue.';
export const SUBTOTAL_TEXT = 'Subtotal';
export const CONFIRM_ORDER_STRING = 'Place Order';
export const YOUR_ORDER_CONFIRMED_STRING = 'Your order is confirmed';
export const ORDER_STATUS_INSTRUCTION_STRING =
  'You may view your order status on your Amazon.com account page.';
export const TAKE_ME_TO_PAYMENT_STRING = 'Take me to my payments page.';
export const TAKE_ME_TO_ORDERS_STRING = 'Take me to my orders page.';
export const TAKE_ME_TO_ADDRESS_STRING = 'Take me to my addresses page.';
export const CONTINUE_SHOPPING_STRING = 'Continue Shopping';
export const KYC_INSTRUCTIONS_STRING = 'See instructions for uploading a KYC document.';
export const KYC_DOCUMENT_UPLOAD_REQUIRED_STRING =
  'You are required to upload a KYC document to complete this purchase. Please follow the link to upload this document then try again.';
export const REQUIRES_ADDITIONAL_PAYMENT_CONFIRMATION_STRING =
  'Sorry, your order requires additional payment confirmation to proceed.';
export const PAYMENT_ADDRESS_MISMATCH_STRING =
  'Your payment address does not match your default address. Please go to your Amazon account and update your address or payment method to continue.';
export const UPDATED_DEFAULT_SHIPPING_ADDRESS_STRING =
  'Your default address has changed. Please try checkout again.';
export const UPDATED_DEFAULT_PAYMENT_METHOD_STRING =
  'Your default payment method has changed. Please try checkout again.';
export const EXPIRED_DEFAULT_PAYMENT_INSTRUMENT_STRING =
  'Your default payment method has expired. Please go to your Amazon account and update your payment method to continue.';
export const BUYER_IS_SELLER_STRING =
  'Sorry, an item in your cart cannot be purchased as you are the seller. Please remove any items you are selling and try again.';
export const CANT_SHIP_TO_ADDRESS_STRING =
  'An item in your cart cannot be shipped to your default shipping address. Please go to your Amazon account and update your address to continue.';
export const PRICE_HAS_CHANGED_STRING =
  'Sorry, the price of an item in your cart has changed. Please try again.';
export const NO_MORE_ORDERING_UNITS_STRING =
  'Sorry, an item in your cart is no longer available. Please update your cart and try again.';
export const EXCEEDED_AVAILABLE_QUANTITY_STRING =
  'You have exceeded the maximum available quantity for an item in your cart. Please update the quantity and try again.';
export const EXCEEDED_PERMITTED_QUANTITY_STRING =
  'You have exceeded the maximum order quantity for an item in your cart. Please update the quantity and try again.';
export const LESS_THAN_PERMITTED_QUANTITY_STRING =
  'The quantity you have selected is lower than the minimum required for an item in your cart. Please update the quantity and try again.';
export const PURCHASE_ALREADY_CONFIRMED_STRING =
  'Your purchase has already been confirmed. Please go to your Amazon account to see your order.';
export const NO_BILLING_ADDRESS_ERROR_STRING =
  'Customer does not have a default billing address set. Please check your payment method.';
export const NO_ADDRESS_ERROR_STRING =
  'Your account does not have a default US shipping address set. Please go to your Amazon account and set a default US address to continue.';
export const NO_PAYMENT_ERROR_STRING =
  'Your account does not have a default US payment method set. Please go to your Amazon account and set a default US payment method to continue.';
export const NO_PAYMENT_OR_ADDRESS_ERROR_STRING =
  'Your account does not have a default US shipping address nor a default US payment method set. Please go to your Amazon account and set a default US address and a default US payment method to continue.';
export const EMPTY_CART_ERROR_STRING = `You don't have items in cart for checkout. Please continue shopping.`;
export const GENERIC_ORDER_ERROR_STRING = 'There was an issue with your order. Please try again.';
export const ITEM_UNAVAILABLE =
  'An item you are trying to purchase may not be available. Please try again';
export const NOT_FOUND_STRING = "The page you're looking for can't be found.";
export const GO_TO_ACCOUNT_STRING = 'Go to my Amazon Account page.';
export const UH_OH = 'Uh oh!';
export const LWA_ERROR = 'There was an issue signing into your Amazon account. Please try again.';
export const GENERIC_ERROR_STRING =
  'Sorry, there was an issue displaying this page. Please try again.';
export const ITEM_SUBTOTAL_STRING = 'Item Subtotal ';
export const ADD_TO_CART_SUCCESS = 'Item added to cart!';
export const SIGN_OUT_SUCCESS = 'Signed out successfully';
export const ADD_TO_CART_MAX = "Sorry, you've hit the maximum allowed quantity for this item.";

export const PRODUCT_STRING = 'Product';
export const LOGIN_WITH_AMAZON = 'Login with';
export const BUY_WITH_AMAZON = 'Buy with';
export const SHIPPED_BY_AMAZON_TEXT = 'Shipped and sold by Amazon.com';
export const PREORDER_PRICE_GUARANTEE_TEXT = 'Pre-order Price Guarantee.';
export const TERMS_TEXT = 'Terms';
export const LOGIN_WITH_AMAZON_SUBTEXT =
  'Securely link your Amazon account for Amazon’s delivery, returns, and quality.';
export const DEFAULT_PAYMENT_AND_ADDRESS_NOTE =
  'We use the default payment method and shipping address on your Amazon account. You can review or update in your Amazon ';
export const PRIVACY_NOTICE_AND_CONDITION_OF_USE_NOTE =
  "By placing your order, you agree to Amazon's";
export const ACCOUNT_SETTING = 'Account Settings';
export const PRIVACY_NOTICE = 'Privacy Notice';
export const CONDITIONS_OF_USE = 'Conditions of Use';
export const GENERIC_ERROR_CODE = 'Generic Error';
export const MAX_ITEMS_REACHED_TEXT =
  'You have reached the max amount of different items you can add to your cart.';
export const SIGN_OUT_ERROR = 'Error signing out. Please try again.';
export const LOADING_TEXT = 'Loading...';
export const FALLBACK_IMAGE_ERROR_MESSAGE = 'Fallback image used in place of media central image.';

// TV
export const TORMENTED_VALLEY_PREFIX = 'tv';
export const TORMENTED_VALLEY_STRING = 'THE TORMENTED VALLEY';

// Plumeria
export const PLUMERIA_PREFIX = 'pp';
