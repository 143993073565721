import MaterialButton from '@mui/material/Button';
import React from 'react';

export interface ButtonStyles {
  buttonContainer?: React.CSSProperties;
}
export interface ButtonProps {
  text: string;
  disabled?: boolean;
  onClick: () => void;
  onHover?: () => void;
  styles?: ButtonStyles;
  testId?: string;
  classNamePrefix: string;
}

const Button = (props: ButtonProps) => {
  return (
    <MaterialButton
      variant="outlined"
      className={`${props.classNamePrefix}-buttonContainer`}
      style={props.styles?.buttonContainer}
      data-testid={props.testId}
      disabled={props.disabled}
      onClick={props.onClick}
      onMouseOver={props.onHover}
    >
      {props.text}
    </MaterialButton>
  );
};

export default Button;
