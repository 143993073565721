export enum SiteType {
  TEST = 'TEST',
  TORMENTEDVALLEY = 'TORMENTEDVALLEY',
  PLUMERIA = 'PLUMERIA',
}

// SiteName should map to the artist domain without the www/.com. Beyonce.com would be beyonce. This is used for mediacentral images.
enum SiteName {
  TEST = 'test',
  TORMENTEDVALLEY = 'thetormentedvalley',
  PLUMERIA = 'pesopluma',
}

// SiteName
export const getSiteNameForType = (type: SiteType) => {
  switch (type) {
    case SiteType.PLUMERIA:
      return SiteName.PLUMERIA;
    case SiteType.TORMENTEDVALLEY:
      return SiteName.TORMENTEDVALLEY;
    case SiteType.TEST:
      return SiteName.TEST;
  }
};
