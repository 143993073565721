export const errorContainerStyles: React.CSSProperties = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  margin: '0',
  position: 'absolute',
  top: '-4rem',
  textAlign: 'center',
};
