import {
  Availability,
  DeliveryMessage,
  ItemIdentifier,
  PrimeBadgeData,
  ProductImages,
  ProductVariations,
} from '@amzn/mulberry-external-typescript-client';
import { DeleteOutlineRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SiteProps } from 'src/components/App/App';
import MediaCentralImage from 'src/components/Image/MediacentralImage';
import QuantitySelector, { SymbolStyle } from 'src/components/QuantitySelector/QuantitySelector';
import { recordClick } from 'src/metrics';
import { ActionType, ElementType, PageType } from 'src/metricsTypes/metricsTypes';
import { Cart, selectCart, setCart } from 'src/store/cartSlice';
import { selectSessionId } from 'src/store/identifierSlice';
import { AppDispatch } from 'src/store/store';
import { parseDeliveryMessage } from 'src/utils/deliveryMessageParser';
import { ImageSize } from 'src/utils/imageUtil';
import {
  buildProductUrl,
  findCartItemIndex,
  formatAvailabilityString,
  getVariationValues,
  isCatalogItemUnavailable,
} from 'src/utils/utils';

export interface CartItemStyle {
  cartItemContainer?: React.CSSProperties;
  cartItemImage?: React.CSSProperties;
  cartItemInfoContainer?: React.CSSProperties;
  cartItemInnerContainer?: React.CSSProperties;
  cartItemFirstRow?: React.CSSProperties;
  cartItemFirstColumn?: React.CSSProperties;
  cartItemSecondColumn?: React.CSSProperties;
  cartItemThirdColumn?: React.CSSProperties;
  removeItemButtonIcon?: React.CSSProperties;
  removeItemButton?: React.CSSProperties;
  cartTitleString?: React.CSSProperties;
  cartAvailabilityString?: React.CSSProperties;
  cartPriceContainer?: React.CSSProperties;
  cartPrimeBadgeContainer?: React.CSSProperties;
  cartLabelContainer?: React.CSSProperties;
  cartPriceString?: React.CSSProperties;
  cartItemQuantitySelectorStyle?: Record<string, React.CSSProperties>;
  totalPriceString?: React.CSSProperties;
  totalPriceContainer?: React.CSSProperties;
  totalPriceLabelString?: React.CSSProperties;
  variationContainer?: React.CSSProperties;
  variationKeyContainer?: React.CSSProperties;
  variationValueContainer?: React.CSSProperties;
  variationOuterContainer?: React.CSSProperties;
  deliveryMessageContainer?: React.CSSProperties;
  deliveryMessageOuterContainer?: React.CSSProperties;
}

export interface CartItemProps extends SiteProps {
  identifier: ItemIdentifier | undefined;
  image: ProductImages | undefined;
  title: string | undefined;
  availability: Availability | undefined;
  price: string | undefined;
  quantity: number | undefined;
  variations: ProductVariations | undefined;
  deliveryMessage?: DeliveryMessage;
  cartItemChanged?: boolean;
  primeBadgeData?: PrimeBadgeData;
  setCartItemChanged?: (changed: boolean) => void;
  styles?: CartItemStyle;
}

const CartItem = (props: CartItemProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(props.quantity ?? 0);
  const cart = useSelector(selectCart);
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const priceNumber = parseFloat((props.price ?? '0').replace('$', '')) || 0;
  const priceNumberString: string = formatter.format(priceNumber);
  const totalPrice: number = Number((quantity ?? 0) * priceNumber);
  const totalPriceString: string = formatter.format(totalPrice);
  const isAvailable = !isCatalogItemUnavailable(props.availability);
  const availabilityText = formatAvailabilityString(
    props.availability?.primaryMessage,
    props.availability?.secondaryMessage,
  );
  const sessionId = useSelector(selectSessionId);
  const variations: { [key: string]: string } =
    props.variations && props.identifier
      ? getVariationValues(
          props.variations,
          props.identifier.itemAsin ?? '',
          props.identifier.customId ?? '',
          props.identifier.customizationToken ?? '',
        )
      : {};

  const onRemoveItemClick = () => {
    setQuantity(0);
  };

  const goProductDetail = (): void => {
    recordClick(
      props.siteType,
      ActionType.GO_PRODUCT_DETAIL,
      props.includeSessionIdInMetrics ? sessionId : undefined,
      PageType.CART_PAGE,
      ElementType.CART_ITEM,
      props.identifier?.itemAsin,
    );
    const productUrl = buildProductUrl(
      props?.identifier?.itemAsin,
      props?.identifier?.customId,
      props?.identifier?.customizationToken,
    );
    navigate(`${productUrl}`);
  };

  useEffect(() => {
    const itemIndex = findCartItemIndex(
      cart,
      props.identifier?.itemAsin ?? '',
      props.identifier?.customId ?? undefined,
      props.identifier?.customizationToken ?? undefined,
    );
    const currentItemQuantity = cart?.items?.[itemIndex]?.quantity;
    // don't re-set the cart if the quantity has not changed
    if (quantity !== currentItemQuantity) {
      const oldCartQuantity = cart?.quantity ?? 0;
      const oldCartSubtotal = cart?.subtotal ?? 0;
      // If the purchaseItem is found, update its quantity
      if (cart && itemIndex !== -1) {
        const updatedItems = [...cart.items];
        updatedItems[itemIndex] = {
          ...updatedItems[itemIndex],
          quantity: quantity,
        };
        const newCartQuantity = updatedItems.reduce(
          (total, item) => total + (item.quantity ?? 0),
          0,
        );
        // to get the new subtotal, we need to get the number of items in the cart that changed * the price of the item
        // we use the difference of the new quantity and the old quantity to see if the number of items changed by a positive or negative amount
        const subtotal =
          newCartQuantity === 0
            ? 0
            : oldCartSubtotal + (newCartQuantity - oldCartQuantity) * priceNumber;
        const newCart: Cart = {
          items: updatedItems,
          quantity: newCartQuantity,
          subtotal: subtotal,
        };
        dispatch(setCart({ cart: newCart }));
      }
    }
  }, [quantity]);

  if (quantity === 0) {
    return null;
  }

  return (
    <div
      className={`${props.sitePrefix}-cartItemContainer`}
      data-testid="cartItemContainer"
      style={props.styles?.cartItemContainer}
    >
      {props.image && (
        <a className={`${props.sitePrefix}-cartItemLink`} onClick={goProductDetail}>
          <MediaCentralImage
            siteType={props.siteType}
            pageType={PageType.CART_PAGE}
            size={ImageSize.SMALL}
            productImages={props.image}
            testId={`cartItemImage`}
            style={props.styles?.cartItemImage}
          />
        </a>
      )}
      <div
        className={`${props.sitePrefix}-cartItemInfoContainer`}
        data-testid="cartItemInfoContainer"
        style={props.styles?.cartItemInfoContainer}
      >
        <div
          className={`${props.sitePrefix}-cartItemInnerContainer`}
          data-testid="cartItemInnerContainer"
          style={props.styles?.cartItemInnerContainer}
        >
          <div
            className={`${props.sitePrefix}-cartItemColumnContainer`}
            data-testid="cartItemColumnContainer"
          >
            <div
              className={`${props.sitePrefix}-cartItemInnerColumnContainer`}
              data-testid="cartItemInnerColumnContainer"
            >
              <div
                className={`${props.sitePrefix}-cartItemFirstColumn`}
                data-testid="cartItemFirstColumn"
                style={props.styles?.cartItemFirstColumn}
              >
                {props.title && (
                  <a className={`${props.sitePrefix}-cartItemLink`} onClick={goProductDetail}>
                    <div
                      className={`${props.sitePrefix}-cartTitleString`}
                      data-testid="cartTitleString"
                      style={props.styles?.cartTitleString}
                    >
                      {props.title}
                    </div>
                  </a>
                )}

                {/* Only display availability message when unavailable */}
                {!isAvailable && availabilityText && (
                  <div
                    className={`${props.sitePrefix}-cartAvailabilityString`}
                    data-testid="cartAvailabilityString"
                    style={props.styles?.cartAvailabilityString}
                  >
                    {availabilityText}
                  </div>
                )}

                {priceNumber !== 0 && (
                  <div
                    className={`${props.sitePrefix}-priceContainer`}
                    data-testid="cartPriceContainer"
                    style={props.styles?.cartPriceContainer}
                  >
                    <div
                      className={`${props.sitePrefix}-priceString`}
                      data-testid="priceString"
                      style={props.styles?.cartPriceString}
                    >
                      ${priceNumberString}
                    </div>
                  </div>
                )}

                {variations && (
                  <div
                    className={`${props.sitePrefix}-variationOuterContainer`}
                    data-testid="variationOuterContainer"
                    style={props.styles?.variationOuterContainer}
                  >
                    {Object.entries(variations).map(([key, value]) => (
                      <div
                        key={key}
                        className={`${props.sitePrefix}-variationContainer`}
                        data-testid="variationContainer"
                        style={props.styles?.variationContainer}
                      >
                        <div
                          className={`${props.sitePrefix}-variationKeyContainer`}
                          data-testid="variationKeyContainer"
                          style={props.styles?.variationKeyContainer}
                        >
                          {key}:
                        </div>
                        <div
                          className={`${props.sitePrefix}-variationValueContainer`}
                          data-testid="variationValueContainer"
                          style={props.styles?.variationValueContainer}
                        >
                          {value}
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {/* Only display delivery message when available */}
                {isAvailable && props.deliveryMessage && (
                  <div
                    className="deliveryMessageOuterContainer"
                    data-testid="deliveryMessageOuterContainer"
                    style={props.styles?.deliveryMessageOuterContainer}
                  >
                    <div
                      className="deliveryMessageContainer"
                      data-testid="deliveryMessageContainer"
                      style={props.styles?.deliveryMessageContainer}
                    >
                      {parseDeliveryMessage(props.deliveryMessage)}
                    </div>
                  </div>
                )}

                {props.primeBadgeData?.isPrime && props.primeBadgeData?.miniBadgeImageUrl && (
                  <div
                    data-testid="primeBadgeContainer"
                    style={props.styles?.cartPrimeBadgeContainer}
                  >
                    <img
                      data-testid={`primeBadge_${props.identifier?.itemAsin}`}
                      src={props.primeBadgeData?.miniBadgeImageUrl}
                      alt={props.primeBadgeData.imageAltText}
                    />
                  </div>
                )}
              </div>
              <div
                className={`${props.sitePrefix}-cartItemSecondColumn`}
                data-testid="cartItemSecondColumn"
                style={props.styles?.cartItemSecondColumn}
              >
                {quantity && quantity !== 0 && props.availability?.maxOrderQuantity && (
                  <QuantitySelector
                    disabled={!isAvailable}
                    symbolStyle={SymbolStyle.ARITHMETIC}
                    quantity={quantity}
                    maxQuantity={props.availability.maxOrderQuantity}
                    setQuantity={setQuantity}
                    styles={props.styles?.cartItemQuantitySelectorStyle}
                    classNamePrefix={props.sitePrefix}
                  />
                )}
                <IconButton
                  className={`${props.sitePrefix}-removeItemButton`}
                  data-testid="removeItemButton"
                  onClick={onRemoveItemClick}
                  style={props.styles?.removeItemButtonIcon}
                >
                  <DeleteOutlineRounded />
                </IconButton>
              </div>
            </div>
            <div
              className={`${props.sitePrefix}-cartItemThirdColumn`}
              data-testid="cartItemThirdColumn"
              style={props.styles?.cartItemThirdColumn}
            >
              {totalPrice !== 0 && (
                <div
                  className={`${props.sitePrefix}-totalPriceContainer`}
                  style={props.styles?.totalPriceContainer}
                  data-testid="totalPriceContainer"
                >
                  <div
                    className={`${props.sitePrefix}-totalPriceString`}
                    data-testid="totalPriceString"
                    style={props.styles?.totalPriceString}
                  >
                    ${totalPriceString}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
