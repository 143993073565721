import 'src/sites/Plumeria/Plumeria.css';

import { useState } from 'react';
import Favicon from 'react-favicon';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { PLUMERIA_PREFIX } from 'src/constants/strings';
import { SiteType } from 'src/frameworks/models/SiteType';
import ClosePageComponent from 'src/pages/CloseWindowPage';
import { Paths } from 'src/paths/Paths';
import BackgroundImage from 'src/sites/Plumeria/assets/background.png';
import Footer from 'src/sites/Plumeria/components/Footer/Footer';
import NavBar from 'src/sites/Plumeria/components/NavBar/NavBar';
import CartPage from 'src/sites/Plumeria/pages/CartPage';
import Home from 'src/sites/Plumeria/pages/Home';
import NotFoundPage from 'src/sites/Plumeria/pages/NotFoundPage';
import PlumeriaOrderPage from 'src/sites/Plumeria/pages/OrderPage';
import PlumeriaProductDetailPage from 'src/sites/Plumeria/pages/ProductDetailPage';
import SuccessPage from 'src/sites/Plumeria/pages/SuccessPage';
import { selectCart } from 'src/store/cartSlice';

import Faq from './pages/Faq';

export const PP_INCLUDE_SESSION_ID_IN_METRICS = false;

const Plumeria = () => {
  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);
  const cart = useSelector(selectCart);

  // TODO: add background image
  return (
    <BrowserRouter>
      <div
        className="pp-page-wrapper"
        style={{
          backgroundImage: `url(${BackgroundImage})`,
        }}
      >
        <NavBar
          isNavMenuOpen={isNavMenuOpen}
          setIsNavMenuOpen={setIsNavMenuOpen}
          includeSessionIdInMetrics={PP_INCLUDE_SESSION_ID_IN_METRICS}
          siteType={SiteType.PLUMERIA}
          sitePrefix={PLUMERIA_PREFIX}
        />

        {/* ToDo: Replace this with actual Favicon URLs when we have it. This is a temporary emoji. We can show/hide cart count */}
        <Favicon
          alertCount={cart?.quantity}
          alertFillColor="#000000"
          alertTextColor="#ffffff"
          animated={true}
          iconSize={128}
          url={
            'https://cdn.prod.website-files.com/65ccdb9b3a0994655a108f14/65d5435a722b14f40f952f1e_Untitled_favi_pp.png'
          }
        />
        <TransitionGroup className="content-wrapper">
          <CSSTransition key="group" classNames="fade" timeout={300}>
            <Routes>
              <Route path={Paths.home} Component={Home} />
              <Route path={Paths.store} Component={Home} />
              <Route path={Paths.details} Component={PlumeriaProductDetailPage} />
              <Route path={Paths.cart} Component={CartPage} />
              <Route path={Paths.previewCheckout} Component={PlumeriaOrderPage} />
              <Route path={Paths.successCheckout} Component={SuccessPage} />
              <Route path={Paths.closeWindow} Component={ClosePageComponent} />
              <Route path={Paths.faq} Component={Faq} />
              <Route path={Paths.invalid} Component={NotFoundPage} />
            </Routes>
          </CSSTransition>
        </TransitionGroup>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default Plumeria;
