import { SiteType } from 'src/frameworks/models/SiteType';
import { PageType } from 'src/metricsTypes/metricsTypes';
import withPageMetrics from 'src/shared/withPageMetrics';
import { PP_INCLUDE_SESSION_ID_IN_METRICS } from 'src/sites/Plumeria/Plumeria';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  color: var(--secondary-color);
  width: 100%;
  max-width: 90vw;
  margin: auto;
  margin-top: 0px;
  padding: 20px;
  flex-direction: column;
  font-family: Arial, sans-serif;
`;

const Content = styled.div`
  display: flex;
  color: var(--secondary-color);
  font-size: var(--body5-font-size);
  width: 100%;
  flex-direction: column;
`;

const Header = styled.h3`
  color: var(--secondary-color);
  font-size: var(--body4-font-size);
  margin-bottom: 12px;
`;

const Link = styled.a`
  color: #fff;
  font-weight: bold;
  margin: 5px;
`;

const FaqItem = styled.p`
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0;
`;

const PageHeader = styled.p`
  margin: 0;
  color: var(--secondary-color);
  font-size: 24px;
  font-weight: bold;
  font-family: Arial, sans-serif;
`;

const Faq = () => {
  return (
    <Container className="faqContainer">
      <Content className="faqContent">
        <PageHeader>Frequently Asked Questions</PageHeader>
        <Header>How do I change the shipping address for my order?</Header>
        <FaqItem>
          If your order has yet to ship, you can edit shipping address locations. Go to
          <Link href="http://Amazon.com">Amazon.com</Link>
          and click the &ldquo;Returns &amp; Orders&rdquo; tab on the top right hand of the site.
          You will be prompted to sign into Amazon and once signed into your Amazon account, you can
          view and manage your orders by selecting the &ldquo;Returns &amp; Orders&rdquo; tab at the
          top right hand of the page. From there, find your merch order and click on
          &ldquo;Change&rdquo; below the shipping address to edit and update to your desired
          shipping location.
        </FaqItem>
        <Header>How do I update my credit card information to purchase my merch?</Header>
        <FaqItem>
          To update your credit card information, go to
          <Link href="http://Amazon.com">Amazon.com</Link>
          and click the
          <Link href="https://www.amazon.com/gp/css/homepage.html?ref_=nav_AccountFlyout_ya">
            &ldquo;Account &amp; Lists&rdquo;
          </Link>
          tab on the top right hand of the site. Then select the &ldquo;Your Payments&rdquo; tab and
          you will be prompted to sign into Amazon. Once signed into your Amazon account, click
          &ldquo;Edit&rdquo; to update your default credit card information.
        </FaqItem>
        <Header>How do I know what international shipping rates are?</Header>
        <FaqItem>
          The cost to ship items from each product category varies by item, shipment, and shipping
          option. Amazon works closely with its carriers to provide a competitive shipping
          experience for our customers. Exact shipping charges are calculated at the time of
          checkout based on the number of items, item type, weight, and volume of all the items in
          the cart. The total shipping cost is calculated by adding a base &quot;Per Shipment&quot;
          cost and various &quot;Per Item&quot; costs. The &quot;Per Item&quot; cost depends on the
          item, and is based on the quantity of items, the weight, or volume (referred to as the
          dimensional weight) of each package. To view rates by country, click
          <Link href="https://www.amazon.com/gp/help/customer/display.html?nodeId=G3RFBWEWS8HAFWZP">
            here.
          </Link>
        </FaqItem>
        <Header>After I purchase my merch, how do I access or view my order?</Header>
        <FaqItem>
          To access or view your order, go to
          <Link href="http://Amazon.com">Amazon.com</Link>
          and click the &ldquo;Returns &amp; Orders&rdquo; tab on the top right hand of the site.
          You will be prompted to sign into Amazon and once signed into your Amazon account, you can
          view and manage your orders by selecting the &ldquo;Returns &amp; Orders&rdquo; tab. Then
          find the order you would like to access or view.
        </FaqItem>
        <Header>Once I place my order, will I receive a confirmation email?</Header>
        <FaqItem>
          Yes! Once you place your order you will receive an order confirmation email from Amazon to
          the email addressed linked to your Amazon account. Within this email, you will be able to
          view important order details including: order ID number, shipping address, estimated
          shipping location and links to track your package.
        </FaqItem>
        <Header>How can I check the status of my shipment?</Header>
        <FaqItem>
          To check the status of your order, go to
          <Link href="http://Amazon.com">Amazon.com</Link>
          and click the &ldquo;Returns &amp; Orders&rdquo; tab on the top right hand of the site.
          You will be prompted to sign into Amazon and once signed into your Amazon account, you can
          view and manage your orders by selecting the &ldquo;Returns &amp; Orders&rdquo; tab. Then
          find the order you would like to check shipment status for and on the bottom left of the
          page, you can easily view an updated arrival date or click &ldquo;Track Package&rdquo; on
          the right hand side of the page to track your package.
        </FaqItem>
        <Header>How can I cancel my merch order?</Header>
        <FaqItem>
          If your order has yet to ship, and you wish to cancel your order, go to
          <Link href="http://Amazon.com">Amazon.com</Link>
          then click the &ldquo;Returns &amp; Orders&rdquo; tab on the top right hand of the site.
          You will be prompted to sign into Amazon and once signed into your Amazon account, you can
          view and manage your orders by selecting the &ldquo;Returns &amp; Orders&rdquo; tab then
          find the order you would like to cancel and click &ldquo;Cancel Items&rdquo; on the bottom
          right of the page.
        </FaqItem>
        <Header>How do I find out more information about late or undeliverable orders?</Header>
        <FaqItem>
          If your merch is not delivered or when tracking shows your merch as delivered but you
          haven&rsquo;t received it or when you have other delivery issues go to
          <Link href="http://Amazon.com">Amazon.com</Link>
          &gt;
          <Link href="https://www.amazon.com/gp/help/customer/display.html?ref_=hp_bc_nav&amp;nodeId=GGE5X8EV7VNVTK6R">
            Shipping and Delivery
          </Link>
          &gt;
          <Link href="https://www.amazon.com/gp/help/customer/display.html?ref_=hp_bc_nav&amp;nodeId=GLMXPL8LEQBMUWDA">
            Where&apos;s My Stuff?
          </Link>
        </FaqItem>
        <Header>How can I contact customer service?</Header>
        <FaqItem>
          To contact customer service, go to
          <Link href="http://Amazon.com">Amazon.com</Link>
          &gt; click the &ldquo;Account &amp; Lists&rdquo; tab on the top right hand of the site
          &gt; click on the
          <Link href="https://www.amazon.com/hz/contact-us?ref_=nav_AccountFlyout_CS">
            Customer Service
          </Link>
          option at the bottom of the drop down menu. From there, select option that best describes
          your need.
        </FaqItem>
        <Header>
          How do I learn about how
          <Link href="http://pesopluma.com">pesopluma.com</Link>
          collects, uses, and discloses Personal Information?
        </Header>
        <FaqItem>
          Please visit
          <Link href="https://www.pesopluma.com/policies">pesopluma.com Privacy Policy.</Link> After
          reviewing this policy, if you have additional questions, want more information about our
          privacy practices, or would like to make a complaint, please contact us by e-mail at
          <Link href="mailto:admin@pesopluma.com">admin@pesopluma.com</Link>
          or by mail using the details provided below: Double P Records 1747 S Claudina Way,
          Anaheim, CA 92805, United States.
        </FaqItem>
        <Header>How do I return my merch?</Header>
        <FaqItem>
          To return your merch, you will go through the same process as any other
          <Link href="https://amazon.com">Amazon.com</Link>return. Go to the “My Orders” tab on
          <Link href="https://amazon.com">Amazon.com.</Link>You can find this tab at the top right
          hand of the site. Once signed into your Amazon account, you can view and manage your
          orders by selecting the “Orders” tab as shown below. On the bottom right hand side of the
          page, click on “Return” to start the returns process. You can drop off the merch you wish
          to return at a designated <Link href="https://amazon.com">Amazon.com</Link>return center
          or location. Eligible drop off locations will be recommended to you during the returns
          process on <Link href="https://amazon.com">Amazon.com.</Link>
        </FaqItem>
      </Content>
    </Container>
  );
};

export default withPageMetrics(
  SiteType.PLUMERIA,
  PageType.FAQ,
  Faq,
  PP_INCLUDE_SESSION_ID_IN_METRICS,
);
