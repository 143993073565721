import {
  Availability,
  AvailabilityType,
  DeliveryMessage,
  ItemIdentifier,
  ItemMetadata,
  OrderItem,
  PaymentMethodType,
  PrimeBadgeData,
  ProductImages,
  ProductVariations,
  PurchaseItem,
  Variation,
} from '@amzn/mulberry-external-typescript-client';
import { Dispatch } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import {
  AMAZON_ACCOUNTS_PAGE,
  AMAZON_ADDRESS_PAGE,
  AMAZON_ORDERS_PAGE,
  AMAZON_PAYMENT_PAGE,
  KYC_PAGE,
} from 'src/constants/links';
import { currentBaseUrl, POP_UP_WIDTH_AND_HEIGHT } from 'src/constants/lwa';
import { BATCH_SIZE } from 'src/constants/roadie';
import {
  BUYER_IS_SELLER_STRING,
  CANT_SHIP_TO_ADDRESS_STRING,
  CART_FOOTER_TEXT,
  CART_WITH_MAX_EXCEEDED_FOOTER_TEXT,
  CART_WITH_UNAVAILABLE_ITEMS_FOOTER_TEXT,
  EXCEEDED_AVAILABLE_QUANTITY_STRING,
  EXCEEDED_PERMITTED_QUANTITY_STRING,
  EXPIRED_DEFAULT_PAYMENT_INSTRUMENT_STRING,
  GENERIC_ORDER_ERROR_STRING,
  GO_TO_ACCOUNT_STRING,
  KYC_DOCUMENT_UPLOAD_REQUIRED_STRING,
  KYC_INSTRUCTIONS_STRING,
  LESS_THAN_PERMITTED_QUANTITY_STRING,
  LOCALE_STRING,
  NO_ADDRESS_ERROR_STRING,
  NO_BILLING_ADDRESS_ERROR_STRING,
  NO_MORE_ORDERING_UNITS_STRING,
  NO_PAYMENT_ERROR_STRING,
  NO_PAYMENT_OR_ADDRESS_ERROR_STRING,
  PAYMENT_ADDRESS_MISMATCH_STRING,
  PRICE_HAS_CHANGED_STRING,
  PURCHASE_ALREADY_CONFIRMED_STRING,
  REQUIRES_ADDITIONAL_PAYMENT_CONFIRMATION_STRING,
  TAKE_ME_TO_ADDRESS_STRING,
  TAKE_ME_TO_CART_STRING,
  TAKE_ME_TO_ORDERS_STRING,
  TAKE_ME_TO_PAYMENT_STRING,
  UPDATED_DEFAULT_PAYMENT_METHOD_STRING,
  UPDATED_DEFAULT_SHIPPING_ADDRESS_STRING,
} from 'src/constants/strings';
import { SiteType } from 'src/frameworks/models/SiteType';
import { AuthState, setAuthState, updateExpiryTime } from 'src/store/authSlice';
import { Cart } from 'src/store/cartSlice';
import { hydrateCatalog } from 'src/store/catalogSlice';
import { AppDispatch } from 'src/store/store';
import { BuildSignInUrl } from 'src/utils/loginWithAmazon';

/*
Possible chargeTypes are:
PURCHASE_TOTAL: Total price for the purchase, inclusive of all applicable taxes, shipping fees, and discounts.
ITEMS_SUBTOTAL: Purchase price, exclusive of any applicable taxes, shipping fees, and discounts.
ESTIMATED_TAX_TOTAL: Total amount of estimated taxes to be collected.
IMPORT_EXPORT_FEE_TOTAL: Total fees associated with the import or export of the products in the purchase.
REGULATORY_FEE_TOTAL: Total amount of regulatory fees associated with the purchase, including compliance costs, environmental regulations, and other regulatory charges.
SHIPPING_FEE_TOTAL: Total shipping and handling costs, before shipping related discounts.
ITEMS_DISCOUNT_TOTAL: Total discount applied to a purchase, excluding any discounts related to shipping costs. Will be a negative value if present.
SHIPPING_DISCOUNT_TOTAL: Total discount applied to shipping and handling costs. Will be a negative value if present.
*/

export const getChargeTypeString = (chargeType: string): string => {
  switch (chargeType) {
    case 'PURCHASE_TOTAL':
      return 'Total: ';

    case 'ITEMS_SUBTOTAL':
      return 'Subtotal: ';

    case 'ESTIMATED_TAX_TOTAL':
      return 'Estimated Tax: ';

    case 'IMPORT_EXPORT_FEE_TOTAL':
      return 'Import/Export Fee: ';

    case 'REGULATORY_FEE_TOTAL':
      return 'Regulatory Fee: ';

    case 'SHIPPING_FEE_TOTAL':
      return 'Shipping Fee: ';

    case 'ITEMS_DISCOUNT_TOTAL':
      return 'Discounts: ';

    case 'SHIPPING_DISCOUNT_TOTAL':
      return 'Shipping Discounts: ';

    default:
      return 'Miscellaneous Fee: ';
  }
};
/*
Possible payment method types are:
CARD: Payment card such as a credit card, debit card or charge card. Gift cards are not included in this category.
AMAZON_CREDIT: Payment Method that enables customers to purchase using credit, provided to the customer by third party lenders and repay the credit either in installments or at one go at a later date.
AMAZON_PAY: Payment Method which represents the consolidated balance of gift card and stored value account.
AMAZON_PRODUCT_VOUCHER: Payment Method that enables customers to use stored value to purchase restricted products in Amazon.
BANK_ACCOUNT: Payment Method that represents an arrangement a customer has made with a bank whereby a customer may deposit and/or withdraw money.
DIGITAL_WALLET: Payment Method that allows customers to pay for purchases via their Digital Wallet account provided by Digital Wallet brands.
EXTERNAL_PAYMENT_METHOD: Payment Method that allows customers to pay with token generated by external partner without storing actual payment method information into Amazon system.
GIFT_CARD: Payment Method that represents an internal or third-party gift card.
PAY_PAL: PayPal is a global online payment system and digital wallet. Customers choosing to pay with PayPal may be redirected to the PayPal site to log in to their PayPal wallet, authenticate and authorize the payment.
PROMOTION: Payment Method that represents a promotion or discount.
ZELLE: Payment Method that enables customers to pay using Zelle, a US-based digital instant payment network allowing individuals to transfer money from their bank account to another registered user’s bank account using mobile devices or the websites of participating banking institutions with an email address or phone number.
UNKNOWN: Payment method type cannot be determined.
*/

export const getPaymentMethodString = (paymentType: PaymentMethodType | string): string => {
  // Handle the PayPal case separately
  if (paymentType === 'PAY_PAL') {
    return 'PayPal';
  }
  // All other strings should just replace '_' with ' ' and make title case
  const formatString = (str: string): string =>
    str
      .replace(/_/g, ' ')
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());

  return formatString(paymentType);
};

// format the strings as "str1, str2" if both exist, otherwise show one if one exists, none if neither exist
export const formatAvailabilityString = (
  primaryMessage: string | undefined,
  secondaryMessage: string | undefined,
) => {
  return [primaryMessage, secondaryMessage].filter(Boolean).join(', ');
};

export const getVariationValues = (
  productVariations: ProductVariations,
  asin: string,
  customId: string,
  customizationToken: string,
): { [key: string]: string } => {
  const variationValues: { [key: string]: string } = {};

  // Find the variation for the specified ASIN
  const variation = productVariations?.variations?.find(
    (v) =>
      v.asin === asin &&
      (isEmpty(customId) || v.customId === customId) &&
      (isEmpty(customizationToken) || v.customizationToken === customizationToken),
  );

  if (!variation) {
    return {};
  }

  // Map dimension names to their corresponding values
  productVariations?.dimensions?.forEach((dimension, index) => {
    const valueIndex = variation?.dimensionLocation?.[index] ?? -1;
    const value = dimension?.values?.[valueIndex] || 'Unknown';

    if (dimension.name && value) {
      variationValues[dimension.name] = value;
    }
  });

  return variationValues;
};

export const isSamePurchaseItem = (item1: PurchaseItem, item2: PurchaseItem): boolean => {
  return (
    item1.asin === item2.asin &&
    isEqual(item1.customId, item2.customId) &&
    isEqual(item1.customizationToken, item2.customizationToken)
  );
};

export const findCartItemIndex = (
  cart: Cart | null,
  asin: string,
  customId: string | undefined,
  customizationToken: string | undefined,
): number => {
  if (cart) {
    return cart.items.findIndex(
      (item) =>
        item.asin === asin &&
        isEqual(item.customId, customId) &&
        isEqual(item.customizationToken, customizationToken),
    );
  }
  return -1;
};

export const findCatalogItemUsingPurchaseItem = (
  catalog: ItemMetadata[],
  item: OrderItem | PurchaseItem,
): ItemMetadata | null => {
  return (
    catalog.find(
      (catalogItem) =>
        catalogItem.identifier?.itemAsin === item.asin &&
        isEqual(catalogItem.identifier?.customId, item.customId) &&
        isEqual(catalogItem.identifier?.customizationToken, item.customizationToken),
    ) ?? null
  );
};

export const isCatalogItemUnavailable = (availability: Availability | undefined): boolean => {
  if (availability) {
    return (
      availability.type === AvailabilityType.OUT_OF_STOCK ||
      availability.type === AvailabilityType.UNAVAILABLE ||
      availability.type === AvailabilityType.UNKNOWN ||
      availability.type === AvailabilityType.UNKNOWN_AVAILABILITY
    );
  }
  return true;
};

export const convertPriceStringToNumber = (price: string): number => {
  return parseFloat(price.replace('$', ''));
};

export const isIdentifierEqualToVariationIdentifier = (
  variation: Variation,
  identifier: ItemIdentifier,
): boolean => {
  return (
    variation.asin === identifier.itemAsin &&
    isEqual(variation.customId, identifier.customId) &&
    isEqual(variation.customizationToken, identifier.customizationToken)
  );
};

export const isIdentifierEqualToSecondIdentifier = (
  primaryIdentifier: ItemIdentifier,
  secondaryIdentifier: ItemIdentifier,
): boolean => {
  return (
    primaryIdentifier.itemAsin === secondaryIdentifier.itemAsin &&
    isEqual(primaryIdentifier.customId, secondaryIdentifier.customId) &&
    isEqual(primaryIdentifier.customizationToken, secondaryIdentifier.customizationToken)
  );
};

export const priceToUSDStringFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const getPriceNumberFromString = (price: string) => {
  return parseFloat(price.replace('$', '')) || 0;
};

export enum MuiAlertType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

/* Possible error codes that we want to be explicit in our messaging to customers for:
PURCHASE_ALREADY_CONFIRMED: Purchase is already confirmed
LESS_THAN_PERMITTED_QUANTITY: The quantity selected is less than permitted minimum quantity for the item.
EXCEEDED_PERMITTED_QUANTITY: The quantity selected exceeds permitted quantity for the item.
EXCEEDED_AVAILABLE_QUANTITY: The quantity selected exceeds available quantity for the item.
NO_MORE_ORDERING_UNITS: Cannot deliver this item anymore because available quantity is 0.
PRICE_HAS_CHANGED: The item price has changed
CANT_SHIP_TO_ADDRESS: An item cannot be shipped to the selected destination
BUYER_IS_SELLER: A seller can't buy it's own product.
DEFAULT_SHIPPING_ADDRESS_NOT_SET: Customer does not have a default shipping address set.
DEFAULT_PAYMENT_METHOD_NOT_SET: Customer does not have a default payment method set.
UPDATED_DEFAULT_SHIPPING_ADDRESS: Customer updated default shipping address after preview, and before confirm order.
UPDATED_DEFAULT_PAYMENT_METHOD: Customer updated default payment method after preview, and before confirm order.
EXPIRED_DEFAULT_PAYMENT_INSTRUMENT: Default payment method has expired.
PAYMENT_ADDRESS_MISMATCH: Default payment method address is not associated with default shipping address.
REQUIRES_ADDITIONAL_PAYMENT_CONFIRMATION: Order requires additional payment confirmation.
KYC_DOCUMENT_UPLOAD_REQUIRED: Customer must upload Know Your Customer documentation on Amazon account.
*/

export const getErrorMessage = (errorCodes: string[]): string => {
  switch (true) {
    case errorCodes.includes('DEFAULT_SHIPPING_ADDRESS_NOT_SET') &&
      errorCodes.includes('DEFAULT_PAYMENT_METHOD_NOT_SET'):
      return NO_PAYMENT_OR_ADDRESS_ERROR_STRING;
    case errorCodes.includes('DEFAULT_SHIPPING_ADDRESS_NOT_SET'):
      return NO_ADDRESS_ERROR_STRING;
    case errorCodes.includes('DEFAULT_PAYMENT_METHOD_NOT_SET'):
      return NO_PAYMENT_ERROR_STRING;
    case errorCodes.includes('BILLING_ADDRESS_NOT_SET'):
      return NO_BILLING_ADDRESS_ERROR_STRING;
    case errorCodes.includes('PURCHASE_ALREADY_CONFIRMED'):
      return PURCHASE_ALREADY_CONFIRMED_STRING;
    case errorCodes.includes('LESS_THAN_PERMITTED_QUANTITY'):
      return LESS_THAN_PERMITTED_QUANTITY_STRING;
    case errorCodes.includes('EXCEEDED_PERMITTED_QUANTITY'):
      return EXCEEDED_PERMITTED_QUANTITY_STRING;
    case errorCodes.includes('EXCEEDED_AVAILABLE_QUANTITY'):
      return EXCEEDED_AVAILABLE_QUANTITY_STRING;
    case errorCodes.includes('NO_MORE_ORDERING_UNITS'):
      return NO_MORE_ORDERING_UNITS_STRING;
    case errorCodes.includes('PRICE_HAS_CHANGED'):
      return PRICE_HAS_CHANGED_STRING;
    case errorCodes.includes('CANT_SHIP_TO_ADDRESS'):
      return CANT_SHIP_TO_ADDRESS_STRING;
    case errorCodes.includes('BUYER_IS_SELLER'):
      return BUYER_IS_SELLER_STRING;
    case errorCodes.includes('EXPIRED_DEFAULT_PAYMENT_INSTRUMENT'):
      return EXPIRED_DEFAULT_PAYMENT_INSTRUMENT_STRING;
    case errorCodes.includes('PAYMENT_ADDRESS_MISMATCH'):
      return PAYMENT_ADDRESS_MISMATCH_STRING;
    case errorCodes.includes('REQUIRES_ADDITIONAL_PAYMENT_CONFIRMATION'):
      return REQUIRES_ADDITIONAL_PAYMENT_CONFIRMATION_STRING;
    case errorCodes.includes('KYC_DOCUMENT_UPLOAD_REQUIRED'):
      return KYC_DOCUMENT_UPLOAD_REQUIRED_STRING;
    case errorCodes.includes('UPDATED_DEFAULT_SHIPPING_ADDRESS'):
      return UPDATED_DEFAULT_SHIPPING_ADDRESS_STRING;
    case errorCodes.includes('UPDATED_DEFAULT_PAYMENT_METHOD'):
      return UPDATED_DEFAULT_PAYMENT_METHOD_STRING;
    default:
      return GENERIC_ORDER_ERROR_STRING;
  }
};

export const getErrorMessageLink = (errorCodes: string[], sitePrefix: string): string => {
  switch (true) {
    case errorCodes.includes('DEFAULT_SHIPPING_ADDRESS_NOT_SET') &&
      errorCodes.includes('DEFAULT_PAYMENT_METHOD_NOT_SET'):
      return AMAZON_ACCOUNTS_PAGE(sitePrefix);
    case errorCodes.includes('CANT_SHIP_TO_ADDRESS') ||
      errorCodes.includes('DEFAULT_SHIPPING_ADDRESS_NOT_SET'):
      return AMAZON_ADDRESS_PAGE(sitePrefix);
    case errorCodes.includes('KYC_DOCUMENT_UPLOAD_REQUIRED'):
      return KYC_PAGE(sitePrefix);
    case errorCodes.includes('PURCHASE_ALREADY_CONFIRMED'):
      return AMAZON_ORDERS_PAGE(sitePrefix);
    case errorCodes.includes('PAYMENT_ADDRESS_MISMATCH') ||
      errorCodes.includes('REQUIRES_ADDITIONAL_PAYMENT_CONFIRMATION') ||
      errorCodes.includes('EXPIRED_DEFAULT_PAYMENT_INSTRUMENT') ||
      errorCodes.includes('DEFAULT_PAYMENT_METHOD_NOT_SET'):
      return AMAZON_PAYMENT_PAGE(sitePrefix);
    default:
      return '';
  }
};

export const getErrorMessageText = (errorCodes: string[]): string => {
  switch (true) {
    case errorCodes.includes('DEFAULT_SHIPPING_ADDRESS_NOT_SET') &&
      errorCodes.includes('DEFAULT_PAYMENT_METHOD_NOT_SET'):
      return GO_TO_ACCOUNT_STRING;
    case errorCodes.includes('CANT_SHIP_TO_ADDRESS') ||
      errorCodes.includes('DEFAULT_SHIPPING_ADDRESS_NOT_SET'):
      return TAKE_ME_TO_ADDRESS_STRING;
    case errorCodes.includes('KYC_DOCUMENT_UPLOAD_REQUIRED'):
      return KYC_INSTRUCTIONS_STRING;
    case errorCodes.includes('PURCHASE_ALREADY_CONFIRMED'):
      return TAKE_ME_TO_ORDERS_STRING;
    case errorCodes.includes('PAYMENT_ADDRESS_MISMATCH') ||
      errorCodes.includes('REQUIRES_ADDITIONAL_PAYMENT_CONFIRMATION') ||
      errorCodes.includes('EXPIRED_DEFAULT_PAYMENT_INSTRUMENT') ||
      errorCodes.includes('DEFAULT_PAYMENT_METHOD_NOT_SET'):
      return TAKE_ME_TO_PAYMENT_STRING;
    case errorCodes.includes('UPDATED_DEFAULT_SHIPPING_ADDRESS') ||
      errorCodes.includes('UPDATED_DEFAULT_PAYMENT_METHOD'):
      return TAKE_ME_TO_CART_STRING;
    default:
      return '';
  }
};

export type ValidCartItem = {
  identifier: ItemIdentifier | undefined;
  merchantId: string | undefined;
  offerId: string | undefined;
  image: ProductImages | undefined;
  title: string | undefined;
  availability: Availability | undefined;
  price: string | undefined;
  quantity: number | undefined;
  deliveryMessage: DeliveryMessage | undefined;
  variations: ProductVariations | undefined;
  primeBadgeData: PrimeBadgeData | undefined;
} | null;

export type ValidCart = {
  cartItems: ValidCartItem[];
  quantity: number;
  subtotal: string;
};

export const getValidCart = (cartItems: PurchaseItem[], catalog: ItemMetadata[]): ValidCart => {
  const hydratedCartItems: ValidCartItem[] =
    cartItems
      .map((item) => {
        const catalogItem: ItemMetadata | null = findCatalogItemUsingPurchaseItem(catalog, item);
        if (catalogItem) {
          return {
            identifier: catalogItem.identifier,
            merchantId: catalogItem.merchantId,
            offerId: catalogItem.offerId,
            image: catalogItem.images?.[0],
            title: catalogItem.name,
            availability: catalogItem.availability,
            price: catalogItem.price,
            quantity: item.quantity,
            variations: catalogItem.variations,
            deliveryMessage: catalogItem.deliveryMessage,
            primeBadgeData: catalogItem.primeBadgeData,
          };
        } else {
          return null;
        }
      })
      .filter((item) => item !== null) ?? [];
  const totalSubtotal = hydratedCartItems.reduce((total, item) => {
    const itemQuantity = item?.quantity ?? 0;
    const priceNumber = parseFloat((item?.price ?? '0').replace('$', '')) || 0;
    const itemSubtotal = priceNumber * itemQuantity;
    return total + itemSubtotal;
  }, 0);
  const totalQuantity = hydratedCartItems.reduce((total, item) => {
    return total + (item?.quantity ?? 0);
  }, 0);
  const totalPriceString: string = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(totalSubtotal);
  return { cartItems: hydratedCartItems, quantity: totalQuantity, subtotal: totalPriceString };
};

export const buildProductUrl = (
  asin: string | undefined,
  customId: string | undefined,
  customizationToken: string | undefined,
): string => {
  if (asin) {
    let url = `/product/${asin}`;
    const searchParams = new URLSearchParams();
    if (customId) {
      searchParams.append('customId', customId);
    }
    if (customizationToken) {
      searchParams.append('customizationToken', customizationToken);
    }
    if (searchParams.toString()) {
      url += `?${searchParams.toString()}`;
    }
    return url;
  } else return '';
};

export const useExpiryTimeMs = (dispatch: Dispatch) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const expiryParam = urlSearchParams.get('expiry');
  if (expiryParam) {
    const expiryTimeSeconds = Number(expiryParam);
    const expiryTimeInMs = expiryTimeSeconds ? expiryTimeSeconds * 1000 : null;
    const currentTimestamp = Date.now();
    const isExpired = expiryTimeInMs ? currentTimestamp > expiryTimeInMs : true;
    if (!isExpired) {
      dispatch(updateExpiryTime(expiryTimeInMs));
    }
  }
};

export const handleStorageChange = (
  event: StorageEvent,
  dispatch: Dispatch,
  prevValue: AuthState,
) => {
  return new Promise<void>((resolve, reject) => {
    if (event.key === 'authState') {
      const storedAuthStateJSON = localStorage.getItem('authState');
      const storedAuthState = storedAuthStateJSON ? JSON.parse(storedAuthStateJSON) : null;
      if (storedAuthState && prevValue.expiryTime !== storedAuthState?.expiryTime) {
        dispatch(setAuthState(storedAuthState));
        resolve(); // Resolve the promise only if expiry time has changed
      } else {
        reject();
      }
    } else {
      reject();
    }
  });
};

export const handleAuth = (clientId: string, redirectUrl: string) => {
  const amazonLoginUrl = BuildSignInUrl(clientId, redirectUrl);
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const windowWidth = Math.min(screenWidth, POP_UP_WIDTH_AND_HEIGHT);
  const windowHeight = Math.min(screenHeight, POP_UP_WIDTH_AND_HEIGHT);
  const left = screenWidth / 2 - windowWidth / 2;
  const top = screenHeight / 2 - windowHeight / 2;

  // Open the LWA login in page in a new window
  const signInWindow = window.open(
    amazonLoginUrl,
    '_blank',
    `width=${windowWidth},height=${windowHeight},left=${left},top=${top}`,
  );

  // Add an event listener to the new window to handle any LWA errors
  signInWindow?.addEventListener('message', (event) => {
    if (event.origin !== currentBaseUrl) {
      return;
    }
    if (event.data.type === 'lwa-error') {
      // Display the LWA error in the new window
      signInWindow.document.body.innerHTML = `
        <div style="
          text-align: center;
          margin-top: 10px;
          padding: 20px;
          background-color: #f1f1f1;
          border-radius: 4px;
        ">
          <h2>LWA Error</h2>
          <p>${event.data.error}</p>
        </div>
      `;
    }
  });
};

/* when the user logs in successfully, the expiry time is set as a param in the LWA pop up window, then stored in local storage in that window
  this useEffect checks if expiryTime in local storage has changed in the original window and if so updates the redux state and reloads */
export const reloadWindowOnStorageChange = (dispatch: Dispatch, prevValue: AuthState) => {
  const handleStorageEvent = (event: StorageEvent) => {
    handleStorageChange(event, dispatch, prevValue)
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        // This just means the expiry time has not been changed and we do not need to reload the page
      });
  };

  window.addEventListener('storage', handleStorageEvent);
  // Clean up the event listener to prevent memory leaks
  return () => {
    window.removeEventListener('storage', handleStorageEvent);
  };
};

export const hasUnavailableItems = (cartItems: ValidCartItem[]) => {
  return cartItems.find((item) => isCatalogItemUnavailable(item?.availability)) !== undefined;
};

export const identifierExistsInCart = (cartItems: ValidCartItem[], identifier: ItemIdentifier) => {
  return cartItems.some(
    (item) => item?.identifier && isIdentifierEqualToSecondIdentifier(item?.identifier, identifier),
  );
};

export const hydrateCatalogFromCart = (
  cart: Cart,
  dispatch: AppDispatch,
  siteType: SiteType,
  csrfToken: string | undefined,
) => {
  const itemIdentifiers =
    cart.items.map((cartItem) => {
      return {
        itemAsin: cartItem.asin,
        customId: cartItem.customId,
        customizationToken: cartItem.customizationToken,
      } as ItemIdentifier;
    }) ?? [];
  // Separate item identifiers into chunks
  for (let i = 0; i < itemIdentifiers.length; i += BATCH_SIZE) {
    const chunk = itemIdentifiers.slice(i, i + BATCH_SIZE);
    dispatch(
      hydrateCatalog({
        itemIdentifiers: chunk,
        site: siteType,
        locale: LOCALE_STRING,
        csrfToken: csrfToken,
      }),
    );
  }
};

// show default footer text if unauthed or authed with no issues
// show other text if authed and there is an unavailable item/max items exceeded
export const getFooterText = (
  isLoggedIn: boolean,
  maxItemsExceeded: boolean,
  hasUnavailableItem: boolean,
): string => {
  if (!isLoggedIn) return CART_FOOTER_TEXT;
  else {
    return maxItemsExceeded
      ? CART_WITH_MAX_EXCEEDED_FOOTER_TEXT
      : hasUnavailableItem
        ? CART_WITH_UNAVAILABLE_ITEMS_FOOTER_TEXT
        : CART_FOOTER_TEXT;
  }
};

// Regular expression to match the price format: xx.xx where xx.xx are digits
const priceRegex = /\d+\.\d{2}\b/;

export const extractPriceAsNumber = (input: string): number | null => {
  const match = input.match(priceRegex);
  if (match) {
    const priceString = match[0];
    const price = parseFloat(priceString);
    if (!isNaN(price)) {
      return price;
    }
  }
  return null;
};

export const replacePrice = (message: string, price: number): string => {
  const match = message.match(priceRegex);
  if (match) {
    const replacedMessage = message.replace(priceRegex, price.toFixed(2));
    return replacedMessage;
  }
  return message; // Return the original message if no match found
};

export const buildCombinedShippingAndImportCostString = (
  importFeeString: string,
  deliveryMessageString: string,
  message: string,
): string => {
  const importFee = extractPriceAsNumber(importFeeString);
  const shippingFee = extractPriceAsNumber(deliveryMessageString);
  const combinedCost = (importFee ?? 0) + (shippingFee ?? 0);
  const newMessage = replacePrice(message, combinedCost);
  return newMessage;
};
