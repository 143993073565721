import { getSiteNameForType, SiteType } from 'src/frameworks/models/SiteType';

// Config of Sites that should use MediaCentral for transparent images
const ALLOWED_SITES = [SiteType.PLUMERIA, SiteType.TEST];

export enum ImageSize {
  LARGE,
  SMALL,
}

/* Create the URL based on the properties. 
  - Site name is used as a folder inside /AmazonMusic/Store
  - Images are stored inside /sitename/images/
  - Size is set using _SY400_ where 400 is the value pixels
  - "_FMpng_" is required to return transparent png instead of JPG
  - _AC_ crops out whitespace
*/
const MEDIA_CENTRAL_URL = (siteType: SiteType, id: string, size?: ImageSize) =>
  `https://m.media-amazon.com/images/G/01/ALPS/store` +
  `/${getSiteNameForType(siteType)}` +
  `/images` +
  `/${id.replace(/\+/g, '')}` +
  `.${size === ImageSize.LARGE ? '_SY800_' : '_SY400_'}` +
  `._FMpng_._AC_.png`;

/* Returns the MediaCentral image for the image url, asin, sitetype combination we have. This only works if the image is uploaded to
  media central. This expects an image, with the same file name from the retail image url (passed in) exists in media central inside
  inside the sitename/images/ folder structure in media central. This function will return undefined if the SiteType is not set up to use media
  central in this file.
*/
export const GetMediaCentralImage = (
  siteType: SiteType,
  emitImageErrorMetric: () => void,
  url?: string,
  fallbackImage?: string,
  size: ImageSize = ImageSize.LARGE,
) => {
  if (!url) {
    emitImageErrorMetric();
    return fallbackImage;
  }

  // Make sure SiteType is allowlisted
  if (!ALLOWED_SITES.includes(siteType)) {
    return url;
  }

  const id = getImageId(url);

  // Get ID
  return MEDIA_CENTRAL_URL(siteType, id, size);
};

// Returns the image id from the image url.
export const getImageId = (url: string) => {
  // Split the URL by '/' to get the path segments
  const pathSegments = url.split('/');

  // Get the last path segment, which should be the file name with extension
  const fileNameWithExtension = pathSegments[pathSegments.length - 1];

  // Split the file name with extension by '.' to separate the name and extension
  const [id] = fileNameWithExtension.split('.');

  // Get ID
  return id;
};
