import { ItemMetadata, MerchItem } from '@amzn/mulberry-external-typescript-client';
import React from 'react';
import { useEffect } from 'react';
import MerchGrid from 'src/components/MerchGrid/MerchGrid';
import MessageBox from 'src/components/MessageBox/MessageBox';
import { PLUMERIA_ENGAGEMENTS } from 'src/constants/roadie';
import { GENERIC_ERROR_STRING, UH_OH } from 'src/constants/strings';
import { getSite } from 'src/frameworks/GetSite';
import { SiteType } from 'src/frameworks/models/SiteType';
import { PageType } from 'src/metricsTypes/metricsTypes';
import withPageMetrics from 'src/shared/withPageMetrics';
import { merchGridCss } from 'src/sites/Plumeria/components/MerchGrid/MerchGridCss';
import { PlumeriaMerchGridItem } from 'src/sites/Plumeria/components/MerchGrid/PlumeriaMerchGridItem';
import { errorBoxCss } from 'src/sites/Plumeria/components/MessageBox/ErrorBox';
import { PP_INCLUDE_SESSION_ID_IN_METRICS } from 'src/sites/Plumeria/Plumeria';
import { getValueFromStageMap } from 'src/utils/roadieHelper';

const Home = () => {
  useEffect(() => {
    document.title = getSite()?.name || '';
  });
  const gridItemRenderer = (merchItem: MerchItem, metadata: ItemMetadata) => {
    return (
      <PlumeriaMerchGridItem
        merchItem={merchItem}
        metadata={metadata}
        includeSessionIdInMetrics={PP_INCLUDE_SESSION_ID_IN_METRICS}
      />
    );
  };

  const gridErrorRenderer = () => {
    return (
      <MessageBox
        heading={UH_OH}
        text={GENERIC_ERROR_STRING}
        styles={errorBoxCss}
        testId="merchGridErrorBox"
      />
    );
  };

  // TODO: update to use plumeria engagements when ready
  return (
    <>
      <div className="pp-homePage" data-testid="homePage">
        <MerchGrid
          itemRenderer={gridItemRenderer}
          errorRenderer={gridErrorRenderer}
          engagementId={getValueFromStageMap(PLUMERIA_ENGAGEMENTS)}
          site={SiteType.PLUMERIA}
          includeSessionIdInMetrics={PP_INCLUDE_SESSION_ID_IN_METRICS}
          numColumnsSmallScreen={2}
          numColumnsMediumScreen={3}
          numColumnsLargeScreen={4}
          gridSpacing={3}
          styles={merchGridCss}
        />
      </div>
    </>
  );
};

export default withPageMetrics(
  SiteType.PLUMERIA,
  PageType.HOME,
  Home,
  PP_INCLUDE_SESSION_ID_IN_METRICS,
);
