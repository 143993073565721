/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/display-name */
import React from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';

export type NavigationProps = {
  navigate: NavigateFunction;
  params?: URLSearchParams;
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const withRouter = (Component: any) => {
  return (props: any) => {
    const navigate = useNavigate();
    const params = useQuery();

    return <Component navigate={navigate} params={params} {...props} />;
  };
};
