import { Variation, VariationDimension } from '@amzn/mulberry-external-typescript-client';
import { isEmpty, isNil } from 'lodash';

/**
 * Variation Selection Logic
 *
 * Part 1: Initial Selection
 * * Select the first variation that matches the combination of asin, customId, and customizationToken upon page loading. If there’s no match, select the first variation.
 *
 * Part 2: Select a new dimension value
 * * Create a new dimension value array that copies the existing one, and change the value of the dimension currently selected to the new value.
 * * Try to find if there’s an exact match to that dimension value array. An exact match is defined as all dimension values match a specific variation. If yes, then select that variation.
 * * If no match, set the value in the dimension value array to undefined, one by one, from end to beginning, except for the dimension that is just selected, and try to find if there’s a match. If yes, then select that variation.
 * * If still no match, select the first variation.
 *
 * Part 3: Unavailability
 * * A dimension value is unavailable, only if selecting such dimension value does not lead to an exact match.
 * * Select an unavailable button still works, and will be considered as a new selection (part 2).
 */

// Implementation of finding exact variation
// "matching" is an array of indexes that we want to compare with variations
// If the value in "matching" is undefined, we skip the comparison of that dimension
// This can be used to find variation based on single or multiple dimension indexes
export const findExactVariation = (
  variations: Array<Variation>,
  matching: Array<number | undefined> | undefined = undefined,
): Variation | undefined => {
  if (isEmpty(variations) || isNil(matching) || isEmpty(matching)) {
    return variations?.[0];
  }
  return variations.find((variation) => {
    let match = true;
    for (const [key, value] of matching.entries()) {
      if (value !== undefined && variation.dimensionLocation?.[key] !== value) {
        match = false;
        break;
      }
    }
    return match;
  });
};

// Implementation of finding variation based on selected dimension value index
export const findVariation = (
  variations: Array<Variation>,
  dimensionIndex: number,
  valueIndex: number,
  currentDimensionValueIndexes: Array<number>,
): Variation | undefined => {
  // Skip this process if same dimension value is selected
  if (currentDimensionValueIndexes[dimensionIndex] === valueIndex) {
    return;
  }
  const newDimensionValueIndexes: Array<number | undefined> = [...currentDimensionValueIndexes];
  newDimensionValueIndexes[dimensionIndex] = valueIndex;
  // Find the exact match variation
  let newVariation = findExactVariation(variations, newDimensionValueIndexes);
  if (!newVariation) {
    // Find the first variation that match selected dimension value
    // Ease the dimension value in bottom to top order by setting them to undefined
    // Except for the dimension we are selecting
    const length = newDimensionValueIndexes.length;
    for (let index = length - 1; index >= 0; index--) {
      if (index === dimensionIndex) {
        continue;
      }
      newDimensionValueIndexes[index] = undefined;
      newVariation = findExactVariation(variations, newDimensionValueIndexes);
      if (newVariation) {
        break;
      }
    }
  }
  // Use the first variation
  if (!newVariation) {
    newVariation = findExactVariation(variations);
  }
  return newVariation;
};

// Implementation of calculating dimension value unavailabilities
// We use a 2D array to store unavailability information for each dimension value
export const findDimensionValueUnavailabilities = (
  variations: Array<Variation>,
  dimensions: Array<VariationDimension>,
  matching: Array<number>,
): Array<Array<boolean>> => {
  const unavailabilities: Array<Array<boolean>> = [];
  for (const [key, dimension] of dimensions.entries()) {
    const length = (dimension.values ?? []).length;
    const unavailability: Array<boolean> = [];
    for (let index = 0; index < length; index++) {
      const newMatching = [...matching];
      newMatching[key] = index;
      unavailability.push(!findExactVariation(variations, newMatching));
    }
    unavailabilities.push(unavailability);
  }
  return unavailabilities;
};
