import 'src/sites/Plumeria/components/Footer/Footer.scss';

import React from 'react';
import * as ICONS from 'src/sites/Plumeria/assets/icons/SocialIcons';

const Footer = () => {
  return (
    <footer className="pp-footer">
      <nav className="pp-footerNav">
        <a href="https://pesopluma.com/" rel="noreferrer">
          PESO PLUMA ©2024
        </a>
        <a href="https://www.pesopluma.com/terms-of-service" target="_blank" rel="noreferrer">
          Terms of Service
        </a>
        <a href="https://www.pesopluma.com/policies" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
        <a href="/faq">FAQs</a>
      </nav>
      <nav className="pp-footerNavRight">
        <a href="https://www.instagram.com/pesopluma" rel="noreferrer" target="_blank">
          {ICONS.INSTAGRAM}
        </a>
        <a href="https://www.tiktok.com/@therealpesopluma" rel="noreferrer" target="_blank">
          {ICONS.TIKTOK}
        </a>
        <a href="https://twitter.com/ElPesoPluma" rel="noreferrer" target="_blank">
          {ICONS.TWITTER}
        </a>
        <a href="https://www.facebook.com/PesoPlumaDobleP/" rel="noreferrer" target="_blank">
          {ICONS.FACEBOOK}
        </a>
      </nav>
    </footer>
  );
};

export default Footer;
