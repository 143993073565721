import { PLUMERIA_PREFIX } from 'src/constants/strings';
import { SiteType } from 'src/frameworks/models/SiteType';
import { PageType } from 'src/metricsTypes/metricsTypes';
import { NotFoundPage, NotFoundPageStyles } from 'src/pages/NotFoundPage';
import withPageMetrics from 'src/shared/withPageMetrics';
import { PP_INCLUDE_SESSION_ID_IN_METRICS } from 'src/sites/Plumeria/Plumeria';
import { buttonPlumeriaStyles } from 'src/sites/Plumeria/styles/ButtonStyles';
import { errorPlumeriaStyle } from 'src/sites/Plumeria/styles/ErrorStyles';

const $style: NotFoundPageStyles = {
  errorMessageBoxStyle: errorPlumeriaStyle,
  errorButtonStyle: buttonPlumeriaStyles,
};

const PlumeriaNotFoundPage = () => {
  return (
    <NotFoundPage
      style={$style}
      siteType={SiteType.PLUMERIA}
      sitePrefix={PLUMERIA_PREFIX}
      includeSessionIdInMetrics={PP_INCLUDE_SESSION_ID_IN_METRICS}
    />
  );
};

export default withPageMetrics(
  SiteType.PLUMERIA,
  PageType.NOT_FOUND,
  PlumeriaNotFoundPage,
  PP_INCLUDE_SESSION_ID_IN_METRICS,
);
