import React, { ReactElement, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { recordPageLoadTime, recordPageViewed } from 'src/metrics';
import { selectSessionId } from 'src/store/identifierSlice';
import { RootState } from 'src/store/store';

const withPageMetrics = (
  siteName: string,
  pageName: string,
  PageComponent: React.ComponentType<any>,
  includeSessionIdInMetrics: boolean,
): React.ComponentType<any> => {
  const mapStateToProps = (state: RootState) => ({
    sessionId: selectSessionId(state),
  });
  return connect(mapStateToProps)(function withPageMetrics(props: any): ReactElement {
    const { sessionId } = props;
    const pageLoadStartTime = Date.now();

    useEffect(() => {
      recordPageViewed(siteName, pageName, includeSessionIdInMetrics ? sessionId : undefined);
    }, []);

    useLayoutEffect(() => {
      recordPageLoadTime(
        siteName,
        pageName,
        Date.now() - pageLoadStartTime,
        includeSessionIdInMetrics ? sessionId : undefined,
      );
    }, []);

    return <PageComponent {...props} />;
  });
};
export default withPageMetrics;
