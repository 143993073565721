import { ItemMetadata, MerchItem } from '@amzn/mulberry-external-typescript-client';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MediaCentralImage from 'src/components/Image/MediacentralImage';
import { SiteType } from 'src/frameworks/models/SiteType';
import { recordClick } from 'src/metrics';
import { ActionType, ElementType, PageType } from 'src/metricsTypes/metricsTypes';
import { selectSessionId } from 'src/store/identifierSlice';
import { ImageSize } from 'src/utils/imageUtil';
import styled from 'styled-components';

interface MerchGridItemProps {
  includeSessionIdInMetrics: boolean;
  merchItem: MerchItem;
  metadata: ItemMetadata;
}

const MAX_WIDTH = 300;
const MAX_HEIGHT = 250;

const MerchGridItemImage = styled(MediaCentralImage)`
  min-width: ${MAX_WIDTH}px;
  min-height: ${MAX_HEIGHT}px;
  max-width: ${MAX_WIDTH}px;
  max-height: ${MAX_HEIGHT}px;
  margin: auto;
  object-fit: contain;
  filter: drop-shadow(0 2px 8px #00000070);
  transition: all 0.3s !important;

  &:hover {
    z-index: 2;
    transform: scale(1.1);
    transition: all 0.3s !important;
  }
`;

const MerchGridItemContainer = styled.div`
  margin: auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  opacity: 1;
  cursor: pointer;
  z-index: 2;
`;

const MerchGridItemTextContainer = styled.div`
  color: var(--secondary-color);
  text-align: center;
  font-family: var(--font-family);
  font-size: var(--body4-font-size);
  max-width: 100%;
  margin: auto;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
`;

const MerchGridItemPrice = styled.span`
  color: var(--tertiary-color);
  font-size: var(--body4-font-size);
`;

const MerchGridItemName = styled.span`
  font-size: var(--body3-font-size);
  margin-bottom: 0.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PlumeriaMerchGridItem = (props: MerchGridItemProps) => {
  const navigate = useNavigate();
  const image = props.metadata.images?.[0];
  const sessionId = useSelector(selectSessionId);

  const goProductDetail = (): void => {
    recordClick(
      SiteType.PLUMERIA,
      ActionType.GO_PRODUCT_DETAIL,
      props.includeSessionIdInMetrics ? sessionId : undefined,
      PageType.HOME,
      ElementType.MERCH_GRID_ITEM,
      props.merchItem.itemAsin,
    );
    navigate(`/product/${props.merchItem.itemAsin}`);
  };

  return (
    <MerchGridItemContainer onClick={goProductDetail} data-testid="merchGridItemContainer">
      <MerchGridItemImage
        testId="merchGridItemImage"
        pageType={PageType.HOME}
        siteType={SiteType.PLUMERIA}
        size={ImageSize.SMALL}
        productImages={image}
      />
      <MerchGridItemTextContainer data-testid="merchGridItemTextContainer">
        <MerchGridItemName data-testid="merchGridItemName">{props.metadata.name}</MerchGridItemName>
        <MerchGridItemPrice data-testid="merchGridItemPrice">
          {props.metadata.price}
        </MerchGridItemPrice>
      </MerchGridItemTextContainer>
    </MerchGridItemContainer>
  );
};

export default PlumeriaMerchGridItem;
