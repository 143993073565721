import { ItemMetadata, MerchItem } from '@amzn/mulberry-external-typescript-client';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SiteType } from 'src/frameworks/models/SiteType';
import { recordClick } from 'src/metrics';
import { ActionType, ElementType, PageType } from 'src/metricsTypes/metricsTypes';
import { selectSessionId } from 'src/store/identifierSlice';
import styled from 'styled-components';

interface MerchGridItemProps {
  includeSessionIdInMetrics: boolean;
  merchItem: MerchItem;
  metadata: ItemMetadata;
}

const MAX_WIDTH = 350;
const MAX_HEIGHT = 350;

const MerchGridItemImage = styled.img`
  width: 100%;
  height: auto;
  max-width: ${MAX_WIDTH}px;
  max-height: ${MAX_HEIGHT}px;
  margin: auto;
`;

const MerchGridItemContainer = styled.div`
  margin: auto;
  margin-bottom: 100px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  opacity: 1;
  cursor: pointer;
  z-index: 2;

  &:hover {
    z-index: 2;
    opacity: 0.6;
  }
`;

const MerchGridItemTextContainer = styled.div`
  color: var(--secondary-color);
  text-align: center;
  font-family: var(--font-family);
  font-size: 1rem;
  max-width: ${MAX_WIDTH}px;
  margin: auto;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
`;

const MerchGridItemPrice = styled.span``;

const MerchGridItemName = styled.span`
  margin-bottom: 0.5rem;
`;

const MerchGridItem = (props: MerchGridItemProps) => {
  const navigate = useNavigate();
  const image = props.metadata.images?.[0];
  const sessionId = useSelector(selectSessionId);

  const goProductDetail = (): void => {
    recordClick(
      SiteType.TORMENTEDVALLEY,
      ActionType.GO_PRODUCT_DETAIL,
      props.includeSessionIdInMetrics ? sessionId : undefined,
      PageType.HOME,
      ElementType.MERCH_GRID_ITEM,
      props.merchItem.itemAsin,
    );
    navigate(`/product/${props.merchItem.itemAsin}`);
  };

  return (
    <MerchGridItemContainer onClick={goProductDetail} data-testid="merchGridItemContainer">
      <MerchGridItemImage
        data-testid="merchGridItemImage"
        src={image?.lowRes?.url ?? ''}
        alt={image?.altText ?? ''}
      />
      <MerchGridItemTextContainer data-testid="merchGridItemTextContainer">
        <MerchGridItemName data-testid="merchGridItemName">{props.metadata.name}</MerchGridItemName>
        <MerchGridItemPrice data-testid="merchGridItemPrice">
          {props.metadata.price}
        </MerchGridItemPrice>
      </MerchGridItemTextContainer>
    </MerchGridItemContainer>
  );
};

export default MerchGridItem;
