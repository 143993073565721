export const buttonTormentedValleyStyles: Record<string, React.CSSProperties> = {
  buttonContainer: {
    color: 'var( --secondary-color)',
    borderWidth: '.0625rem',
    borderColor: 'var( --secondary-color)',
    cursor: 'pointer',
    minWidth: '15rem',
    textAlign: 'center',
    alignItems: '1rem',
    justifyContent: '1rem',
    padding: '1rem',
    width: '100%',
    fontFamily: 'var( --font-family )',
    fontWeight: 'var( --font-weight )',
    backgroundColor: 'var( --primary-color)',
    transition: 'color 0.3s, background-color 0.3s',
    borderRadius: '5px',
    fontSize: '1.5rem',
    boxSizing: 'border-box',
    height: 'auto',
    marginBottom: '5rem',
  },
};
