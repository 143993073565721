import { PLUMERIA_PREFIX } from 'src/constants/strings';
import { SiteType } from 'src/frameworks/models/SiteType';
import { PageType } from 'src/metricsTypes/metricsTypes';
import { CartPage } from 'src/pages/CartPage';
import withPageMetrics from 'src/shared/withPageMetrics';
import PlumeriaCartItem from 'src/sites/Plumeria/components/Cart/PlumeriaCartItem';
import { PP_INCLUDE_SESSION_ID_IN_METRICS } from 'src/sites/Plumeria/Plumeria';
import { buttonPlumeriaStyles } from 'src/sites/Plumeria/styles/ButtonStyles';
import { cartListPlumeriaStyle } from 'src/sites/Plumeria/styles/CartListStyles';
import { errorContainerStyles } from 'src/sites/Plumeria/styles/ErrorContainerStyles';
import { errorPlumeriaStyle } from 'src/sites/Plumeria/styles/ErrorStyles';

const PlumeriaCartPage = () => {
  return (
    <CartPage
      cartItemRenderer={PlumeriaCartItem}
      styles={{
        cartListStyle: cartListPlumeriaStyle,
        checkoutButton: buttonPlumeriaStyles,
        cartErrorContainer: errorContainerStyles,
        cartErrorStyles: errorPlumeriaStyle,
      }}
      siteType={SiteType.PLUMERIA}
      sitePrefix={PLUMERIA_PREFIX}
      includeSessionIdInMetrics={PP_INCLUDE_SESSION_ID_IN_METRICS}
    />
  );
};

export default withPageMetrics(
  SiteType.PLUMERIA,
  PageType.CART_PAGE,
  PlumeriaCartPage,
  PP_INCLUDE_SESSION_ID_IN_METRICS,
);
