export const errorPlumeriaStyle: Record<string, React.CSSProperties> = {
  messageBoxContainer: {
    color: 'var(--secondary-color)',
    fontFamily: 'var(--font-family',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    gap: '1rem',
    textAlign: 'center',
  },
  messageBoxHeading: {
    fontSize: '3rem',
    textTransform: 'uppercase',
  },
  messageBoxLink: {
    color: 'var(--tertiary-color)',
    fontSize: '1.5rem',
  },
  messageBoxText: {
    fontSize: '1.75rem',
    maxWidth: '80vw',
  },
};
