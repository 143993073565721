import {
  Charge,
  ChargeType,
  PaymentMethod,
  ShippingAddress,
} from '@amzn/mulberry-external-typescript-client';
import { Box, Divider } from '@mui/material';
import {
  ENDING_IN_STRING,
  ORDER_SUMMARY_STRING,
  PAYMENT_STRING,
  SHIPPING_TO_STRING,
} from 'src/constants/strings';
import { getChargeTypeString, getPaymentMethodString } from 'src/utils/utils';
import styled from 'styled-components';

export interface OrderSummaryStyles {
  orderSummaryContainer?: React.CSSProperties;
  orderSummaryHeader?: React.CSSProperties;
  orderSummaryOrderHeader?: React.CSSProperties;
  chargeListContainer?: React.CSSProperties;
  chargeItemContainer?: React.CSSProperties;
  chargeTypeString?: React.CSSProperties;
  chargeAmountString?: React.CSSProperties;
  orderSummaryPaymentHeader?: React.CSSProperties;
  paymentString?: React.CSSProperties;
  shippingToString?: React.CSSProperties;
}

export interface OrderSummaryOptions {
  /**
   * Show a visual summary header
   * defaults to true
   */
  showSummaryHeader?: boolean;
  /**
   * Show a visual divider between charges and payment details
   * defaults to false
   */
  showDivider?: boolean;
  /**
   * Set a display variant of payment method
   */
  paymentMethodVariant?: 'default' | 'align-right';
  /**
   * Set a display variant of shipping address
   */
  shippingAddressVariant?: 'default' | 'align-right';
}

type OrderSummaryProps = {
  charges: Charge[] | undefined;
  shippingAddress: ShippingAddress | undefined;
  paymentMethod: PaymentMethod | undefined;
  styles?: OrderSummaryStyles;
  options?: OrderSummaryOptions;
};

const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 1.25rem;
`;

const OrderSummary = ({
  options = {
    showSummaryHeader: true,
    showDivider: false,
    paymentMethodVariant: 'default',
    shippingAddressVariant: 'default',
  },
  ...props
}: OrderSummaryProps) => {
  if (!props.charges && !props.shippingAddress && !props.paymentMethod) {
    return null;
  }
  // Sort charges and discount based on this order
  const sortOrder = [
    ChargeType.ITEMS_SUBTOTAL,
    ChargeType.ITEMS_DISCOUNT_TOTAL,
    ChargeType.SHIPPING_FEE_TOTAL,
    ChargeType.SHIPPING_DISCOUNT_TOTAL,
    ChargeType.IMPORT_EXPORT_FEE_TOTAL,
    ChargeType.REGULATORY_FEE_TOTAL,
    ChargeType.ESTIMATED_TAX_TOTAL,
    ChargeType.PURCHASE_TOTAL,
  ];

  const sortedCharges = props.charges
    ?.filter((charge) => {
      const chargeAmount = parseFloat((charge.chargeAmount ?? '').replace('$', ''));
      return chargeAmount !== 0 || charge.type === ChargeType.ESTIMATED_TAX_TOTAL;
    })
    .sort((a, b) => {
      return sortOrder.indexOf(a.type as ChargeType) - sortOrder.indexOf(b.type as ChargeType);
    });

  const ShipTo = () => {
    const shippingToCity = props.shippingAddress?.city;
    const shippingToZipCode = props.shippingAddress?.zipCode;
    switch (options.shippingAddressVariant) {
      case 'align-right':
        return (
          <ItemContainer data-testid="shipToContainer" style={props.styles?.shippingToString}>
            <div data-testid="shipToLabel">Ship to:</div>
            <div data-testid="shipToValue">ZIP {shippingToZipCode}</div>
          </ItemContainer>
        );
      default: {
        const shippingToText =
          shippingToCity && shippingToZipCode
            ? `${SHIPPING_TO_STRING}${shippingToCity}, ${shippingToZipCode}`
            : shippingToCity || shippingToZipCode
              ? `${SHIPPING_TO_STRING}${shippingToCity || shippingToZipCode}`
              : '';
        return (
          <>
            {shippingToText && (
              <div
                className="shippingToString"
                data-testid="shippingToString"
                style={props.styles?.shippingToString}
              >
                {shippingToText}
              </div>
            )}
          </>
        );
      }
    }
  };
  const Payment = () => {
    const paymentMethodType = getPaymentMethodString(props.paymentMethod?.type ?? '');
    const paymentMethodTail = props.paymentMethod?.tail;
    const paymentText =
      paymentMethodType && paymentMethodTail
        ? `${paymentMethodType}${ENDING_IN_STRING}${paymentMethodTail}`
        : paymentMethodType
          ? paymentMethodType
          : '';
    switch (options.paymentMethodVariant) {
      case 'align-right': {
        return (
          <>
            {paymentText && (
              <ItemContainer data-testid="paymentContainer">
                <div data-testid="paymentLabel">Payment:</div>
                <div data-testid="paymentValue">{paymentText}</div>
              </ItemContainer>
            )}
          </>
        );
      }
      default: {
        const paymentText =
          paymentMethodType && paymentMethodTail
            ? `${paymentMethodType}${ENDING_IN_STRING}${paymentMethodTail}`
            : paymentMethodType
              ? paymentMethodType
              : '';
        return (
          <>
            {paymentText && (
              <>
                <h2
                  className="orderSummaryHeader"
                  id="orderSummaryPaymentHeader"
                  data-testid="orderSummaryPaymentHeader"
                  style={{
                    ...props.styles?.orderSummaryPaymentHeader,
                    ...props.styles?.orderSummaryHeader,
                  }}
                >
                  {PAYMENT_STRING}
                </h2>
                <div
                  className="paymentString"
                  data-testid="paymentString"
                  style={props.styles?.paymentString}
                >
                  {paymentText}
                </div>
              </>
            )}
          </>
        );
      }
    }
  };

  return (
    <div
      className="orderSummaryContainer"
      data-testid="orderSummaryContainer"
      style={props.styles?.orderSummaryContainer}
    >
      {options.showSummaryHeader && (
        <h2
          className="orderSummaryHeader"
          id="orderSummaryOrderHeader"
          data-testid="orderSummaryOrderHeader"
          style={{ ...props.styles?.orderSummaryOrderHeader, ...props.styles?.orderSummaryHeader }}
        >
          {ORDER_SUMMARY_STRING}
        </h2>
      )}
      {sortedCharges && (
        <div
          className="chargeListContainer"
          data-testid="chargeListContainer"
          style={props.styles?.chargeListContainer}
        >
          {sortedCharges.map(
            (charge, index) =>
              charge.type &&
              charge.chargeAmount && (
                <div
                  key={charge.type + index}
                  className="chargeItemContainer"
                  data-testid="chargeItemContainer"
                  style={props.styles?.chargeItemContainer}
                >
                  <div
                    key={`${charge.type}-label`}
                    className="chargeTypeString"
                    data-testid="chargeTypeString"
                    style={props.styles?.chargeTypeString}
                  >
                    {getChargeTypeString(charge.type)}
                  </div>
                  <div
                    key={`${charge.type}-amount`}
                    className="chargeAmountString"
                    data-testid="chargeAmountString"
                    style={props.styles?.chargeAmountString}
                  >
                    {charge.chargeAmount}
                  </div>
                </div>
              ),
          )}
        </div>
      )}
      {options.showDivider && (
        <Box marginTop={2} marginBottom={2}>
          <Divider />
        </Box>
      )}
      <ShipTo />
      <Payment />
    </div>
  );
};
export default OrderSummary;
