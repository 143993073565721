import { createSelector, createSlice, PayloadAction, Selector } from '@reduxjs/toolkit';
import type { RootState } from 'src/store/store';

export interface VariationState {
  key: string;
  variationIndexes: number[] | null;
}

const initialState: VariationState = {
  key: '',
  variationIndexes: null,
};

const variationSlice = createSlice({
  name: 'variation',
  initialState,
  reducers: {
    setVariationIndexes: (state, action: PayloadAction<{ selectedVariation: VariationState }>) => {
      const { selectedVariation } = action.payload;
      if (selectedVariation) {
        return {
          ...state,
          key: selectedVariation.key,
          variationIndexes: selectedVariation.variationIndexes,
        };
      } else {
        return state;
      }
    },
  },
});

export const { setVariationIndexes } = variationSlice.actions;

export const selectIndexes = (asin: string): Selector<RootState, number[]> =>
  createSelector(
    (state: RootState) => state.variation.key,
    (state: RootState) => state.variation.variationIndexes,
    (key, variationIndexes) => (key === asin ? variationIndexes ?? [] : []),
  );

export default variationSlice.reducer;
