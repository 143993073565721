import { Card } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import {
  CHECKOUT_STRING,
  LOGIN_WITH_AMAZON,
  LOGIN_WITH_AMAZON_SUBTEXT,
} from 'src/constants/strings';
import logo from 'src/images/amazon_logo.png';

export interface LinkAccountButtonProps {
  styles?: {
    containerStyle: React.CSSProperties;
    buttonStyle: React.CSSProperties;
    titleStyle: React.CSSProperties;
    contentStyle: React.CSSProperties;
  };
  disabled?: boolean;
  onClick: () => void;
  onHover?: () => void;
  testId?: string;
  classNamePrefix: string;
}

const $titleStyle: React.CSSProperties = {
  fontSize: '1rem',
  fontWeight: 'bold',
  fontFamily: 'Amazon Ember V2',
};

const $contentStyle: React.CSSProperties = {
  fontSize: '0.9rem',
  fontFamily: 'Amazon Ember V2',
  marginTop: '5px',
};

const $containerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  padding: '20px',
  width: '320px',
  background: 'linear-gradient(180deg, #F1F0EF 0%, #E0E0E0 100%)',
  boxShadow: 'none',
};

const $linkAccountButtonStyle: React.CSSProperties = {
  height: '53px',
  borderRadius: '67px',
  padding: '4px 8px',
  border: '0px none',
  backgroundColor: '#FFD814',
  width: '300px',
  alignContent: 'center',
  justifyContent: 'center',
  verticalAlign: 'center',
  margin: '10px',
};

const $logoStyle: React.CSSProperties = {
  marginLeft: '4px',
  marginTop: '8px',
  height: '20px',
  width: '64px',
  objectFit: 'contain',
};

const $buttonTextStyle: React.CSSProperties = {
  fontSize: '16px',
  color: 'black',
  fontWeight: 'bold',
  fontFamily: 'Amazon Ember V2',
  textTransform: 'none',
};
const LinkAccountButton = (props: LinkAccountButtonProps) => {
  const handleOnClick = () => {
    props.onClick();
  };

  return (
    <Card
      style={{ ...$containerStyle, ...props.styles?.containerStyle }}
      data-testid="linkAccountContainerTestId"
    >
      <span
        style={{ ...$titleStyle, ...props.styles?.titleStyle }}
        data-testid="linkAccountTitleTestId"
      >
        {CHECKOUT_STRING}
      </span>
      <span
        style={{ ...$contentStyle, ...props.styles?.contentStyle }}
        data-testid="linkAccountBodyTestId"
      >
        {LOGIN_WITH_AMAZON_SUBTEXT}
      </span>
      <Button
        style={{
          ...$linkAccountButtonStyle,
          ...props.styles?.buttonStyle,
        }}
        className="linkAccountButton"
        data-testid={props.testId}
        onClick={handleOnClick}
        disabled={props.disabled ?? false}
      >
        <span style={$buttonTextStyle}>{LOGIN_WITH_AMAZON}</span>
        <img src={logo} style={$logoStyle} />
      </Button>
    </Card>
  );
};

export default LinkAccountButton;
