import { ItemMetadata, MerchItem } from '@amzn/mulberry-external-typescript-client';
import { useEffect } from 'react';
import { confirmOrderErrorCss } from 'src/components/Error/confirmOrderErrorCss';
import MerchGrid from 'src/components/MerchGrid/MerchGrid';
import MessageBox from 'src/components/MessageBox/MessageBox';
import { TORMENTED_VALLEY_ENGAGEMENTS } from 'src/constants/roadie';
import { GENERIC_ERROR_STRING, UH_OH } from 'src/constants/strings';
import { getSite } from 'src/frameworks/GetSite';
import { SiteType } from 'src/frameworks/models/SiteType';
import { PageType } from 'src/metricsTypes/metricsTypes';
import withPageMetrics from 'src/shared/withPageMetrics';
import { merchGridCss } from 'src/sites/TormentedValleyExternalPilot/components/MerchGrid/MerchGridCss';
import MerchGridItem from 'src/sites/TormentedValleyExternalPilot/components/MerchGrid/TormentedValleyMerchGridItem';
import { TV_INCLUDE_SESSION_ID_IN_METRICS } from 'src/sites/TormentedValleyExternalPilot/TormentedValley';
import { getValueFromStageMap } from 'src/utils/roadieHelper';

const Home = () => {
  useEffect(() => {
    document.title = getSite()?.name || '';
  });
  const gridItemRenderer = (merchItem: MerchItem, metadata: ItemMetadata) => {
    return (
      <MerchGridItem
        merchItem={merchItem}
        metadata={metadata}
        includeSessionIdInMetrics={TV_INCLUDE_SESSION_ID_IN_METRICS}
      />
    );
  };

  const gridErrorRenderer = () => {
    return (
      <MessageBox
        heading={UH_OH}
        text={GENERIC_ERROR_STRING}
        styles={confirmOrderErrorCss}
        testId="merchGridErrorBox"
      />
    );
  };

  return (
    <>
      <div className="tv-homePage" data-testid="homePage">
        <MerchGrid
          itemRenderer={gridItemRenderer}
          errorRenderer={gridErrorRenderer}
          engagementId={getValueFromStageMap(TORMENTED_VALLEY_ENGAGEMENTS)}
          site={SiteType.TORMENTEDVALLEY}
          includeSessionIdInMetrics={TV_INCLUDE_SESSION_ID_IN_METRICS}
          numColumnsSmallScreen={1}
          numColumnsMediumScreen={3}
          numColumnsLargeScreen={4}
          gridSpacing={3}
          styles={merchGridCss}
        />
      </div>
    </>
  );
};

export default withPageMetrics(
  SiteType.TORMENTEDVALLEY,
  PageType.HOME,
  Home,
  TV_INCLUDE_SESSION_ID_IN_METRICS,
);
