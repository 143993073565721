import isEmpty from 'lodash/isEmpty';
import { CSSProperties, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SiteProps } from 'src/components/App/App';
import Button, { ButtonStyles } from 'src/components/Button/Button';
import CheckoutButton, { CheckoutButtonType } from 'src/components/Button/CheckoutButton';
import LinkAccountButton from 'src/components/Button/LinkAccountButton';
import { CartItemProps } from 'src/components/Cart/CartItem';
import CartList from 'src/components/Cart/CartList';
import LoadingSpinner from 'src/components/LoadingSpinner/LoadingSpinner';
import { LoadingSpinnerContainer } from 'src/components/LoadingSpinner/LoadingSpinnerContainer';
import MessageBox, { MessageBoxStyles } from 'src/components/MessageBox/MessageBox';
import { getRedirectUrl } from 'src/constants/lwa';
import { MAX_ASINS_IN_CART } from 'src/constants/roadie';
import {
  CONTINUE_SHOPPING_STRING,
  EMPTY_CART_STRING,
  GENERIC_ERROR_STRING,
  ITEM_SUBTOTAL_STRING,
  PRODUCT_STRING,
  QUANTITY_STRING,
  SUBTOTAL_TEXT,
  UH_OH,
  YOUR_CART_STRING,
} from 'src/constants/strings';
import { getSite } from 'src/frameworks/GetSite';
import { recordClick } from 'src/metrics';
import { ActionType } from 'src/metricsTypes/metricsTypes';
import {
  getValidateToken,
  selectAuthState,
  selectClientId,
  selectCsrfToken,
  selectExpiryTime,
  selectValidateTokenComplete,
} from 'src/store/authSlice';
import { selectCart } from 'src/store/cartSlice';
import {
  selectCatalog,
  selectCatalogError,
  selectHydrateCatalogComplete,
} from 'src/store/catalogSlice';
import { selectSessionId } from 'src/store/identifierSlice';
import { AppDispatch } from 'src/store/store';
import {
  getFooterText,
  getValidCart,
  handleAuth,
  hasUnavailableItems,
  hydrateCatalogFromCart,
  reloadWindowOnStorageChange,
  ValidCartItem,
} from 'src/utils/utils';

export interface CartPageStyles {
  checkoutButton?: ButtonStyles;
  cartListStyle?: {
    cartListContainer?: CSSProperties;
    cartListItem?: CSSProperties;
    emptyCartListMessage?: CSSProperties;
    emptyCartListContainer?: CSSProperties;
    buttonContainer?: CSSProperties;
  };
  cartErrorContainer?: CSSProperties;
  cartErrorStyles?: MessageBoxStyles;
}

export interface CartPageProps extends SiteProps {
  styles?: CartPageStyles;
  cartItemRenderer: (props: CartItemProps) => ReactElement;
}

export const CartPage = (props: CartPageProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const cart = useSelector(selectCart);
  const catalog = useSelector(selectCatalog);
  const [validSubtotal, setValidSubtotal] = useState('0.00');
  const [validQuantity, setValidQuantity] = useState(0);
  const [validHydratedCartItems, setValidHydratedCartItems] = useState<ValidCartItem[]>([]);
  const [hasUnavailableItem, setHasUnavailableItem] = useState(false);
  const accessTokenExists = useSelector(selectValidateTokenComplete);
  const expiryTime = useSelector(selectExpiryTime);
  const clientId = useSelector(selectClientId);
  const authState = useSelector(selectAuthState);
  const maxItemsExceeded: boolean = validHydratedCartItems.length > MAX_ASINS_IN_CART;
  const isLoggedIn: boolean = !!accessTokenExists && !!expiryTime && Date.now() < expiryTime;
  const csrfToken = useSelector(selectCsrfToken);
  const hydrateCatalogComplete = useSelector(selectHydrateCatalogComplete);
  const error = useSelector(selectCatalogError);
  const isNotEmpty = cart?.items && cart?.items?.length > 0;
  const sessionId = useSelector(selectSessionId);

  // Update title
  useEffect(() => {
    document.title = `${YOUR_CART_STRING} - ${getSite()?.name}`;
  }, []);

  useEffect(() => {
    reloadWindowOnStorageChange(dispatch, authState);
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getValidateToken({
        site: props.siteType,
        csrfToken: csrfToken,
      }),
    );
  }, []);

  // Hydrate catalog
  useEffect(() => {
    if (isNotEmpty) {
      hydrateCatalogFromCart(cart, dispatch, props.siteType, csrfToken);
    }
  }, [expiryTime, accessTokenExists, csrfToken]);

  // Use subtotal and quantity of ONLY valid cart items i.e. cart items that can be hydrated by the catalog
  useEffect(() => {
    if (catalog && cart) {
      const cartItems = cart?.items ?? [];
      const validCart = getValidCart(cartItems, catalog);
      const validItems = validCart.cartItems;
      setHasUnavailableItem(hasUnavailableItems(validItems));
      setValidHydratedCartItems(validItems);
      setValidSubtotal(validCart.subtotal);
      setValidQuantity(validCart.quantity);
    }
  }, [cart, catalog]);

  const goCheckout = (): void => {
    recordClick(
      props.siteType,
      ActionType.GO_CHECKOUT,
      props.includeSessionIdInMetrics ? sessionId : undefined,
    );
    if (!isEmpty(cart)) {
      navigate('/checkout');
    }
  };

  const goHome = (): void => {
    recordClick(
      props.siteType,
      ActionType.GO_HOME,
      props.includeSessionIdInMetrics ? sessionId : undefined,
    );
    navigate(`/`);
  };

  /* the catalog ONLY hydrates when the cart is not empty, 
  so only show loading screen if the catalog has not finished hydrating AND cart is not empty
  for error and regular screens, check that EITHER the catalog has completed hydrating or that the cart is empty */

  if (!hydrateCatalogComplete && isNotEmpty) {
    return (
      <LoadingSpinnerContainer data-testid="loadingSpinnerContainer">
        <LoadingSpinner />
      </LoadingSpinnerContainer>
    );
  }
  return (
    <>
      {(hydrateCatalogComplete || !isNotEmpty) && error && (
        <div
          className={`${props.sitePrefix}-cartErrorContainer`}
          data-testid="cartErrorContainer"
          style={props.styles?.cartErrorContainer}
        >
          <MessageBox
            heading={UH_OH}
            text={GENERIC_ERROR_STRING}
            styles={props.styles?.cartErrorStyles}
            testId="cartMessageBox"
          />
        </div>
      )}
      {(hydrateCatalogComplete || !isNotEmpty) && !error && (
        <div className={`${props.sitePrefix}-cartContainer`} data-testid="cartContainer">
          <div className={`${props.sitePrefix}-cartContent`}>
            <div className={`${props.sitePrefix}-cartHeader`} data-testid="cartHeader">
              <div className={`${props.sitePrefix}-cartHeaderText`} data-testid="cartHeaderText">
                {YOUR_CART_STRING}
              </div>
              <div className={`${props.sitePrefix}-cartDescriptor`} data-testid="cartDescriptor">
                <div className={`${props.sitePrefix}-cartDescriptorTitle`}>{PRODUCT_STRING}</div>
                <div className={`${props.sitePrefix}-cartDescriptorQuantity`}>
                  {QUANTITY_STRING}
                </div>
                <div className={`${props.sitePrefix}-cartDescriptorSubtotal`}>
                  {ITEM_SUBTOTAL_STRING}
                </div>
              </div>
            </div>
            <div className={`${props.sitePrefix}-cartList`} data-testid="cartList">
              <CartList
                sitePrefix={props.sitePrefix}
                siteType={props.siteType}
                includeSessionIdInMetrics={props.includeSessionIdInMetrics}
                hideEmptyCartButton={true}
                itemRenderer={props.cartItemRenderer}
                emptyCartMessage={EMPTY_CART_STRING}
                validCartItems={validHydratedCartItems}
                styles={props.styles?.cartListStyle}
              />
            </div>
            <div className={`${props.sitePrefix}-cartFooter`} data-testid="cartFooter">
              <div
                className={`${props.sitePrefix}-cartSubtitleContainer`}
                data-testid="cartSubtitleContainer"
              >
                <div
                  className={`${props.sitePrefix}-cartSubtotalContainer`}
                  data-testid="cartSubtotalContainer"
                >
                  <div
                    className={`${props.sitePrefix}-cartSubtotalLabel`}
                    data-testid="cartSubtotalLabel"
                  >
                    {SUBTOTAL_TEXT}:
                  </div>
                  <div className={`${props.sitePrefix}-cartSubtotal`} data-testid="cartSubtotal">
                    ${validSubtotal}
                  </div>
                </div>
                <div className={`${props.sitePrefix}-cartFooterText`} data-testid="cartFooterText">
                  {getFooterText(isLoggedIn, maxItemsExceeded, hasUnavailableItem)}
                </div>
              </div>
              <div
                className={`${props.sitePrefix}-checkoutButtonContainer`}
                data-testid="checkoutButtonContainer"
              >
                {validQuantity === 0 && (
                  <Button
                    text={CONTINUE_SHOPPING_STRING}
                    onClick={goHome}
                    testId="checkoutButton"
                    classNamePrefix={props.sitePrefix}
                    styles={props.styles?.checkoutButton}
                  />
                )}
                {validQuantity !== 0 &&
                  (isLoggedIn ? (
                    <CheckoutButton
                      // Disable checkout button when at least one items are unavailable or max limit has been exceeded
                      disabled={hasUnavailableItem || maxItemsExceeded}
                      type={CheckoutButtonType.CHECKOUT}
                      onClick={goCheckout}
                      testId="checkoutButton"
                      classNamePrefix={props.sitePrefix}
                    />
                  ) : (
                    <LinkAccountButton
                      onClick={() => handleAuth(clientId ?? '', getRedirectUrl(props.siteType))}
                      testId="linkAccountButton"
                      classNamePrefix={props.sitePrefix}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
