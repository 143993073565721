export const ppLoadingSpinnerContainer: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
};

export const ppProductDPErrorContainer: React.CSSProperties = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  margin: '0',
  top: '-4rem',
  textAlign: 'center',
};

export const ppVariationLabel: React.CSSProperties = {
  fontSize: '1.25rem',
  fontFamily: 'var(--font-family)',
  color: 'var(--secondary-color)',
};

export const ppProductDPLoadingContainer: React.CSSProperties = {
  height: '100%',
  width: '100%',
};
