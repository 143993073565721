export const descriptionBulletsTormentedValleyStyle: Record<string, React.CSSProperties> = {
  productDetailBoxContainer: {
    color: 'var( --secondary-color )',
    fontFamily: 'var( --font-family )',
    fontSize: '1.25rem',
    fontWeight: 'normal',
    listStyle: 'none',
    paddingLeft: '0',
  },
  primaryInfoBoxAmazonInfo: { marginBottom: '.5rem', fontSize: '1.25rem' },
};
