export const imageBoxPlumeriaStyle: Record<string, React.CSSProperties> = {
  imageBoxContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    maxWidth: '90vw',
  },
  imageBoxRow: {
    display: 'flex',
    flexDirection: 'column',
    order: '2',
    marginTop: '3rem',
    maxWidth: '90vw',
  },
  imageBoxColumn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    order: '1',
    maxWidth: '100%',
    flexWrap: 'wrap',
    gap: '.5rem',
    marginTop: '.5rem',
  },
  imageBoxSmallImage: {
    maxHeight: '100px',
    maxWidth: '100px',
    cursor: 'pointer',
    objectFit: 'cover',
    filter: 'drop-shadow(0 2px 8px #00000070)',
  },
  imageBoxLargeImage: {
    maxHeight: '600px',
    height: 'min(600px, 40vh)',
    maxWidth: 'min(100%,600px)',
    marginLeft: 'auto',
    marginRight: 'auto',
    filter: 'drop-shadow(0 2px 8px #00000070)',
    objectFit: 'contain',
  },
  imageBoxLargeImageContainer: {
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    objectFit: 'contain',
    display: 'flex',
  },
  imageBoxSmallImageContainer: {
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
