export const buttonGroupPlumeriaStyle: Record<string, React.CSSProperties> = {
  pressedButton: {
    backgroundColor: 'rgba(20,20,20)',
    padding: '.5rem',
    color: 'var(--tertiary-color)',
    border: '1px solid var(--tertiary-color)',
    marginBottom: '.5rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    borderRadius: '4px',
    fontFamily: 'var(--font-family)',
    fontSize: '17px',
  },
  defaultButton: {
    backgroundColor: 'var(--primary-color)',
    color: 'var(--secondary-color)',
    marginBottom: '.5rem',
    padding: '.5rem',
    border: '1px solid rgb(219, 219, 219)',
    cursor: 'pointer',
    borderRadius: '4px',
    fontFamily: 'var(--font-family)',
    fontSize: '17px',
  },
  grayedOutButton: {
    backgroundColor: 'var(--primary-color)',
    color: 'rgb(219, 219, 219)',
    marginBottom: '.5rem',
    padding: '.5rem',
    border: '1px dashed rgb(219, 219, 219)',
    cursor: 'pointer',
    borderRadius: '4px',
    fontFamily: 'var(--font-family)',
    fontSize: '15px',
    opacity: 0.6,
  },
  buttonGroupContainer: {
    columnGap: '.75rem',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '.5rem',
    WebkitUserSelect: 'none',
    userSelect: 'none',
    MozUserSelect: 'none',
  },
};
