import { OrderSummaryOptions } from 'src/components/OrderSummary/OrderSummary';
import { TORMENTED_VALLEY_PREFIX } from 'src/constants/strings';
import { SiteType } from 'src/frameworks/models/SiteType';
import { PageType } from 'src/metricsTypes/metricsTypes';
import { OrderPage, OrderPageStyles } from 'src/pages/OrderPage';
import withPageMetrics from 'src/shared/withPageMetrics';
import { withRouter } from 'src/shared/withRouter';
import { orderItemCss } from 'src/sites/TormentedValleyExternalPilot/components/Order/OrderItemCss';
import { orderListCss } from 'src/sites/TormentedValleyExternalPilot/components/Order/OrderListCss';
import { orderSummaryCSS } from 'src/sites/TormentedValleyExternalPilot/components/Order/OrderSummaryCss';
import { buttonTormentedValleyStyles } from 'src/sites/TormentedValleyExternalPilot/styles/ButtonStyles';
import { errorTormentedValleyStyle } from 'src/sites/TormentedValleyExternalPilot/styles/ErrorStyles';
import { TV_INCLUDE_SESSION_ID_IN_METRICS } from 'src/sites/TormentedValleyExternalPilot/TormentedValley';

const orderSummaryOptions: OrderSummaryOptions = {
  showSummaryHeader: false,
  showDivider: true,
  paymentMethodVariant: 'align-right',
  shippingAddressVariant: 'align-right',
};

const $style: OrderPageStyles = {
  orderItemStyle: orderItemCss,
  orderItemListStyle: orderListCss,
  orderSummaryStyle: orderSummaryCSS,
  errorMessageBoxStyle: errorTormentedValleyStyle,
  errorButtonStyle: buttonTormentedValleyStyles,
  paymentAndAddressNote: { alignSelf: 'flex-end', maxWidth: 300 },
  privacyNoticeAndConditionOfUseNote: { alignSelf: 'flex-end' },
  checkoutButtonStyles: {
    confirmButtonStyle: {
      alignSelf: 'flex-end',
      backgroundColor: 'transparent',
      borderRadius: 0,
      border: '1px solid #000',
    },
    confirmButtonTextStyle: {
      color: 'black',
    },
    lockIconStyle: {
      color: 'black',
    },
  },
};

const TormentedValleyOrderPage = () => {
  return (
    <OrderPage
      siteType={SiteType.TORMENTEDVALLEY}
      sitePrefix={TORMENTED_VALLEY_PREFIX}
      orderSummaryOptions={orderSummaryOptions}
      style={$style}
      includeSessionIdInMetrics={TV_INCLUDE_SESSION_ID_IN_METRICS}
    />
  );
};
export default withPageMetrics(
  SiteType.TORMENTEDVALLEY,
  PageType.ORDER_PAGE,
  withRouter(TormentedValleyOrderPage),
  TV_INCLUDE_SESSION_ID_IN_METRICS,
);
