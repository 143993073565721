export const exampleMerchGridItemCss: Record<string, React.CSSProperties> = {
  merchGridItemContainer: {
    width: '300px',
    height: '300px',
  },
  merchGridItemImage: {
    width: '300px',
    height: '300px',
  },
};
