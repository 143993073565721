import React, { useState } from 'react';

export interface ButtonGroupStyles {
  pressedButton?: React.CSSProperties;
  defaultButton?: React.CSSProperties;
  grayedOutButton?: React.CSSProperties;
  buttonGroupContainer?: React.CSSProperties;
}

export interface ButtonGroupProps {
  buttons: {
    id: number;
    label: string;
    grayedOut?: boolean;
  }[];
  selectedIndex?: number;
  onButtonPress: (buttonId: number) => void;
  styles?: ButtonGroupStyles;
}

const ButtonGroup = (props: ButtonGroupProps) => {
  const [pressedButton, setPressedButton] = useState<number | undefined>(props.selectedIndex);

  const handleButtonPress = (buttonId: number) => {
    setPressedButton(buttonId);
    props.onButtonPress(buttonId);
  };

  return (
    <div
      className="buttonGroupContainer"
      style={props.styles?.buttonGroupContainer}
      data-testid="buttonGroup"
    >
      {props.buttons.map((button) => (
        <button
          className="buttonGroupItem"
          key={button.id}
          style={
            button.grayedOut
              ? props.styles?.grayedOutButton
              : pressedButton === button.id
                ? props.styles?.pressedButton
                : props.styles?.defaultButton
          }
          onClick={() => handleButtonPress(button.id)}
          data-testid={`button-group-${button.id}`}
        >
          {button.label}
        </button>
      ))}
    </div>
  );
};

export default ButtonGroup;
