import { Success, SuccessStyle } from 'src/components/Success/Success';
import { TORMENTED_VALLEY_PREFIX } from 'src/constants/strings';
import { SiteType } from 'src/frameworks/models/SiteType';
import { PageType } from 'src/metricsTypes/metricsTypes';
import withPageMetrics from 'src/shared/withPageMetrics';
import { NavigationProps, withRouter } from 'src/shared/withRouter';
import { buttonTormentedValleyStyles } from 'src/sites/TormentedValleyExternalPilot/styles/ButtonStyles';
import { TV_INCLUDE_SESSION_ID_IN_METRICS } from 'src/sites/TormentedValleyExternalPilot/TormentedValley';

const $style: SuccessStyle = {
  errorContainer: {
    background: 'var(--primary-color)',
    color: 'var(--secondary-color)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'var(--font-family)',
    padding: '3rem 6rem',
    boxSizing: 'border-box',
    maxWidth: '1350px',
    margin: 'auto',
    lineHeight: '3rem',
    textAlign: 'center',
  },
  successButton: { ...buttonTormentedValleyStyles.buttonContainer, marginTop: '50px' },
  errorMessageBox: {
    messageBoxHeading: {
      fontSize: '2rem',
    },
    messageBoxContainer: {
      fontSize: '1.25rem',
    },
    messageBoxLink: {
      fontSize: '1.25rem',
    },
  },
  errorMessageBoxButton: { ...buttonTormentedValleyStyles.buttonContainer, marginTop: '50px' },
  successButtonContainer: {},
  successMessageBox: {
    messageBoxHeading: {
      fontSize: '2rem',
    },
    messageBoxContainer: {
      fontSize: '1.25rem',
    },
    messageBoxLink: {
      fontSize: '1.25rem',
    },
  },
  successContainer: {
    background: 'var(--primary-color)',
    color: 'var(--secondary-color)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'var(--font-family)',
    padding: '3rem 6rem',
    boxSizing: 'border-box',
    maxWidth: '1350px',
    margin: 'auto',
    lineHeight: '3rem',
    textAlign: 'center',
  },
};

const SuccessPage = ({ params, navigate }: NavigationProps) => {
  return (
    <Success
      styles={$style}
      sitePrefix={TORMENTED_VALLEY_PREFIX}
      siteType={SiteType.TORMENTEDVALLEY}
      navigation={{ params, navigate }}
      includeSessionIdInMetrics={TV_INCLUDE_SESSION_ID_IN_METRICS}
    />
  );
};

export default withPageMetrics(
  SiteType.TORMENTEDVALLEY,
  PageType.SUCCESS_PAGE,
  withRouter(SuccessPage),
  TV_INCLUDE_SESSION_ID_IN_METRICS,
);
