import { TORMENTED_VALLEY_PREFIX } from 'src/constants/strings';
import { SiteType } from 'src/frameworks/models/SiteType';
import { PageType } from 'src/metricsTypes/metricsTypes';
import { NotFoundPage, NotFoundPageStyles } from 'src/pages/NotFoundPage';
import withPageMetrics from 'src/shared/withPageMetrics';
import { buttonTormentedValleyStyles } from 'src/sites/TormentedValleyExternalPilot/styles/ButtonStyles';
import { errorTormentedValleyStyle } from 'src/sites/TormentedValleyExternalPilot/styles/ErrorStyles';
import { TV_INCLUDE_SESSION_ID_IN_METRICS } from 'src/sites/TormentedValleyExternalPilot/TormentedValley';

const $style: NotFoundPageStyles = {
  errorMessageBoxStyle: errorTormentedValleyStyle,
  errorButtonStyle: buttonTormentedValleyStyles,
};

const TormentedValleyNotFoundPage = () => {
  return (
    <NotFoundPage
      style={$style}
      siteType={SiteType.TORMENTEDVALLEY}
      sitePrefix={TORMENTED_VALLEY_PREFIX}
      includeSessionIdInMetrics={TV_INCLUDE_SESSION_ID_IN_METRICS}
    />
  );
};

export default withPageMetrics(
  SiteType.TORMENTEDVALLEY,
  PageType.NOT_FOUND,
  TormentedValleyNotFoundPage,
  TV_INCLUDE_SESSION_ID_IN_METRICS,
);
