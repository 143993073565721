import { SiteType } from 'src/frameworks/models/SiteType';

export const getSite = (): { type: SiteType; name: string } => {
  const hostname = window.location.hostname;

  if (
    hostname.endsWith('external-pilot.mulberry-app.music.amazon.dev') ||
    hostname.endsWith('shop.tormentedvalley.com') || // local site
    hostname.endsWith('shop.thetormentedvalley.com') //actual site)
  ) {
    return { type: SiteType.TORMENTEDVALLEY, name: 'The Tormented Valley' };
  }
  // do not load for prod site as prod is also not midway gated - it is part of the same distribution as the D2C artist domain
  if (
    hostname.endsWith('store.pesopluma.com') ||
    hostname.endsWith('beta.stack1.mulberry-app.music.amazon.dev') ||
    hostname.endsWith('gamma.stack1.mulberry-app.music.amazon.dev') ||
    hostname.endsWith('shop.plumeria.com') // local site
  ) {
    return { type: SiteType.PLUMERIA, name: 'Peso Pluma Official' };
  }
  // fallback to test site
  return { type: SiteType.TEST, name: 'D2C Site' };
};
