export const imageBoxTormentedValleyStyle: Record<string, React.CSSProperties> = {
  imageBoxContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 'calc(100% - 2rem)',
    boxSizing: 'border-box',
  },
  imageBoxRow: {
    display: 'flex',
    flexDirection: 'column',
    order: '2',
    marginTop: '3rem',
    maxWidth: '100vw',
  },
  imageBoxColumn: {
    display: 'flex',
    flexDirection: 'row',
    order: '1',
    width: '38rem',
    maxWidth: '100%',
    flexWrap: 'wrap',
    gap: '.5rem',
    marginTop: '.5rem',
  },
  imageBoxSmallImage: {
    height: 'auto',
    width: '8.25rem',
    maxHeight: '100%',
    maxWidth: '100%',
    cursor: 'pointer',
    objectPosition: 'center center',
    objectFit: 'contain',
  },
  imageBoxLargeImage: {
    height: 'auto',
    width: '38rem',
    maxHeight: '100%',
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    objectFit: 'contain',
    objectPosition: 'center center',
  },
  imageBoxLargeImageContainer: {
    height: 'auto',
    width: '38rem',
    maxWidth: '100%',
    border: '1px solid rgb(207, 207, 207)',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageBoxSmallImageContainer: {
    height: 'auto',
    width: '8.25rem',
    border: '1px solid rgb(207, 207, 207)',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
