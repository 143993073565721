import { TORMENTED_VALLEY_PREFIX } from 'src/constants/strings';
import { SiteType } from 'src/frameworks/models/SiteType';
import { PageType } from 'src/metricsTypes/metricsTypes';
import { CartPage } from 'src/pages/CartPage';
import withPageMetrics from 'src/shared/withPageMetrics';
import TormentedValleyCartItem from 'src/sites/TormentedValleyExternalPilot/components/Cart/TormentedValleyCartItem';
import { buttonTormentedValleyStyles } from 'src/sites/TormentedValleyExternalPilot/styles/ButtonStyles';
import { cartListTormentedValleyStyle } from 'src/sites/TormentedValleyExternalPilot/styles/CartListStyles';
import { errorContainerStyles } from 'src/sites/TormentedValleyExternalPilot/styles/ErrorContainerStyles';
import { errorTormentedValleyStyle } from 'src/sites/TormentedValleyExternalPilot/styles/ErrorStyles';
import { TV_INCLUDE_SESSION_ID_IN_METRICS } from 'src/sites/TormentedValleyExternalPilot/TormentedValley';

const TormentedValleyCartPage = () => {
  return (
    <CartPage
      cartItemRenderer={TormentedValleyCartItem}
      styles={{
        cartListStyle: cartListTormentedValleyStyle,
        checkoutButton: buttonTormentedValleyStyles,
        cartErrorContainer: errorContainerStyles,
        cartErrorStyles: errorTormentedValleyStyle,
      }}
      siteType={SiteType.TORMENTEDVALLEY}
      sitePrefix={TORMENTED_VALLEY_PREFIX}
      includeSessionIdInMetrics={TV_INCLUDE_SESSION_ID_IN_METRICS}
    />
  );
};

export default withPageMetrics(
  SiteType.TORMENTEDVALLEY,
  PageType.CART_PAGE,
  TormentedValleyCartPage,
  TV_INCLUDE_SESSION_ID_IN_METRICS,
);
