import 'src/sites/Plumeria/components/ProductDetailPage/ProductDetailPage.css';

import { PLUMERIA_PREFIX } from 'src/constants/strings';
import { SiteType } from 'src/frameworks/models/SiteType';
import ProductDetailPage, { ProductDetailPageStyles } from 'src/pages/ProductDetailPage';
import { descriptionBulletsPlumeriaStyle } from 'src/sites/Plumeria//styles/DescriptionBulletsStyles';
import {
  ppLoadingSpinnerContainer,
  ppProductDPLoadingContainer,
  ppVariationLabel,
} from 'src/sites/Plumeria/components/ProductDetailPage/ProductDetailPageCss';
import { PP_INCLUDE_SESSION_ID_IN_METRICS } from 'src/sites/Plumeria/Plumeria';
import { buttonGroupPlumeriaStyle } from 'src/sites/Plumeria/styles/ButtonGroupStyles';
import { buttonPlumeriaStyles } from 'src/sites/Plumeria/styles/ButtonStyles';
import { errorContainerStyles } from 'src/sites/Plumeria/styles/ErrorContainerStyles';
import { errorPlumeriaStyle } from 'src/sites/Plumeria/styles/ErrorStyles';
import { imageBoxPlumeriaStyle } from 'src/sites/Plumeria/styles/ImageBoxStyles';
import { primaryInfoBoxPlumeriaStyle } from 'src/sites/Plumeria/styles/PrimaryInfoBoxStyles';
import { quantitySelectorPlumeriaStyle } from 'src/sites/Plumeria/styles/QuantitySelectorStyles';

const PlumeriaProductDetailPage = () => {
  const $style: ProductDetailPageStyles = {
    loadingSpinnerContainer: ppLoadingSpinnerContainer,
    productDPErrorContainer: errorContainerStyles,
    messageBoxErrorStyles: errorPlumeriaStyle,
    imageBoxStyles: imageBoxPlumeriaStyle,
    primaryInfoBoxStyles: primaryInfoBoxPlumeriaStyle,
    variationLabel: ppVariationLabel,
    buttonGroupStyles: buttonGroupPlumeriaStyle,
    quantitySelectorStyles: quantitySelectorPlumeriaStyle,
    buttonStyles: buttonPlumeriaStyles,
    descriptionBulletsStyle: descriptionBulletsPlumeriaStyle,
    productDPLoadingContainer: ppProductDPLoadingContainer,
  };
  return (
    <ProductDetailPage
      siteType={SiteType.PLUMERIA}
      sitePrefix={PLUMERIA_PREFIX}
      style={$style}
      includeSessionIdInMetrics={PP_INCLUDE_SESSION_ID_IN_METRICS}
    />
  );
};

export default PlumeriaProductDetailPage;
